@charset "UTF-8";
.select2-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), to(#eeeeee));
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(#cccccc));
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(50%, #eeeeee));
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(white));
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.slick-lightbox .slick-loading .slick-list {
  background-color: transparent;
}

.slick-lightbox .slick-prev {
  left: 15px;
}

.slick-lightbox .slick-next {
  right: 15px;
}

.slick-lightbox-hide {
  opacity: 0;
}

.slick-lightbox-hide.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0;
}

.slick-lightbox-hide-init.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden;
}

.slick-lightbox-slick-item:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  display: block;
  text-align: center;
}

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white;
}

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
}

.slick-lightbox-close:focus {
  outline: none;
}

.slick-lightbox-close:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: 'Ã—';
}

@-webkit-keyframes lum-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lum-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lum-fadeZoom {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes lum-fadeZoom {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes lum-loader-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
            transform: translate(-50%, -50%) rotate(-180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes lum-loader-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
            transform: translate(-50%, -50%) rotate(-180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes lum-loader-before {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2) translateX(6px);
            transform: scale(1.2) translateX(6px);
  }
  25% {
    -webkit-transform: scale(1.3) translateX(8px);
            transform: scale(1.3) translateX(8px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(6px);
            transform: scale(1.2) translateX(6px);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(6px);
            transform: scale(0.8) translateX(6px);
  }
  75% {
    -webkit-transform: scale(0.7) translateX(8px);
            transform: scale(0.7) translateX(8px);
  }
  90% {
    -webkit-transform: scale(0.8) translateX(6px);
            transform: scale(0.8) translateX(6px);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes lum-loader-before {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2) translateX(6px);
            transform: scale(1.2) translateX(6px);
  }
  25% {
    -webkit-transform: scale(1.3) translateX(8px);
            transform: scale(1.3) translateX(8px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(6px);
            transform: scale(1.2) translateX(6px);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(6px);
            transform: scale(0.8) translateX(6px);
  }
  75% {
    -webkit-transform: scale(0.7) translateX(8px);
            transform: scale(0.7) translateX(8px);
  }
  90% {
    -webkit-transform: scale(0.8) translateX(6px);
            transform: scale(0.8) translateX(6px);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes lum-loader-after {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2) translateX(-6px);
            transform: scale(1.2) translateX(-6px);
  }
  25% {
    -webkit-transform: scale(1.3) translateX(-8px);
            transform: scale(1.3) translateX(-8px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(-6px);
            transform: scale(1.2) translateX(-6px);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(-6px);
            transform: scale(0.8) translateX(-6px);
  }
  75% {
    -webkit-transform: scale(0.7) translateX(-8px);
            transform: scale(0.7) translateX(-8px);
  }
  90% {
    -webkit-transform: scale(0.8) translateX(-6px);
            transform: scale(0.8) translateX(-6px);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes lum-loader-after {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2) translateX(-6px);
            transform: scale(1.2) translateX(-6px);
  }
  25% {
    -webkit-transform: scale(1.3) translateX(-8px);
            transform: scale(1.3) translateX(-8px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(-6px);
            transform: scale(1.2) translateX(-6px);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(-6px);
            transform: scale(0.8) translateX(-6px);
  }
  75% {
    -webkit-transform: scale(0.7) translateX(-8px);
            transform: scale(0.7) translateX(-8px);
  }
  90% {
    -webkit-transform: scale(0.8) translateX(-6px);
            transform: scale(0.8) translateX(-6px);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.lum-lightbox {
  background: rgba(0, 0, 0, 0.6);
}

.lum-lightbox-inner {
  top: 2.5%;
  right: 2.5%;
  bottom: 2.5%;
  left: 2.5%;
}

.lum-lightbox-inner img {
  position: relative;
}

.lum-lightbox-inner .lum-lightbox-caption {
  margin: 0 auto;
  color: #fff;
  max-width: 700px;
  text-align: center;
}

.lum-loading .lum-lightbox-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 66px;
  height: 20px;
  -webkit-animation: lum-loader-rotate 1800ms infinite linear;
          animation: lum-loader-rotate 1800ms infinite linear;
}

.lum-lightbox-loader:before,
.lum-lightbox-loader:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
}

.lum-lightbox-loader:before {
  left: 0;
  -webkit-animation: lum-loader-before 1800ms infinite linear;
          animation: lum-loader-before 1800ms infinite linear;
}

.lum-lightbox-loader:after {
  right: 0;
  -webkit-animation: lum-loader-after 1800ms infinite linear;
          animation: lum-loader-after 1800ms infinite linear;
  -webkit-animation-delay: -900ms;
          animation-delay: -900ms;
}

.lum-lightbox.lum-opening {
  -webkit-animation: lum-fade 180ms ease-out;
          animation: lum-fade 180ms ease-out;
}

.lum-lightbox.lum-opening .lum-lightbox-inner {
  -webkit-animation: lum-fadeZoom 180ms ease-out;
          animation: lum-fadeZoom 180ms ease-out;
}

.lum-lightbox.lum-closing {
  -webkit-animation: lum-fade 300ms ease-in;
          animation: lum-fade 300ms ease-in;
  animation-direction: reverse;
}

.lum-lightbox.lum-closing .lum-lightbox-inner {
  -webkit-animation: lum-fadeZoom 300ms ease-in;
          animation: lum-fadeZoom 300ms ease-in;
  animation-direction: reverse;
}

.lum-img {
  -webkit-transition: opacity 120ms ease-out;
  transition: opacity 120ms ease-out;
}

.lum-loading .lum-img {
  opacity: 0;
}

.lum-gallery-button {
  overflow: hidden;
  text-indent: 150%;
  white-space: nowrap;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 100px;
  max-height: 100%;
  width: 60px;
  cursor: pointer;
}

.lum-close-button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

.lum-close-button:hover {
  opacity: 1;
}

.lum-close-button:before,
.lum-close-button:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.lum-close-button:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.lum-close-button:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.lum-previous-button {
  left: 12px;
}

.lum-next-button {
  right: 12px;
}

.lum-gallery-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  border-top: 4px solid rgba(255, 255, 255, 0.8);
}

.lum-previous-button:after {
  -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
  border-left: 4px solid rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: -2px 0 rgba(0, 0, 0, 0.2);
          box-shadow: -2px 0 rgba(0, 0, 0, 0.2);
  left: 12%;
  border-radius: 3px 0 0 0;
}

.lum-next-button:after {
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  border-right: 4px solid rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 2px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 2px 0 rgba(0, 0, 0, 0.2);
  right: 12%;
  border-radius: 0 3px 0 0;
}

/* This media query makes screens less than 460px wide display in a "fullscreen"-esque mode. Users can then scroll around inside the lightbox to see the entire image. */
@media (max-width: 460px) {
  .lum-lightbox-image-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .lum-lightbox-caption {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  /* Used to centre the image in the container, respecting overflow: https://stackoverflow.com/a/33455342/515634 */
  .lum-lightbox-position-helper {
    margin: auto;
  }
  .lum-lightbox-inner img {
    max-width: none;
    max-height: none;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

/**
* For collapsible content
*/
div.window.dialog {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

div.window.dialog .titlebar {
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  display: block;
}

div.window.dialog .titlebar tbody {
  display: block;
}

div.window.dialog .titlebar tr {
  display: block;
}

div.window.dialog .titlebar td {
  display: block;
}

div.window.dialog td.activewindowtitle {
  padding: 25px 30px 1px;
  text-align: left;
  margin: 0;
  line-height: 1;
  font-size: 1.875rem;
  font-weight: 700;
  color: rgb(var(--color-brand-main));
  height: auto;
}

div.window.dialog td.windowbuttons {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 100%;
  color: #fff;
  padding: 0 10px;
}

div.window.dialog td.windowbuttons .btn {
  background-color: transparent;
  padding: 0 10px 0;
  border: none;
  color: transparent;
  cursor: pointer;
  width: 50px;
  overflow: hidden;
  text-indent: -1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

div.window.dialog td.windowbuttons .btn::after {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 1.95rem;
  color: #dbe3e8;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}

div.window.dialog td.windowbuttons .btn:hover::after {
  color: #fff;
}

div.window.dialog .windowcontent {
  top: 70px;
  padding: 1px 25px 25px;
  border: none;
  background-color: #fff;
  color: #000;
  bottom: auto;
  left: auto;
  right: auto;
  width: 100%;
  max-height: calc(100vh - 100px);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

div.window.dialog .windowcontent .prop-editor {
  padding: 0;
}

div.window.dialog .actions.bottom, div.window.dialog .actions.top {
  width: 100%;
  text-align: right;
}

div.window.dialog .actions.bottom::after, div.window.dialog .actions.top::after {
  display: block;
  clear: both;
  content: "";
}

div.window.dialog .actions.bottom .btn + .btn, div.window.dialog .actions.top .btn + .btn {
  margin-bottom: 0;
}

div.window.dialog .actions.bottom .btn-group .btn + .btn, div.window.dialog .actions.top .btn-group .btn + .btn {
  margin-left: -1px;
}

div.window.dialog .actions.bottom .btn-block + .btn-block, div.window.dialog .actions.top .btn-block + .btn-block {
  margin-left: 0;
}

div.window.dialog .actions.top {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
  padding: 0 0 15px;
  margin: 0 0 1rem;
}

div.window.dialog .actions.bottom {
  border-top: 1px solid #F4F6FC;
  margin: 1rem 0 0;
  padding: 15px 0 0;
}

div.window.dialog h1 {
  font-size: 1.5rem;
}

div.window.dialog h2 {
  font-size: 1rem;
  padding-bottom: .15rem !important;
}

div.window.dialog .miwt-table {
  font-size: 85%;
}

div.window.dialog .miwt-table > tbody > tr + tr {
  border-top: 1px solid white;
}

div.window.dialog .prop, div.window.dialog .prop-group {
  margin-bottom: 15px;
}

div.window.dialog .prop.has-error, div.window.dialog .prop-group.has-error {
  padding: 0 !important;
  /** FIXME : fix/remove this */
  background: transparent;
  border: none;
}

div.window.dialog .prop .prop, div.window.dialog .prop-group .prop {
  /** FIXME : remove this */
  float: none;
  width: 100%;
  margin: 0;
}

div.window.dialog .actions {
  clear: both;
  text-align: right;
}

div.window.dialog input[type="text"], div.window.dialog input[type="tel"], div.window.dialog input[type="email"], div.window.dialog textarea {
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  display: block;
  width: 100%;
  padding: 10px 15px;
  color: #4a4a4b;
  outline: none;
  -webkit-transition: border .15s ease;
  transition: border .15s ease;
}

div.window.dialog textarea {
  padding: 5px;
  font-style: italic;
  min-height: 100px;
  resize: none;
}

div.window.dialog input[type="file"] {
  margin: 1rem 0 0;
  padding: .5rem;
  background: #F4F6FC;
}

div.window.dialog input[type="file"] + .actions {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid #F4F6FC;
}

div.window.dialog label {
  color: #cfcfcf;
  font-weight: 700;
  font-size: .875rem;
  margin: 0 0 5px;
  line-height: 1;
}

div.window.dialog .prop-body {
  max-width: none;
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
}

div.window.dialog .prop-footer {
  margin: 0;
  max-width: none;
}

div.window.dialog .prop-footer .actions {
  max-width: none;
}

div.window.dialog .prop-body div.prop {
  display: block;
}

@media (max-width: 767px) {
  div.window.dialog .windowcontent {
    padding: 10px;
  }
  div.window.dialog td.activewindowtitle {
    font-size: 1.1rem;
  }
}

.copyable {
  cursor: url(/_design/default/icons/cursor-copy.png) 2 2, copy;
  -webkit-user-select: all;
     -moz-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

.copyable-alert-box {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10000;
}

.copyable-alert-box:empty {
  display: none;
}

.alert-dismissible {
  word-break: break-all;
}

.alert-dismissible .close {
  cursor: pointer;
  background: transparent;
  border: none;
  line-height: 1;
  padding: 0 15px;
  height: 100%;
  font-size: 0;
}

.alert-dismissible .close:after {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

/**
 * Mixin to build out the calendar theme
 * Created by djacobi on 6/12/17.
 */
div.calendar {
  padding: 1px;
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 1px 20px 3px rgba(0, 0, 0, 0.33);
          box-shadow: 0 1px 20px 3px rgba(0, 0, 0, 0.33);
  position: relative;
  border: none;
  font-size: 13px;
  color: #343940;
  cursor: default;
  background: #dbe3e8;
  font-family: Arial, sans-serif;
  /* Header part -- contains navigation buttons and day names. */
  /* The body part -- contains all the days in month. */
  /* The footer part -- status bar and "Close" button */
  /* Combo boxes (menus that display months/years for direct selection) */
  /* ----- TIME */
}

div.calendar table {
  border: none;
}

div.calendar .button {
  /* "<<", "<", ">", ">>" buttons have this class */
  text-align: center;
  /* They are the navigation buttons */
  padding: 4px;
  /* Make the buttons seem like they're pressing */
  cursor: pointer;
  color: #849fb0;
}

div.calendar .nav {
  background: #7cc6e1;
  color: #2785a7;
  position: relative;
}

div.calendar .nav::after {
  content: '';
  display: block;
  border-top: 4px solid #2785a7;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  right: 4px;
  bottom: 4px;
}

div.calendar thead .title {
  /* This holds the current "month, year" */
  font-weight: bold;
  /* Pressing it will take you to the current date */
  text-align: center;
  background: #fafbfc;
  color: #a3b7c4;
  padding: 4px;
}

div.calendar thead .headrow {
  /* Row <TR> containing navigation buttons */
  background: #7cc6e1;
  color: #343940;
}

div.calendar thead .headrow .hilite {
  background: #4AB0D5;
  color: #1b5d75;
}

div.calendar thead .headrow .button {
  color: #2785a7;
}

div.calendar thead .daynames {
  /* Row <TR> containing the day names */
  background: #4AB0D5;
}

div.calendar thead .daynames .hilite {
  background: #7cc6e1;
  color: #2785a7;
}

div.calendar thead .name {
  /* Cells <TD> containing the day names */
  padding: 4px;
  text-align: center;
  color: #fff;
}

div.calendar thead .weekend {
  /* How a weekend day name shows in header */
  color: #1b5d75;
}

div.calendar thead .active {
  /* Active (pressed) buttons in header */
  background-color: #2b92b7;
  color: #6bbedd;
}

div.calendar tbody .rowhilite td {
  background: #fafbfc;
}

div.calendar tbody .rowhilite td.wn {
  background: #4AB0D5;
}

div.calendar tbody .rowhilite td.weekend {
  color: #849fb0;
}

div.calendar tbody td {
  cursor: pointer;
}

div.calendar tbody td.day {
  /* Cells <TD> containing month days dates */
  width: 32px;
  color: #343940;
  text-align: center;
  padding: 4px;
}

div.calendar tbody td.day.othermonth {
  font-size: 80%;
  background-color: #fafbfc;
  color: #a3b7c4;
}

div.calendar tbody td.day.othermonth.oweekend {
  color: #a3b7c4;
}

div.calendar tbody td.hilite {
  /* Hovered cells <TD> */
  background: white;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.65);
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.65);
  color: #b8b8b8;
}

div.calendar tbody td.active {
  /* Active (pressed) cells <TD> */
  background: #c2d0d8;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

div.calendar tbody td.selected, div.calendar tbody td.selected.weekend {
  /* Cell showing today date */
  font-weight: bold;
  background: #4AB0D5;
  color: #1b5d75;
}

div.calendar tbody td.wn {
  /* Week numbers */
  padding: 2px 3px 2px 2px;
  background: #4AB0D5;
  color: #fff;
}

div.calendar tbody td.weekend {
  /* Cells showing weekend days */
  color: #849fb0;
}

div.calendar tbody td.weekend.hilite {
  color: #b8b8b8;
}

div.calendar tbody td.today {
  font-weight: bold;
  background: #7cc6e1;
  color: #2785a7;
}

div.calendar tbody .disabled {
  color: #999;
}

div.calendar tbody .emptycell {
  /* Empty cells (the best is to hide them) */
  visibility: hidden;
}

div.calendar tbody .emptyrow {
  /* Empty row (some months need less than 6 rows) */
  display: none;
}

div.calendar tfoot .footrow {
  /* The <TR> in footer (only one right now) */
  text-align: center;
  background: #7cc6e1;
  color: #2785a7;
}

div.calendar tfoot .ttip {
  /* Tooltip (status bar) cell <TD> */
  padding: 2px;
  background: #7cc6e1;
  color: #2785a7;
}

div.calendar tfoot .hilite {
  /* Hover style for buttons in footer */
  background: #4AB0D5;
}

div.calendar tfoot .active {
  /* Active (pressed) style for buttons in footer */
  background: #2e9fc8;
}

div.calendar .combo {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 4em;
  cursor: default;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
  background: #7cc6e1;
  color: #343940;
  font-size: 11px;
  z-index: 100;
}

div.calendar .combo .label,
div.calendar .combo .label-IEfix {
  text-align: center;
  padding: 4px;
  border-radius: 0;
  font-size: 100%;
  line-height: 1.25;
  width: 100%;
}

div.calendar .combo .label-IEfix {
  width: 4em;
}

div.calendar .combo .hilite {
  background: #4AB0D5;
}

div.calendar .combo .active {
  background: #4AB0D5;
  font-weight: bold;
}

div.calendar td.time {
  padding: 4px;
  text-align: center;
  background-color: #7cc6e1;
}

div.calendar td.time .hour,
div.calendar td.time .minute,
div.calendar td.time .ampm {
  padding: 0 3px 0 4px;
  font-weight: 600;
  color: #343940;
}

div.calendar td.time .ampm {
  text-align: center;
}

div.calendar td.time .colon {
  padding: 0px 2px 0px 3px;
  font-weight: 600;
}

div.calendar td.time span.hilite {
  background-color: #4AB0D5;
  color: #343940;
}

div.calendar td.time span.active {
  background-color: #dbe3e8;
  color: #343940;
}

/**
* For collapsible content
*/
.prop-wrapper {
  font-family: "Roboto", Arial, Verdana, sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.prop-wrapper input,
.prop-wrapper textarea {
  border: 1px solid #cfcfcf;
  padding: 6px 8px 5px;
  font-size: 1rem;
  line-height: 1.15;
}

.prop-wrapper input:focus,
.prop-wrapper select:focus,
.prop-wrapper textarea:focus {
  background-color: #fff;
}

.prop-wrapper button,
.prop-wrapper input[type="submit"],
.prop-wrapper input[type="reset"],
.prop-wrapper .constraints a[data-features~="submit"] {
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
}

.prop-wrapper input[type=number] {
  width: 8ch;
}

.prop-wrapper .prop-header-title {
  font-size: 22px;
  font-weight: 500;
  margin: 1em 0;
}

.prop-wrapper .prop-group-title {
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0;
}

.prop-wrapper span.ctb.label-before label {
  margin-right: 6px;
}

.prop-wrapper span.ctb.label-after label {
  margin-left: 6px;
}

.prop-wrapper .prop > label, .prop-wrapper .prop > .label, .prop-wrapper span.title {
  display: block;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: .125rem;
  text-transform: uppercase;
}

.prop-wrapper .prop > label.tbl {
  display: inline-block;
  padding-right: 4px;
}

.prop-wrapper .prop > .val,
.prop-wrapper .prop .localizable .val {
  display: inline-block;
  font-weight: 300;
}

.prop-wrapper header .actions, .prop-wrapper footer .actions {
  margin: 10px 0;
  text-align: right;
}

.prop-wrapper .prop {
  margin-bottom: 1rem;
  padding: 5px;
  font-weight: 300;
}

.prop-wrapper .prop > .actions .btn, .prop-wrapper .prop .selected-link .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.prop-wrapper .prop-group {
  margin-bottom: 0;
}

.prop-wrapper .user-entry-required .label:after,
.prop-wrapper .user-entry-required label:after,
.prop-wrapper .user-entry-required caption:after {
  content: "*";
  color: #E2757E;
}

.prop-wrapper .validation-error input[type="text"] {
  border: 1px solid #E2757E;
}

.prop-wrapper .dc-file-upload {
  position: relative;
  padding: 25px;
  min-height: 95px;
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  margin-top: 50px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.prop-wrapper .dc-file-upload > .label {
  position: absolute;
  top: -1px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  left: 0;
  margin: 0;
  font-weight: 700;
}

.prop-wrapper .dc-file-upload input[type="file"] {
  color: transparent;
  background: none !important;
  border: none;
  max-width: 200px;
  margin-top: 0 !important;
}

.prop-wrapper .dc-file-upload input[type="file"]:focus {
  outline: none;
}

.prop-wrapper .dc-file-upload input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.prop-wrapper .dc-file-upload input[type="file"]::before {
  content: 'Select File';
  display: block;
  background: #488fb4;
  border: #488fb4;
  color: #fff;
  border-radius: 3px;
  padding: 10px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.prop-wrapper .dc-file-upload .file-name {
  margin: 5px;
  color: rgb(var(--color-brand-accent));
}

.prop-wrapper .dc-file-upload .clear-file {
  margin: 5px;
  padding: 0;
  background: 0 0;
  border: none;
  text-decoration: underline;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: auto;
}

.prop-wrapper .dc-file-upload .clear-file:hover {
  text-decoration: none;
  color: rgb(var(--color-brand-main));
}

.prop-wrapper .dc-file-upload input {
  margin: 5px;
}

body.editing-component .component-editor-dlg .editor-content .prop .prop-video input {
  width: unset !important;
}

/**
* For collapsible content
*/
.prop-group .prop-img, .prop-group .prop-file, .prop-group .dc-file-upload {
  position: relative;
  padding: 25px;
  min-height: 95px;
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  border-bottom: 3px solid #cfcfcf;
  margin-top: 50px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.prop-group .prop-img:focus, .prop-group .prop-file:focus, .prop-group .dc-file-upload:focus {
  border-bottom: 3px solid #488fb4;
}

.prop-group .prop-img > .label, .prop-group .prop-file > .label, .prop-group .dc-file-upload > .label {
  position: absolute;
  top: -1px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  left: 0;
  margin: 0;
  font-weight: 700;
}

.prop-group .prop-img input[type="file"], .prop-group .prop-file input[type="file"], .prop-group .dc-file-upload input[type="file"] {
  color: transparent;
  background: none !important;
  border: none;
  max-width: 200px;
  margin-top: 0 !important;
}

.prop-group .prop-img input[type="file"]:focus, .prop-group .prop-file input[type="file"]:focus, .prop-group .dc-file-upload input[type="file"]:focus {
  outline: none;
}

.prop-group .prop-img input[type="file"]::-webkit-file-upload-button, .prop-group .prop-file input[type="file"]::-webkit-file-upload-button, .prop-group .dc-file-upload input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.prop-group .prop-img input[type="file"]::before, .prop-group .prop-file input[type="file"]::before, .prop-group .dc-file-upload input[type="file"]::before {
  content: 'Select File';
  display: block;
  background: #488fb4;
  border: #488fb4;
  color: #fff;
  border-radius: 3px;
  padding: 10px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.prop-group .prop-img .file-name, .prop-group .prop-file .file-name, .prop-group .dc-file-upload .file-name {
  margin: 5px;
  color: rgb(var(--color-brand-accent));
}

.prop-group .prop-img .clear-file, .prop-group .prop-file .clear-file, .prop-group .dc-file-upload .clear-file {
  margin: 5px;
  padding: 0;
  background: 0 0;
  border: none;
  text-decoration: underline;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: auto;
}

.prop-group .prop-img .clear-file:hover, .prop-group .prop-file .clear-file:hover, .prop-group .dc-file-upload .clear-file:hover {
  text-decoration: none;
  color: rgb(var(--color-brand-main));
}

.prop-group .prop-img input, .prop-group .prop-file input, .prop-group .dc-file-upload input {
  margin: 5px;
}

.prop-viewer {
  max-width: 780px;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 40px;
  margin: 0 auto 30px;
  background: #fff;
}

@media (min-width: 992px) {
  .prop-viewer {
    padding: 40px 80px;
  }
}

.prop-viewer .actions.top {
  display: none;
}

.prop-viewer .address > span {
  display: block;
  font-weight: 300;
}

.prop-editor .file-upload {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  position: relative;
  padding: 25px;
  min-height: 95px;
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  border-bottom: 3px solid rgb(var(--color-brand-accent));
  margin-top: 50px;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 992px) {
  .prop-editor .file-upload {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.prop-editor .file-upload:before {
  content: 'Select File';
  display: inline-block;
  background: rgb(var(--color-brand-accent));
  color: #fff;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  line-height: 1;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: color .25s ease, background .25s ease;
  transition: color .25s ease, background .25s ease;
}

.prop-editor .file-upload:hover:before {
  color: rgb(var(--color-brand-accent));
  background: #fff;
}

.prop-editor .file-upload > img {
  background: #fff;
  padding: 5px;
  border: 1px dashed #dbe3e8;
  margin: 0 0 15px;
  height: 30px;
  width: auto;
}

@media (min-width: 992px) {
  .prop-editor .file-upload > img {
    margin: 0 15px 0 0;
  }
}

.prop-editor .file-upload .actions {
  margin: 0;
  padding: 0;
}

@media (min-width: 992px) {
  .prop-editor .file-upload .actions {
    margin-right: 15px;
  }
}

.prop-editor .file-upload .actions .btn {
  display: none;
}

.prop-editor .file-upload .actions .btn:first-of-type {
  display: inline-block;
  color: #fff;
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
  text-transform: uppercase;
  font-size: 1.125rem;
  padding: 8px 15px;
  border-radius: 5px;
}

.prop-editor .file-upload .actions .btn:first-of-type:hover {
  color: rgb(var(--color-brand-accent));
  background-color: #fff;
  border-color: #fff;
}

.prop-editor .file-upload .actions .btn:first-of-type:active, .prop-editor .file-upload .actions .btn:first-of-type.active,
.open > .prop-editor .file-upload .actions .btn:first-of-type.dropdown-toggle {
  color: #fff;
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.prop-editor .file-upload .actions .btn:first-of-type:active:hover, .prop-editor .file-upload .actions .btn:first-of-type:active:focus, .prop-editor .file-upload .actions .btn:first-of-type:active.focus, .prop-editor .file-upload .actions .btn:first-of-type.active:hover, .prop-editor .file-upload .actions .btn:first-of-type.active:focus, .prop-editor .file-upload .actions .btn:first-of-type.active.focus,
.open > .prop-editor .file-upload .actions .btn:first-of-type.dropdown-toggle:hover,
.open > .prop-editor .file-upload .actions .btn:first-of-type.dropdown-toggle:focus,
.open > .prop-editor .file-upload .actions .btn:first-of-type.dropdown-toggle.focus {
  color: #fff;
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
}

.prop-editor .file-upload .actions .btn:first-of-type.disabled:focus, .prop-editor .file-upload .actions .btn:first-of-type.disabled.focus, .prop-editor .file-upload .actions .btn:first-of-type:disabled:focus, .prop-editor .file-upload .actions .btn:first-of-type:disabled.focus {
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
}

.prop-editor .file-upload .actions .btn:first-of-type.disabled:hover, .prop-editor .file-upload .actions .btn:first-of-type:disabled:hover {
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
}

.prop-editor .file-upload .file-name {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-size: 1.125rem;
}

.prop-editor .file-upload .label:not(.image-label),
.prop-editor .file-upload label:not(.image-label) {
  position: absolute;
  top: -1px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  left: 0;
  margin: 0;
}

.prop-editor .file-upload .label.image-label,
.prop-editor .file-upload label.image-label {
  margin: 15px 0 0;
  font-weight: 500;
  text-transform: none;
  font-size: 1.125rem;
  word-break: break-word;
}

.prop-editor .file-upload .label.image-label:empty,
.prop-editor .file-upload label.image-label:empty {
  display: none;
}

.prop-editor .file-upload .label.image-label:after,
.prop-editor .file-upload label.image-label:after {
  content: none;
}

@media (min-width: 992px) {
  .prop-editor .file-upload .label.image-label,
  .prop-editor .file-upload label.image-label {
    margin: 0 0 0 10px;
  }
}

.prop-editor .file-upload input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0;
}

.prop-editor .select2 {
  width: 100% !important;
}

.prop-editor .prop.name input[type=text] {
  width: 100%;
}

.prop-editor .prop .actions {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.prop-editor .prop span.rtb + span.rtb {
  padding-left: 1rem;
}

.prop-editor .prop span.ctb + span.ctb {
  padding-left: 1rem;
}

@media (min-width: 992px) {
  .prop-editor .prop-group.address::after {
    display: block;
    clear: both;
    content: "";
  }
  .prop-editor .prop-group.address .prop {
    float: left;
    width: 48%;
  }
  .prop-editor .prop-group.address .prop:nth-of-type(odd) {
    margin-right: 4%;
    clear: both;
  }
}

.prop-editor .miwt-calendar input[type=text] {
  min-width: 8rem;
  max-width: 8rem;
}

div.window.dialog .prop-editor {
  border: none;
}

@media (max-width: 767px) {
  .prop-editor .prop input[type=text],
  .prop-editor .prop input[type=email],
  .prop-editor .prop input[type=url],
  .prop-editor .prop input[type=tel],
  .prop-editor .prop textarea {
    width: 100%;
  }
}

.prop-editor label {
  display: block;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 25px 0 .125rem;
  text-transform: uppercase;
  line-height: 1;
}

.prop-editor h2 {
  font-weight: 500;
  color: #cfcfcf;
}

.prop-editor input[type=text],
.prop-editor input[type=email],
.prop-editor input[type=tel],
.prop-editor textarea {
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  border-bottom: 3px solid rgb(var(--color-brand-accent));
  display: block;
  width: 100%;
  padding: 10px 15px;
  color: #cfcfcf;
  outline: none;
  -webkit-transition: border .15s ease;
  transition: border .15s ease;
}

.prop-editor input[type=text]:focus,
.prop-editor input[type=email]:focus,
.prop-editor input[type=tel]:focus,
.prop-editor textarea:focus {
  border-bottom: 3px solid rgb(var(--color-brand-main));
}

.prop-editor .prop-body {
  max-width: 780px;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 40px;
  margin: 0 auto 30px;
  background: #fff;
}

@media (min-width: 992px) {
  .prop-editor .prop-body {
    padding: 40px 80px;
  }
}

.prop-editor .prop-footer {
  max-width: 780px;
  margin: 0 auto;
}

.prop-editor .prop-header-actions {
  display: none;
}

.prop-editor .actions {
  max-width: 780px;
  margin: 0 auto;
}

.prop-editor .actions button.cancel {
  color: #E2757E;
  background-color: #fff;
  border-color: #E2757E;
}

.prop-editor .actions button.cancel:hover {
  color: #fff;
  background-color: #E2757E;
  border-color: #E2757E;
}

.prop-editor .actions button.cancel:active, .prop-editor .actions button.cancel.active,
.open > .prop-editor .actions button.cancel.dropdown-toggle {
  color: #E2757E;
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.prop-editor .actions button.cancel:active:hover, .prop-editor .actions button.cancel:active:focus, .prop-editor .actions button.cancel:active.focus, .prop-editor .actions button.cancel.active:hover, .prop-editor .actions button.cancel.active:focus, .prop-editor .actions button.cancel.active.focus,
.open > .prop-editor .actions button.cancel.dropdown-toggle:hover,
.open > .prop-editor .actions button.cancel.dropdown-toggle:focus,
.open > .prop-editor .actions button.cancel.dropdown-toggle.focus {
  color: #E2757E;
  background-color: rgb(var(--color-brand-accent));
  border-color: rgb(var(--color-brand-accent));
}

.prop-editor .actions button.cancel.disabled:focus, .prop-editor .actions button.cancel.disabled.focus, .prop-editor .actions button.cancel:disabled:focus, .prop-editor .actions button.cancel:disabled.focus {
  background-color: #fff;
  border-color: #E2757E;
}

.prop-editor .actions button.cancel.disabled:hover, .prop-editor .actions button.cancel:disabled:hover {
  background-color: #fff;
  border-color: #E2757E;
}

/**
* For collapsible content
*/
.custom-content-editor .dc-file-upload {
  position: relative;
  padding: 25px;
  min-height: 95px;
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  border-bottom: 3px solid #cfcfcf;
  margin-top: 50px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.custom-content-editor .dc-file-upload:focus {
  border-bottom: 3px solid #488fb4;
}

.custom-content-editor .dc-file-upload > .label {
  position: absolute;
  top: -1px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  left: 0;
  margin: 0;
  font-weight: 700;
  margin-top: 0 !important;
}

.custom-content-editor .dc-file-upload input[type="file"] {
  color: transparent;
  background: none !important;
  border: none;
  max-width: 200px;
  margin-top: 0 !important;
}

.custom-content-editor .dc-file-upload input[type="file"]:focus {
  outline: none;
}

.custom-content-editor .dc-file-upload input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-content-editor .dc-file-upload input[type="file"]::before {
  content: 'Select File';
  display: block;
  background: #488fb4;
  border: #488fb4;
  color: #fff;
  border-radius: 3px;
  padding: 10px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.custom-content-editor .dc-file-upload .file-name {
  margin: 5px;
  color: rgb(var(--color-brand-accent));
}

.custom-content-editor .dc-file-upload .clear-file {
  margin: 5px;
  padding: 0;
  background: 0 0;
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: auto;
}

.custom-content-editor .dc-file-upload .clear-file:hover {
  text-decoration: none;
  color: rgb(var(--color-brand-main));
}

.custom-content-editor .dc-file-upload input {
  margin: 5px;
  border: 1px solid #aaa;
}

.responsive-video-standard {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.responsive-video-standard iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video-standard video {
  width: 100%;
  height: auto;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1A76B8;
  border-color: #1A76B8;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary:hover {
  color: #fff;
  background-color: #156196;
  border-color: #14598b;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #156196;
  border-color: #14598b;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(60, 139, 195, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(60, 139, 195, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1A76B8;
  border-color: #1A76B8;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #14598b;
  border-color: #125280;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(60, 139, 195, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(60, 139, 195, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-success:hover {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #409440;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #62C5DC;
  border-color: #62C5DC;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-info:hover {
  color: #212529;
  background-color: #43b9d5;
  border-color: #38b6d3;
}

.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #43b9d5;
  border-color: #38b6d3;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(88, 173, 193, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(88, 173, 193, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #62C5DC;
  border-color: #62C5DC;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #38b6d3;
  border-color: #2eb1d0;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(88, 173, 193, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(88, 173, 193, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-warning:hover {
  color: #212529;
  background-color: #f5ae07;
  border-color: #e8a507;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #f5ae07;
  border-color: #e8a507;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(217, 165, 41, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(217, 165, 41, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e8a507;
  border-color: #dc9c06;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(217, 165, 41, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(217, 165, 41, 0.5);
}

.btn-danger {
  color: #212529;
  background-color: #E2757E;
  border-color: #E2757E;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-danger:hover {
  color: #fff;
  background-color: #db5560;
  border-color: #d94b57;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #db5560;
  border-color: #d94b57;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(197, 105, 113, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(197, 105, 113, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #212529;
  background-color: #E2757E;
  border-color: #E2757E;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d94b57;
  border-color: #d7404d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(197, 105, 113, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(197, 105, 113, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-outline-primary {
  color: #1A76B8;
  border-color: #1A76B8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1A76B8;
  border-color: #1A76B8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 118, 184, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(26, 118, 184, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1A76B8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1A76B8;
  border-color: #1A76B8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(26, 118, 184, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(26, 118, 184, 0.5);
}

.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-info {
  color: #62C5DC;
  border-color: #62C5DC;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #62C5DC;
  border-color: #62C5DC;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(98, 197, 220, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(98, 197, 220, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #62C5DC;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #62C5DC;
  border-color: #62C5DC;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(98, 197, 220, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(98, 197, 220, 0.5);
}

.btn-outline-warning {
  color: #F9BB29;
  border-color: #F9BB29;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F9BB29;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #F9BB29;
  border-color: #F9BB29;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 187, 41, 0.5);
}

.btn-outline-danger {
  color: #E2757E;
  border-color: #E2757E;
}

.btn-outline-danger:hover {
  color: #212529;
  background-color: #E2757E;
  border-color: #E2757E;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(226, 117, 126, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(226, 117, 126, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #E2757E;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #E2757E;
  border-color: #E2757E;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(226, 117, 126, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(226, 117, 126, 0.5);
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #000;
  border-color: #000;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 8px 15px 7px;
  line-height: 1;
  text-align: center;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
  font-family: "Roboto", Arial, Verdana, sans-serif;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:hover {
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.btn:active, .btn.active,
.open > .btn.dropdown-toggle {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:active:hover, .btn:active:focus, .btn:active.focus, .btn.active:hover, .btn.active:focus, .btn.active.focus,
.open > .btn.dropdown-toggle:hover,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle.focus {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.btn.disabled:focus, .btn.disabled.focus, .btn:disabled:focus, .btn:disabled.focus {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.btn.disabled:hover, .btn:disabled:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.actions .btn + .btn {
  margin-left: 12px;
}

.actions .btn.btn-primary, .actions .btn.submit, .actions .btn.save, .actions .btn.action-save, .actions .btn.add, .actions .btn.finish-button, .actions .btn.action-save-exit, .actions .btn.save-exit, .actions .btn.confirm, .actions .btn.ok, .actions .btn.yes, .actions .btn.send {
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
  font-size: 0.9rem;
  padding: .75rem 1.5rem;
  font-weight: 600;
  border-radius: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.actions .btn.btn-primary:hover, .actions .btn.submit:hover, .actions .btn.save:hover, .actions .btn.action-save:hover, .actions .btn.add:hover, .actions .btn.finish-button:hover, .actions .btn.action-save-exit:hover, .actions .btn.save-exit:hover, .actions .btn.confirm:hover, .actions .btn.ok:hover, .actions .btn.yes:hover, .actions .btn.send:hover {
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: #fff;
}

.actions .btn.btn-primary:active, .actions .btn.btn-primary.active,
.open > .actions .btn.btn-primary.dropdown-toggle, .actions .btn.submit:active, .actions .btn.submit.active,
.open > .actions .btn.submit.dropdown-toggle, .actions .btn.save:active, .actions .btn.save.active,
.open > .actions .btn.save.dropdown-toggle, .actions .btn.action-save:active, .actions .btn.action-save.active,
.open > .actions .btn.action-save.dropdown-toggle, .actions .btn.add:active, .actions .btn.add.active,
.open > .actions .btn.add.dropdown-toggle, .actions .btn.finish-button:active, .actions .btn.finish-button.active,
.open > .actions .btn.finish-button.dropdown-toggle, .actions .btn.action-save-exit:active, .actions .btn.action-save-exit.active,
.open > .actions .btn.action-save-exit.dropdown-toggle, .actions .btn.save-exit:active, .actions .btn.save-exit.active,
.open > .actions .btn.save-exit.dropdown-toggle, .actions .btn.confirm:active, .actions .btn.confirm.active,
.open > .actions .btn.confirm.dropdown-toggle, .actions .btn.ok:active, .actions .btn.ok.active,
.open > .actions .btn.ok.dropdown-toggle, .actions .btn.yes:active, .actions .btn.yes.active,
.open > .actions .btn.yes.dropdown-toggle, .actions .btn.send:active, .actions .btn.send.active,
.open > .actions .btn.send.dropdown-toggle {
  color: #fff;
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.btn-primary:active:hover, .actions .btn.btn-primary:active:focus, .actions .btn.btn-primary:active.focus, .actions .btn.btn-primary.active:hover, .actions .btn.btn-primary.active:focus, .actions .btn.btn-primary.active.focus,
.open > .actions .btn.btn-primary.dropdown-toggle:hover,
.open > .actions .btn.btn-primary.dropdown-toggle:focus,
.open > .actions .btn.btn-primary.dropdown-toggle.focus, .actions .btn.submit:active:hover, .actions .btn.submit:active:focus, .actions .btn.submit:active.focus, .actions .btn.submit.active:hover, .actions .btn.submit.active:focus, .actions .btn.submit.active.focus,
.open > .actions .btn.submit.dropdown-toggle:hover,
.open > .actions .btn.submit.dropdown-toggle:focus,
.open > .actions .btn.submit.dropdown-toggle.focus, .actions .btn.save:active:hover, .actions .btn.save:active:focus, .actions .btn.save:active.focus, .actions .btn.save.active:hover, .actions .btn.save.active:focus, .actions .btn.save.active.focus,
.open > .actions .btn.save.dropdown-toggle:hover,
.open > .actions .btn.save.dropdown-toggle:focus,
.open > .actions .btn.save.dropdown-toggle.focus, .actions .btn.action-save:active:hover, .actions .btn.action-save:active:focus, .actions .btn.action-save:active.focus, .actions .btn.action-save.active:hover, .actions .btn.action-save.active:focus, .actions .btn.action-save.active.focus,
.open > .actions .btn.action-save.dropdown-toggle:hover,
.open > .actions .btn.action-save.dropdown-toggle:focus,
.open > .actions .btn.action-save.dropdown-toggle.focus, .actions .btn.add:active:hover, .actions .btn.add:active:focus, .actions .btn.add:active.focus, .actions .btn.add.active:hover, .actions .btn.add.active:focus, .actions .btn.add.active.focus,
.open > .actions .btn.add.dropdown-toggle:hover,
.open > .actions .btn.add.dropdown-toggle:focus,
.open > .actions .btn.add.dropdown-toggle.focus, .actions .btn.finish-button:active:hover, .actions .btn.finish-button:active:focus, .actions .btn.finish-button:active.focus, .actions .btn.finish-button.active:hover, .actions .btn.finish-button.active:focus, .actions .btn.finish-button.active.focus,
.open > .actions .btn.finish-button.dropdown-toggle:hover,
.open > .actions .btn.finish-button.dropdown-toggle:focus,
.open > .actions .btn.finish-button.dropdown-toggle.focus, .actions .btn.action-save-exit:active:hover, .actions .btn.action-save-exit:active:focus, .actions .btn.action-save-exit:active.focus, .actions .btn.action-save-exit.active:hover, .actions .btn.action-save-exit.active:focus, .actions .btn.action-save-exit.active.focus,
.open > .actions .btn.action-save-exit.dropdown-toggle:hover,
.open > .actions .btn.action-save-exit.dropdown-toggle:focus,
.open > .actions .btn.action-save-exit.dropdown-toggle.focus, .actions .btn.save-exit:active:hover, .actions .btn.save-exit:active:focus, .actions .btn.save-exit:active.focus, .actions .btn.save-exit.active:hover, .actions .btn.save-exit.active:focus, .actions .btn.save-exit.active.focus,
.open > .actions .btn.save-exit.dropdown-toggle:hover,
.open > .actions .btn.save-exit.dropdown-toggle:focus,
.open > .actions .btn.save-exit.dropdown-toggle.focus, .actions .btn.confirm:active:hover, .actions .btn.confirm:active:focus, .actions .btn.confirm:active.focus, .actions .btn.confirm.active:hover, .actions .btn.confirm.active:focus, .actions .btn.confirm.active.focus,
.open > .actions .btn.confirm.dropdown-toggle:hover,
.open > .actions .btn.confirm.dropdown-toggle:focus,
.open > .actions .btn.confirm.dropdown-toggle.focus, .actions .btn.ok:active:hover, .actions .btn.ok:active:focus, .actions .btn.ok:active.focus, .actions .btn.ok.active:hover, .actions .btn.ok.active:focus, .actions .btn.ok.active.focus,
.open > .actions .btn.ok.dropdown-toggle:hover,
.open > .actions .btn.ok.dropdown-toggle:focus,
.open > .actions .btn.ok.dropdown-toggle.focus, .actions .btn.yes:active:hover, .actions .btn.yes:active:focus, .actions .btn.yes:active.focus, .actions .btn.yes.active:hover, .actions .btn.yes.active:focus, .actions .btn.yes.active.focus,
.open > .actions .btn.yes.dropdown-toggle:hover,
.open > .actions .btn.yes.dropdown-toggle:focus,
.open > .actions .btn.yes.dropdown-toggle.focus, .actions .btn.send:active:hover, .actions .btn.send:active:focus, .actions .btn.send:active.focus, .actions .btn.send.active:hover, .actions .btn.send.active:focus, .actions .btn.send.active.focus,
.open > .actions .btn.send.dropdown-toggle:hover,
.open > .actions .btn.send.dropdown-toggle:focus,
.open > .actions .btn.send.dropdown-toggle.focus {
  color: #fff;
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.actions .btn.btn-primary.disabled:focus, .actions .btn.btn-primary.disabled.focus, .actions .btn.btn-primary:disabled:focus, .actions .btn.btn-primary:disabled.focus, .actions .btn.submit.disabled:focus, .actions .btn.submit.disabled.focus, .actions .btn.submit:disabled:focus, .actions .btn.submit:disabled.focus, .actions .btn.save.disabled:focus, .actions .btn.save.disabled.focus, .actions .btn.save:disabled:focus, .actions .btn.save:disabled.focus, .actions .btn.action-save.disabled:focus, .actions .btn.action-save.disabled.focus, .actions .btn.action-save:disabled:focus, .actions .btn.action-save:disabled.focus, .actions .btn.add.disabled:focus, .actions .btn.add.disabled.focus, .actions .btn.add:disabled:focus, .actions .btn.add:disabled.focus, .actions .btn.finish-button.disabled:focus, .actions .btn.finish-button.disabled.focus, .actions .btn.finish-button:disabled:focus, .actions .btn.finish-button:disabled.focus, .actions .btn.action-save-exit.disabled:focus, .actions .btn.action-save-exit.disabled.focus, .actions .btn.action-save-exit:disabled:focus, .actions .btn.action-save-exit:disabled.focus, .actions .btn.save-exit.disabled:focus, .actions .btn.save-exit.disabled.focus, .actions .btn.save-exit:disabled:focus, .actions .btn.save-exit:disabled.focus, .actions .btn.confirm.disabled:focus, .actions .btn.confirm.disabled.focus, .actions .btn.confirm:disabled:focus, .actions .btn.confirm:disabled.focus, .actions .btn.ok.disabled:focus, .actions .btn.ok.disabled.focus, .actions .btn.ok:disabled:focus, .actions .btn.ok:disabled.focus, .actions .btn.yes.disabled:focus, .actions .btn.yes.disabled.focus, .actions .btn.yes:disabled:focus, .actions .btn.yes:disabled.focus, .actions .btn.send.disabled:focus, .actions .btn.send.disabled.focus, .actions .btn.send:disabled:focus, .actions .btn.send:disabled.focus {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.actions .btn.btn-primary.disabled:hover, .actions .btn.btn-primary:disabled:hover, .actions .btn.submit.disabled:hover, .actions .btn.submit:disabled:hover, .actions .btn.save.disabled:hover, .actions .btn.save:disabled:hover, .actions .btn.action-save.disabled:hover, .actions .btn.action-save:disabled:hover, .actions .btn.add.disabled:hover, .actions .btn.add:disabled:hover, .actions .btn.finish-button.disabled:hover, .actions .btn.finish-button:disabled:hover, .actions .btn.action-save-exit.disabled:hover, .actions .btn.action-save-exit:disabled:hover, .actions .btn.save-exit.disabled:hover, .actions .btn.save-exit:disabled:hover, .actions .btn.confirm.disabled:hover, .actions .btn.confirm:disabled:hover, .actions .btn.ok.disabled:hover, .actions .btn.ok:disabled:hover, .actions .btn.yes.disabled:hover, .actions .btn.yes:disabled:hover, .actions .btn.send.disabled:hover, .actions .btn.send:disabled:hover {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.actions .btn.btn-primary:hover, .actions .btn.submit:hover, .actions .btn.save:hover, .actions .btn.action-save:hover, .actions .btn.add:hover, .actions .btn.finish-button:hover, .actions .btn.action-save-exit:hover, .actions .btn.save-exit:hover, .actions .btn.confirm:hover, .actions .btn.ok:hover, .actions .btn.yes:hover, .actions .btn.send:hover {
  border-color: rgb(var(--color-brand-main));
}

.actions .btn.delete, .actions .btn.remove, .actions .btn.reschedule {
  color: #E2757E;
  background-color: #fff;
  border-color: #E2757E;
}

.actions .btn.delete:hover, .actions .btn.remove:hover, .actions .btn.reschedule:hover {
  color: #fff;
  background-color: #E2757E;
  border-color: #E2757E;
}

.actions .btn.delete:active, .actions .btn.delete.active,
.open > .actions .btn.delete.dropdown-toggle, .actions .btn.remove:active, .actions .btn.remove.active,
.open > .actions .btn.remove.dropdown-toggle, .actions .btn.reschedule:active, .actions .btn.reschedule.active,
.open > .actions .btn.reschedule.dropdown-toggle {
  color: #E2757E;
  background-color: #e6e6e6;
  border-color: #d7424f;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.delete:active:hover, .actions .btn.delete:active:focus, .actions .btn.delete:active.focus, .actions .btn.delete.active:hover, .actions .btn.delete.active:focus, .actions .btn.delete.active.focus,
.open > .actions .btn.delete.dropdown-toggle:hover,
.open > .actions .btn.delete.dropdown-toggle:focus,
.open > .actions .btn.delete.dropdown-toggle.focus, .actions .btn.remove:active:hover, .actions .btn.remove:active:focus, .actions .btn.remove:active.focus, .actions .btn.remove.active:hover, .actions .btn.remove.active:focus, .actions .btn.remove.active.focus,
.open > .actions .btn.remove.dropdown-toggle:hover,
.open > .actions .btn.remove.dropdown-toggle:focus,
.open > .actions .btn.remove.dropdown-toggle.focus, .actions .btn.reschedule:active:hover, .actions .btn.reschedule:active:focus, .actions .btn.reschedule:active.focus, .actions .btn.reschedule.active:hover, .actions .btn.reschedule.active:focus, .actions .btn.reschedule.active.focus,
.open > .actions .btn.reschedule.dropdown-toggle:hover,
.open > .actions .btn.reschedule.dropdown-toggle:focus,
.open > .actions .btn.reschedule.dropdown-toggle.focus {
  color: #E2757E;
  background-color: #d4d4d4;
  border-color: #b22531;
}

.actions .btn.delete.disabled:focus, .actions .btn.delete.disabled.focus, .actions .btn.delete:disabled:focus, .actions .btn.delete:disabled.focus, .actions .btn.remove.disabled:focus, .actions .btn.remove.disabled.focus, .actions .btn.remove:disabled:focus, .actions .btn.remove:disabled.focus, .actions .btn.reschedule.disabled:focus, .actions .btn.reschedule.disabled.focus, .actions .btn.reschedule:disabled:focus, .actions .btn.reschedule:disabled.focus {
  background-color: #fff;
  border-color: #E2757E;
}

.actions .btn.delete.disabled:hover, .actions .btn.delete:disabled:hover, .actions .btn.remove.disabled:hover, .actions .btn.remove:disabled:hover, .actions .btn.reschedule.disabled:hover, .actions .btn.reschedule:disabled:hover {
  background-color: #fff;
  border-color: #E2757E;
}

.actions .btn.cancel, .actions .btn.action-cancel, .actions .btn.cancel-button, .actions .btn.no {
  color: #E2757E;
  background-color: #fff;
  border-color: #E2757E;
  font-size: 0.9rem;
  padding: .75rem 1.5rem;
  font-weight: 600;
  border-radius: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.actions .btn.cancel:hover, .actions .btn.action-cancel:hover, .actions .btn.cancel-button:hover, .actions .btn.no:hover {
  color: #fff;
  background-color: #E2757E;
  border-color: #E2757E;
}

.actions .btn.cancel:active, .actions .btn.cancel.active,
.open > .actions .btn.cancel.dropdown-toggle, .actions .btn.action-cancel:active, .actions .btn.action-cancel.active,
.open > .actions .btn.action-cancel.dropdown-toggle, .actions .btn.cancel-button:active, .actions .btn.cancel-button.active,
.open > .actions .btn.cancel-button.dropdown-toggle, .actions .btn.no:active, .actions .btn.no.active,
.open > .actions .btn.no.dropdown-toggle {
  color: #E2757E;
  background-color: #e6e6e6;
  border-color: #d7424f;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.cancel:active:hover, .actions .btn.cancel:active:focus, .actions .btn.cancel:active.focus, .actions .btn.cancel.active:hover, .actions .btn.cancel.active:focus, .actions .btn.cancel.active.focus,
.open > .actions .btn.cancel.dropdown-toggle:hover,
.open > .actions .btn.cancel.dropdown-toggle:focus,
.open > .actions .btn.cancel.dropdown-toggle.focus, .actions .btn.action-cancel:active:hover, .actions .btn.action-cancel:active:focus, .actions .btn.action-cancel:active.focus, .actions .btn.action-cancel.active:hover, .actions .btn.action-cancel.active:focus, .actions .btn.action-cancel.active.focus,
.open > .actions .btn.action-cancel.dropdown-toggle:hover,
.open > .actions .btn.action-cancel.dropdown-toggle:focus,
.open > .actions .btn.action-cancel.dropdown-toggle.focus, .actions .btn.cancel-button:active:hover, .actions .btn.cancel-button:active:focus, .actions .btn.cancel-button:active.focus, .actions .btn.cancel-button.active:hover, .actions .btn.cancel-button.active:focus, .actions .btn.cancel-button.active.focus,
.open > .actions .btn.cancel-button.dropdown-toggle:hover,
.open > .actions .btn.cancel-button.dropdown-toggle:focus,
.open > .actions .btn.cancel-button.dropdown-toggle.focus, .actions .btn.no:active:hover, .actions .btn.no:active:focus, .actions .btn.no:active.focus, .actions .btn.no.active:hover, .actions .btn.no.active:focus, .actions .btn.no.active.focus,
.open > .actions .btn.no.dropdown-toggle:hover,
.open > .actions .btn.no.dropdown-toggle:focus,
.open > .actions .btn.no.dropdown-toggle.focus {
  color: #E2757E;
  background-color: #d4d4d4;
  border-color: #b22531;
}

.actions .btn.cancel.disabled:focus, .actions .btn.cancel.disabled.focus, .actions .btn.cancel:disabled:focus, .actions .btn.cancel:disabled.focus, .actions .btn.action-cancel.disabled:focus, .actions .btn.action-cancel.disabled.focus, .actions .btn.action-cancel:disabled:focus, .actions .btn.action-cancel:disabled.focus, .actions .btn.cancel-button.disabled:focus, .actions .btn.cancel-button.disabled.focus, .actions .btn.cancel-button:disabled:focus, .actions .btn.cancel-button:disabled.focus, .actions .btn.no.disabled:focus, .actions .btn.no.disabled.focus, .actions .btn.no:disabled:focus, .actions .btn.no:disabled.focus {
  background-color: #fff;
  border-color: #E2757E;
}

.actions .btn.cancel.disabled:hover, .actions .btn.cancel:disabled:hover, .actions .btn.action-cancel.disabled:hover, .actions .btn.action-cancel:disabled:hover, .actions .btn.cancel-button.disabled:hover, .actions .btn.cancel-button:disabled:hover, .actions .btn.no.disabled:hover, .actions .btn.no:disabled:hover {
  background-color: #fff;
  border-color: #E2757E;
}

.actions .btn.reset {
  color: #4a4a4b;
  background-color: #fff;
  border-color: #cfcfcf;
}

.actions .btn.reset:hover {
  color: #fff;
  background-color: #4a4a4b;
  border-color: #4a4a4b;
}

.actions .btn.reset:active, .actions .btn.reset.active,
.open > .actions .btn.reset.dropdown-toggle {
  color: #4a4a4b;
  background-color: #e6e6e6;
  border-color: #b0b0b0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.reset:active:hover, .actions .btn.reset:active:focus, .actions .btn.reset:active.focus, .actions .btn.reset.active:hover, .actions .btn.reset.active:focus, .actions .btn.reset.active.focus,
.open > .actions .btn.reset.dropdown-toggle:hover,
.open > .actions .btn.reset.dropdown-toggle:focus,
.open > .actions .btn.reset.dropdown-toggle.focus {
  color: #4a4a4b;
  background-color: #d4d4d4;
  border-color: #8f8f8f;
}

.actions .btn.reset.disabled:focus, .actions .btn.reset.disabled.focus, .actions .btn.reset:disabled:focus, .actions .btn.reset:disabled.focus {
  background-color: #fff;
  border-color: #cfcfcf;
}

.actions .btn.reset.disabled:hover, .actions .btn.reset:disabled:hover {
  background-color: #fff;
  border-color: #cfcfcf;
}

.actions .btn.search, .actions .btn.view {
  color: rgb(var(--color-brand-tertiary));
  background-color: #fff;
  border-color: rgb(var(--color-brand-tertiary));
}

.actions .btn.search:hover, .actions .btn.view:hover {
  color: #fff;
  background-color: rgb(var(--color-brand-tertiary));
  border-color: rgb(var(--color-brand-tertiary));
}

.actions .btn.search:active, .actions .btn.search.active,
.open > .actions .btn.search.dropdown-toggle, .actions .btn.view:active, .actions .btn.view.active,
.open > .actions .btn.view.dropdown-toggle {
  color: rgb(var(--color-brand-tertiary));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.search:active:hover, .actions .btn.search:active:focus, .actions .btn.search:active.focus, .actions .btn.search.active:hover, .actions .btn.search.active:focus, .actions .btn.search.active.focus,
.open > .actions .btn.search.dropdown-toggle:hover,
.open > .actions .btn.search.dropdown-toggle:focus,
.open > .actions .btn.search.dropdown-toggle.focus, .actions .btn.view:active:hover, .actions .btn.view:active:focus, .actions .btn.view:active.focus, .actions .btn.view.active:hover, .actions .btn.view.active:focus, .actions .btn.view.active.focus,
.open > .actions .btn.view.dropdown-toggle:hover,
.open > .actions .btn.view.dropdown-toggle:focus,
.open > .actions .btn.view.dropdown-toggle.focus {
  color: rgb(var(--color-brand-tertiary));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.actions .btn.search.disabled:focus, .actions .btn.search.disabled.focus, .actions .btn.search:disabled:focus, .actions .btn.search:disabled.focus, .actions .btn.view.disabled:focus, .actions .btn.view.disabled.focus, .actions .btn.view:disabled:focus, .actions .btn.view:disabled.focus {
  background-color: #fff;
  border-color: rgb(var(--color-brand-tertiary));
}

.actions .btn.search.disabled:hover, .actions .btn.search:disabled:hover, .actions .btn.view.disabled:hover, .actions .btn.view:disabled:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-tertiary));
}

.actions .btn.edit, .actions .btn.change {
  color: #4a4a4b;
  background-color: #fff;
  border-color: #4a4a4b;
}

.actions .btn.edit:hover, .actions .btn.change:hover {
  color: #fff;
  background-color: #4a4a4b;
  border-color: #4a4a4b;
}

.actions .btn.edit:active, .actions .btn.edit.active,
.open > .actions .btn.edit.dropdown-toggle, .actions .btn.change:active, .actions .btn.change.active,
.open > .actions .btn.change.dropdown-toggle {
  color: #4a4a4b;
  background-color: #e6e6e6;
  border-color: #2c2c2c;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.actions .btn.edit:active:hover, .actions .btn.edit:active:focus, .actions .btn.edit:active.focus, .actions .btn.edit.active:hover, .actions .btn.edit.active:focus, .actions .btn.edit.active.focus,
.open > .actions .btn.edit.dropdown-toggle:hover,
.open > .actions .btn.edit.dropdown-toggle:focus,
.open > .actions .btn.edit.dropdown-toggle.focus, .actions .btn.change:active:hover, .actions .btn.change:active:focus, .actions .btn.change:active.focus, .actions .btn.change.active:hover, .actions .btn.change.active:focus, .actions .btn.change.active.focus,
.open > .actions .btn.change.dropdown-toggle:hover,
.open > .actions .btn.change.dropdown-toggle:focus,
.open > .actions .btn.change.dropdown-toggle.focus {
  color: #4a4a4b;
  background-color: #d4d4d4;
  border-color: #0b0b0b;
}

.actions .btn.edit.disabled:focus, .actions .btn.edit.disabled.focus, .actions .btn.edit:disabled:focus, .actions .btn.edit:disabled.focus, .actions .btn.change.disabled:focus, .actions .btn.change.disabled.focus, .actions .btn.change:disabled:focus, .actions .btn.change:disabled.focus {
  background-color: #fff;
  border-color: #4a4a4b;
}

.actions .btn.edit.disabled:hover, .actions .btn.edit:disabled:hover, .actions .btn.change.disabled:hover, .actions .btn.change:disabled:hover {
  background-color: #fff;
  border-color: #4a4a4b;
}

.actions .btn.edit:before, .actions .btn.change:before {
  content: "ïŒƒ";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  margin-right: 5px;
}

.actions .btn.add:before {
  content: "ï•";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-size: .875rem;
  margin-right: 5px;
}

.actions .btn.add + .btn {
  font-size: 0.9rem;
  padding: .75rem 1.5rem;
  font-weight: 600;
  border-radius: 2rem;
  text-transform: uppercase;
}

.actions.result-actions {
  margin-top: 5px;
}

.actions.result-actions label {
  font-size: .875rem;
  line-height: 1;
  margin-bottom: .25em;
  margin-right: 5px;
  text-transform: uppercase;
  font-weight: 500;
}

td.action-column .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.2rem;
}

div.pager-top {
  display: none;
}

.pager-bottom {
  display: inline-block;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}

@media (max-width: 767.98px) {
  .pager-bottom {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-flow: row wrap !important;
            flex-flow: row wrap !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .pager-bottom .pages {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .pager-bottom .mp-controls {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}

.pages .page {
  padding: 0;
  width: 2rem;
  text-align: center;
  margin: 0 0.25rem;
  border-radius: 50%;
}

.pages .selected-page.page, .pages .selected-page.page:hover, .pages .selected-page.page:focus {
  color: #1A76B8;
  font-weight: 700;
  cursor: default;
  background-color: #fff;
  border-color: #1A76B8;
}

.pages .page,
.mp-controls .control {
  display: inline-block;
  line-height: 2.15;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  background-color: #1A76B8;
  border: 2px solid #1A76B8;
}

@media (max-width: 767.98px) {
  .pages .page,
  .mp-controls .control {
    margin-left: -1px;
  }
}

.pages .page:hover, .pages .page:focus,
.mp-controls .control:hover,
.mp-controls .control:focus {
  font-weight: 900;
  color: #1A76B8;
  background-color: #fff;
  border-color: #1A76B8;
}

.mp-controls .control {
  font-size: 0.8rem;
  padding: 0;
  color: #1A76B8;
  border: 0 none;
  background-color: transparent;
}

.mp-controls .control + .control {
  margin-left: 5px;
}

.mp-controls .control::before {
  padding-right: 4px;
  margin-top: -4px;
}

.mp-controls .control::after {
  padding-left: 4px;
  margin-top: -4px;
}

.mp-controls .control .cl {
  font-size: 0;
  width: 0;
  letter-spacing: 0;
}

.mp-controls .prev::before {
  content: "ï„„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.75rem;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mp-controls .first::before {
  content: "ï„€";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mp-controls .next::after {
  content: "ï„…";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.75rem;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mp-controls .last::after {
  content: "ï„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pager .controls {
  display: inline-block;
}

.pager .next-controls {
  margin-left: 5px;
}

.pager .prev-controls {
  margin-right: 5px;
}

.picture-editor .message {
  padding: 10px !important;
}

.picture-editor .drop-zone {
  margin: 25px 0 !important;
}

.picture-editor--modal.mode-crop {
  position: fixed !important;
  top: 5vh !important;
  max-height: 90vh !important;
  overflow-y: auto !important;
  border: none !important;
}

.picture-editor label.tbl {
  text-transform: capitalize;
}

.picture-editor .opt-aspect-ratio {
  display: none;
}

.picture-editor input.custom-file {
  display: none !important;
}

.picture-editor label.custom-file {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 8px 15px 7px;
  line-height: 1;
  text-align: center;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
  font-family: "Roboto", Arial, Verdana, sans-serif;
  border-radius: 2rem;
  font-weight: 900;
  letter-spacing: 2px;
  padding: 12px 24px;
  font-size: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .picture-editor label.custom-file {
    -webkit-transition: none;
    transition: none;
  }
}

.picture-editor label.custom-file:hover, .picture-editor label.custom-file:focus {
  text-decoration: none;
}

.picture-editor label.custom-file:focus, .picture-editor label.custom-file.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.picture-editor label.custom-file.disabled, .picture-editor label.custom-file:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.picture-editor label.custom-file:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.picture-editor label.custom-file:not(:disabled):not(.disabled):active, .picture-editor label.custom-file:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.picture-editor label.custom-file:not(:disabled):not(.disabled):active:focus, .picture-editor label.custom-file:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.picture-editor label.custom-file:hover {
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.picture-editor label.custom-file:active, .picture-editor label.custom-file.active,
.open > .picture-editor label.custom-file.dropdown-toggle {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.picture-editor label.custom-file:active:hover, .picture-editor label.custom-file:active:focus, .picture-editor label.custom-file:active.focus, .picture-editor label.custom-file.active:hover, .picture-editor label.custom-file.active:focus, .picture-editor label.custom-file.active.focus,
.open > .picture-editor label.custom-file.dropdown-toggle:hover,
.open > .picture-editor label.custom-file.dropdown-toggle:focus,
.open > .picture-editor label.custom-file.dropdown-toggle.focus {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.picture-editor label.custom-file.disabled:focus, .picture-editor label.custom-file.disabled.focus, .picture-editor label.custom-file:disabled:focus, .picture-editor label.custom-file:disabled.focus {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.picture-editor label.custom-file.disabled:hover, .picture-editor label.custom-file:disabled:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.picture-editor label.custom-file:hover {
  color: #fff !important;
}

.picture-editor .dimensions-instructions {
  font-style: italic;
  display: block;
  text-align: left;
}

.picture-editor-backdrop {
  position: fixed;
  z-index: 50;
  background-color: #4a4a4b;
  opacity: .9;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

/**
* For collapsible content
*/
/*
	Partial -	Messages
*/
.message-container {
  margin: 10px 0;
  clear: both;
}

.message-container::after {
  display: block;
  clear: both;
  content: "";
}

.message-container.empty {
  display: none;
}

.message-container .actionable-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.message-container .actionable-message .message-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.message-container .actionable-message .message-actions {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  margin: 0 !important;
  text-align: inherit !important;
}

.message-container .actionable-message .message-actions .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  font-size: .75rem;
}

.message-container .replacement-message > .message-content {
  display: inline !important;
}

div.message {
  background-color: #d9edf7;
  border: 1px solid #a6d5ec;
  color: #1A76B8;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

div.message:before {
  content: "ïš";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #1A76B8;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

div.message a {
  color: #1A76B8;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

div.message a:hover {
  text-decoration: none;
}

div.message:before {
  margin-right: 5px;
}

div.message span {
  margin-left: .5rem;
}

div.message + div.message {
  margin-top: 5px;
}

div.message.info {
  background-color: #d9edf7;
  border: 1px solid #a6d5ec;
  color: #1A76B8;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

div.message.info:before {
  content: "ïš";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #1A76B8;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

div.message.info a {
  color: #1A76B8;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

div.message.info a:hover {
  text-decoration: none;
}

div.message.info:before {
  margin-right: 5px;
}

div.message.success {
  background-color: #dff0d8;
  border: 1px solid #bbdfac;
  color: #6BD690;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

div.message.success:before {
  content: "ï˜";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #6BD690;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

div.message.success a {
  color: #6BD690;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

div.message.success a:hover {
  text-decoration: none;
}

div.message.important, div.message.warning {
  background-color: #fcf8e3;
  border: 1px solid #f6eaac;
  color: #FF7F51;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

div.message.important:before, div.message.warning:before {
  content: "ïª";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #FF7F51;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

div.message.important a, div.message.warning a {
  color: #FF7F51;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

div.message.important a:hover, div.message.warning a:hover {
  text-decoration: none;
}

div.message.error {
  background-color: #f2dede;
  border: 1px solid #e1b2b2;
  color: #EF5C5C;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

div.message.error:before {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #EF5C5C;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

div.message.error a {
  color: #EF5C5C;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

div.message.error a:hover {
  text-decoration: none;
}

div.message p:first-child {
  margin-top: 0;
}

div.message p:last-child {
  margin-bottom: 0;
}

div.message .miwt-collapsable-content {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
  overflow-y: auto;
  max-height: 500px;
  z-index: 10000;
}

div.has-error.profile-roles, span.has-error.profile-roles {
  margin-bottom: 1rem;
}

div.has-error.profile-roles .error-message, span.has-error.profile-roles .error-message {
  margin-top: .75rem;
}

div.has-error.profile-roles .pager, span.has-error.profile-roles .pager {
  margin-bottom: 0;
}

div.has-error .error-message, span.has-error .error-message {
  display: block;
  padding-left: 1.5rem;
  color: #EF5C5C;
  position: relative;
  font-size: .75rem;
  margin-top: 3px;
}

div.has-error .error-message::before, span.has-error .error-message::before {
  content: '\f071';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: inherit;
  font-style: normal;
  font-weight: 700;
  left: 7px;
  line-height: 1;
  position: absolute;
  text-decoration: inherit;
  text-rendering: optimizelegibility;
  text-transform: none;
  top: 3px;
  vertical-align: middle;
  color: #EF5C5C;
}

.prop-group.time .has-error {
  display: block;
  vertical-align: top;
}

.prop-group.time .has-error span.error-message {
  display: block;
}

.prop-group.time .has-error + .field-separator {
  margin: 0 .25rem;
}

.prop-group.time .has-error + .clock-period {
  margin-left: .35rem;
}

.component-editor .editor > .message-container:not(.empty) .message.error {
  background-color: #f2dede;
  border: 1px solid #e1b2b2;
  color: #EF5C5C;
  padding: 7px 7px 7px .75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}

.component-editor .editor > .message-container:not(.empty) .message.error:before {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  color: #EF5C5C;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

.component-editor .editor > .message-container:not(.empty) .message.error a {
  color: #EF5C5C;
  margin-left: 2px;
  font-family: Open Sans Bold;
}

.component-editor .editor > .message-container:not(.empty) .message.error a:hover {
  text-decoration: none;
}

.component-editor .editor > .message-container:not(.empty) .message.error:before {
  margin-right: 5px;
}

#activity-viewer-input {
  visibility: hidden;
  height: 1px;
  width: 1px;
  margin: 0;
}

#activity-viewer-input + .activities {
  display: none;
}

#activity-viewer-input:checked + .activities:not(:empty) {
  display: block;
  overflow: auto;
}

.activity-viewer {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 15px;
}

@media (max-width: 767px) {
  .activity-viewer {
    padding: 5px !important;
  }
}

.activity-viewer label {
  cursor: pointer;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .activity-viewer label {
    display: block;
    position: relative;
    width: 65px;
    max-width: 18vw;
    height: 40px;
    text-align: center;
  }
  .activity-viewer label .activity-viewer-label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    font-size: 0.625rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .activity-viewer label .activity-icon {
    padding-left: 0;
  }
}

.activity-viewer label .activity-icon {
  position: relative;
  padding-left: 5px;
  font-size: 1.5rem;
  line-height: 0.85rem;
  vertical-align: middle;
}

.activity-viewer .activities {
  padding: 0;
  list-style: none;
  width: 40ch;
  max-width: 100vw;
  border: 1px solid #eee;
  background-color: #fff;
  overflow: auto;
}

@media (max-width: 767px) {
  .activity-viewer .activities {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 50px;
    width: 100%;
    border: 1px solid #cfcfcf;
    border-bottom: 0;
    -webkit-box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
            box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
    max-height: 100vh;
    overflow: auto;
  }
}

@media (min-width: 768px) {
  .activity-viewer .activities {
    max-height: 500px;
    -webkit-box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
            box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
  }
}

@media (min-width: 768px) {
  .activity-viewer .activities {
    position: absolute;
    z-index: 2;
    top: calc(100% + 0.5rem);
    right: 0;
  }
  .activity-viewer .activities:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    -webkit-box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
            box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    bottom: calc(100% - 0.5rem);
    right: 5rem;
  }
}

.activity-viewer .activities > li {
  padding: 0.5ch 0;
  margin: 0 auto;
}

.activity-viewer .activities > li.no-data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #02C27E;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.activity-viewer .activities > li.bar {
  background-color: #dbe3e8;
  height: 10px;
}

.activity-viewer .activities .activity-item {
  overflow: hidden;
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background-color: #fff;
}

.activity-viewer .activities .activity-item + .activity-item {
  border-top: 1px solid #dbe3e8;
}

.activity-viewer .activities .activity-item .team-member-img {
  border-radius: 50%;
  background: #fff;
  height: 50px;
  width: 50px;
  overflow: hidden;
  position: relative;
  margin-right: 1rem;
}

.activity-viewer .activities .activity-item .team-member-btn {
  padding-left: 4rem;
  position: relative;
  height: 50px;
}

.activity-viewer .activities .activity-item .team-member-btn:before {
  background-color: rgb(var(--color-brand-main));
  border-radius: 100%;
  content: "";
  display: block;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.activity-viewer .activities .activity-item .team-member-btn:after {
  color: rgb(var(--color-brand-main-reverse));
  font-size: 1.125rem;
  line-height: 1;
  content: attr(data-team-member-initials);
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.activity-viewer .activities .activity-item .team-member-btn > span {
  display: block;
}

.activity-viewer .activities .activity-item .team-member-btn .new-msg {
  color: #cfcfcf;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: block;
}

.activity-viewer .activities .activity-item .team-member-btn .name {
  font-weight: normal;
  font-size: 1.25rem;
}

.activity-viewer .activities .activity-item .team-member-btn .view {
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  color: rgb(var(--color-brand-accent));
}

.activity-viewer .activities .activity-item .team-member-btn:hover .view {
  text-decoration: underline;
}

.activity-viewer .activities .activity-item .info {
  line-height: 1.25;
  -ms-flex-preferred-size: calc(100% - 66px);
      flex-basis: calc(100% - 66px);
}

.activity-viewer .activities .activity-item .info > div {
  margin: 2px 0;
}

.activity-viewer .activities .activity-item .info .action {
  font-size: 0.875rem;
  font-weight: normal;
}

.activity-viewer .activities .activity-item .info .action .module-link {
  text-decoration: underline;
  color: rgb(var(--color-brand-accent));
  cursor: pointer;
}

.activity-viewer .activities .activity-item .info .action-wrapper {
  width: calc(100% - 2rem);
  display: inline-block;
}

.activity-viewer .activities .activity-item .info .first-visit {
  background-image: url(/_design/engages-standard/images/icon-celebrate.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  vertical-align: top;
}

.activity-viewer .activities .activity-item .info .time {
  font-size: 0.725rem;
  font-style: italic;
  font-weight: 400;
  color: #E53140;
  display: block;
}

.notifications-pending .activity-icon::after {
  content: attr(data-activity-count);
  font-style: normal !important;
  font-size: 0.75rem;
  line-height: 1 !important;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  background-color: #02C27E;
  position: absolute;
  top: -5px;
  right: -10px;
  padding: 4px;
}

.account-team-members .team-members .team-member .team-member-img {
  border-radius: 50%;
  background: #fff;
  height: 50px;
  width: 50px;
  overflow: hidden;
  position: relative;
  margin-right: 1rem;
}

.engages__header {
  padding: 5px 15px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background: #fff;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
}

@media (max-width: 1023px) {
  .engages__header {
    z-index: 9999;
  }
}

.engages__header--modal-open:after {
  content: '';
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: 900;
}

.engages__header__inner {
  max-width: 1400px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1023px) {
  .engages__header__inner {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1400px) {
  .engages__header__inner {
    width: 1400px;
  }
}

.engages__header .logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 1024px) {
  .engages__header .logo-container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .engages__header .logo-container .account-logo {
    display: none;
  }
}

.engages__header__logo {
  text-align: center;
  width: 100%;
}

@media (min-width: 1024px) {
  .engages__header__logo {
    width: unset;
  }
}

.engages__header__logo__img:not(.account-logo) {
  height: 50px;
}

.engages__header__logo__img.account-logo {
  height: 30px;
}

@media (min-width: 1024px) {
  .engages__header__logo__img:not(.primary-logo) {
    display: none;
  }
}

.engages__header__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

@media (max-width: 1023px) {
  .engages__header__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0 0;
    height: 65px;
    background-color: #f2f2f2;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  }
}

@media (min-width: 1280px) {
  .engages__header__menu {
    margin: 0;
  }
}

.engages__header__menu__item-invite-others, .engages__header__menu__item-messages {
  display: block;
}

@media (min-width: 768px) {
  .engages__header__menu__item-invite-others, .engages__header__menu__item-messages {
    padding: 10px 0;
  }
}

@media (min-width: 1280px) {
  .engages__header__menu__item-invite-others, .engages__header__menu__item-messages {
    padding: 0;
  }
}

.engages__header__menu__item-invite-others__label {
  font-size: 0.625rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .engages__header__menu__item-invite-others__label {
    display: block;
    position: relative;
    width: 65px;
    height: 40px;
    line-height: 0.7;
    text-align: center;
  }
  .engages__header__menu__item-invite-others__label:before {
    content: "ïˆ´";
    font-family: "Font Awesome 5 Pro";
    display: block;
    vertical-align: middle;
    font-size: 1.4rem;
    margin-left: 5px;
    margin-bottom: 16px;
    color: #cfcfcf;
  }
}

@media (min-width: 768px) {
  .engages__header__menu__item-invite-others__label {
    font-size: 0.8125rem;
    letter-spacing: 2px;
  }
  .engages__header__menu__item-invite-others__label:after {
    content: "ïˆ´";
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #cfcfcf;
  }
}

.engages__header__menu__item-messages, .engages__header__menu__item .module-conversations {
  padding: 15px;
}

@media (min-width: 1024px) {
  .engages__header__menu__item-messages, .engages__header__menu__item .module-conversations {
    display: none;
  }
}

@media (max-width: 1023px) {
  .engages__header__menu__item-messages, .engages__header__menu__item .module-conversations {
    padding: 5px;
  }
}

.engages__header__menu__item-messages > label, .engages__header__menu__item .module-conversations > label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  font-size: 0.625rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
  cursor: pointer;
}

@media (min-width: 768px) {
  .engages__header__menu__item-messages > label, .engages__header__menu__item .module-conversations > label {
    font-size: 0.8125rem;
  }
}

@media (min-width: 1024px) {
  .engages__header__menu__item-messages > label, .engages__header__menu__item .module-conversations > label {
    display: inline-block;
  }
}

.engages__header__menu__item-messages > label .conversation-icon, .engages__header__menu__item .module-conversations > label .conversation-icon {
  font-size: 1.5rem;
  color: #cfcfcf;
  vertical-align: middle;
  text-align: center;
}

.engages__header__menu__item-messages > label .conversation-icon:after, .engages__header__menu__item .module-conversations > label .conversation-icon:after {
  content: "ïµ";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.5rem;
  color: #cfcfcf;
  font-style: normal;
}

.engages__header__menu__item-messages > label.active, .engages__header__menu__item .module-conversations > label.active {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-messages > label.active .conversation-icon:after, .engages__header__menu__item .module-conversations > label.active .conversation-icon:after {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-messages #conversation-input, .engages__header__menu__item .module-conversations #conversation-input {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.engages__header__menu__item-workspace {
  padding: 15px;
}

@media (min-width: 1024px) {
  .engages__header__menu__item-workspace {
    display: none;
  }
}

@media (max-width: 1023px) {
  .engages__header__menu__item-workspace {
    padding: 5px;
  }
}

.engages__header__menu__item-workspace > label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  font-size: 0.625rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
  cursor: pointer;
}

@media (min-width: 768px) {
  .engages__header__menu__item-workspace > label {
    font-size: 0.8125rem;
  }
}

@media (min-width: 1024px) {
  .engages__header__menu__item-workspace > label {
    display: inline-block;
  }
}

.engages__header__menu__item-workspace > label .workspace-icon {
  font-size: 1.5rem;
  color: #cfcfcf;
  vertical-align: middle;
  text-align: center;
}

.engages__header__menu__item-workspace > label .workspace-icon:after {
  content: "ï€•";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.5rem;
  color: #cfcfcf;
  font-style: normal;
}

.engages__header__menu__item-workspace > label.active {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-workspace > label.active .workspace-icon:after {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-workspace #workspace-input {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.engages__header__menu__item-team, .engages__header__menu__item .account-team-members {
  padding: 15px;
}

@media (min-width: 1024px) {
  .engages__header__menu__item-team, .engages__header__menu__item .account-team-members {
    display: none;
  }
}

@media (max-width: 1023px) {
  .engages__header__menu__item-team, .engages__header__menu__item .account-team-members {
    padding: 5px;
    text-align: center;
  }
}

.engages__header__menu__item-team > label, .engages__header__menu__item .account-team-members > label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  font-size: 0.625rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
  cursor: pointer;
}

@media (min-width: 768px) {
  .engages__header__menu__item-team > label, .engages__header__menu__item .account-team-members > label {
    font-size: 0.8125rem;
  }
}

@media (min-width: 1024px) {
  .engages__header__menu__item-team > label, .engages__header__menu__item .account-team-members > label {
    display: inline-block;
  }
}

.engages__header__menu__item-team > label .team-icon, .engages__header__menu__item .account-team-members > label .team-icon {
  font-size: 1.5rem;
  color: #cfcfcf;
  vertical-align: middle;
}

.engages__header__menu__item-team > label .team-icon:after, .engages__header__menu__item .account-team-members > label .team-icon:after {
  content: "ïƒ€";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.5rem;
  color: #cfcfcf;
  font-style: normal;
}

.engages__header__menu__item-team > label.active, .engages__header__menu__item .account-team-members > label.active {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-team > label.active .team-icon:after, .engages__header__menu__item .account-team-members > label.active .team-icon:after {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-team #team-members-input-input, .engages__header__menu__item .account-team-members #team-members-input-input {
  position: absolute;
  margin: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.engages__header__menu__item-activities, .engages__header__menu__item .activity-viewer {
  padding: 5px 0 5px 15px;
}

@media (max-width: 1023px) {
  .engages__header__menu__item-activities, .engages__header__menu__item .activity-viewer {
    padding: 5px !important;
  }
  .engages__header__menu__item-activities > label, .engages__header__menu__item .activity-viewer > label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    font-size: 0.625rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(var(--color-brand-main));
    cursor: pointer;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .engages__header__menu__item-activities > label, .engages__header__menu__item .activity-viewer > label {
    font-size: 0.8125rem;
  }
}

@media (max-width: 1023px) {
  .engages__header__menu__item-activities > label .activity-viewer-label, .engages__header__menu__item .activity-viewer > label .activity-viewer-label {
    position: relative !important;
  }
  .engages__header__menu__item-activities > label .activity-icon, .engages__header__menu__item .activity-viewer > label .activity-icon {
    line-height: 1.2;
    text-align: center;
  }
}

.engages__header__menu__item-activities > label, .engages__header__menu__item .activity-viewer > label {
  font-size: 0.625rem;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
}

@media (min-width: 480px) {
  .engages__header__menu__item-activities > label, .engages__header__menu__item .activity-viewer > label {
    font-size: 0.8125rem;
    letter-spacing: 2px;
  }
}

.engages__header__menu__item-activities > label .activity-icon, .engages__header__menu__item .activity-viewer > label .activity-icon {
  font-size: 1.5rem;
  color: #cfcfcf;
  vertical-align: middle;
}

.engages__header__menu__item-activities > label .activity-icon:before, .engages__header__menu__item .activity-viewer > label .activity-icon:before {
  content: "ïˆ";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.5rem;
  color: #cfcfcf;
  font-style: normal;
}

.engages__header__menu__item-activities > label.active, .engages__header__menu__item .activity-viewer > label.active {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__item-activities > label.active .activities-icon:after, .engages__header__menu__item .activity-viewer > label.active .activities-icon:after {
  color: rgb(var(--color-brand-main-dark));
}

.engages__header__menu__link {
  display: block;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(var(--color-brand-main));
  font-weight: 600;
  padding: 5px 10px;
  font-size: .8125rem;
}

@media (min-width: 1024px) {
  .engages__header__menu__link {
    padding: 15px;
  }
}

@media (max-width: 1023px) {
  .calendly-badge-widget {
    bottom: 78px !important;
  }
}

#module-conversation-input {
  visibility: hidden;
}

#module-conversation-input + .module-unread-counts {
  display: none;
}

#module-conversation-input:checked + .module-unread-counts:not(:empty) {
  display: block;
}

.module-conversations {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 15px;
}

@media (max-width: 767px) {
  .module-conversations {
    padding: 5px !important;
  }
}

.module-conversations label {
  cursor: pointer;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .module-conversations label {
    display: block;
    position: relative;
    width: 65px;
    max-width: 18vw;
    height: 40px;
    text-align: center;
  }
  .module-conversations label .conversation-label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    font-size: 0.625rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .module-conversations label .conversation-icon {
    padding-left: 0;
  }
}

.module-conversations label .conversation-icon {
  position: relative;
  padding-left: 5px;
  font-size: 1.5rem;
  line-height: 0.85rem;
  vertical-align: middle;
}

.module-conversations label .conversation-icon:before {
  content: "ïµ";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  vertical-align: top;
}

.module-conversations .module-unread-counts {
  padding: 0;
  list-style: none;
  width: 35ch;
  max-width: 100vw;
  border: 1px solid #eee;
  background-color: white;
  -webkit-box-shadow: 0px -15px 15px rgba(219, 227, 232, 0.98);
          box-shadow: 0px -15px 15px rgba(219, 227, 232, 0.98);
}

@media (max-width: 767px) {
  .module-conversations .module-unread-counts {
    position: fixed;
    top: auto;
    right: 15px;
    left: 15px;
    bottom: 74px;
    width: calc(100% - 30px);
    border: 1px solid #dbe3e8;
    border-bottom: 0;
    -webkit-box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
            box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
    max-height: calc(70% - 70px);
    overflow: auto;
  }
}

@media (min-width: 768px) {
  .module-conversations .module-unread-counts {
    position: absolute;
    z-index: 2;
    top: calc(100% + 0.5rem);
    right: 0;
  }
  .module-conversations .module-unread-counts:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    -webkit-box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
            box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    bottom: calc(100% - 0.5rem);
    right: 5rem;
  }
}

.module-conversations .module-unread-counts button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a4a4b;
  text-align: left;
  background-color: transparent;
  padding: 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.module-conversations .module-unread-counts > li {
  padding: 0.5ch 0;
  margin: 0 auto;
}

.module-conversations .module-unread-counts > li.no-data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #02C27E;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.module-conversations .module-unread-counts .module-unread {
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.module-conversations .module-unread-counts .module-unread + .module-unread {
  border-top: 1px solid #dbe3e8;
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn {
  padding-left: 4rem;
  position: relative;
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn:before {
  background-color: rgb(var(--color-brand-main));
  border-radius: 100%;
  content: "";
  display: block;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn:after {
  color: rgb(var(--color-brand-main-reverse));
  font-size: 1.125rem;
  line-height: 1;
  content: attr(data-message-count);
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn > span {
  display: block;
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn .new-msg {
  color: #cfcfcf;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: block;
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn .name {
  font-weight: normal;
  font-size: 1.25rem;
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn .view {
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  color: rgb(var(--color-brand-accent));
}

.module-conversations .module-unread-counts .module-unread .module-unread-btn:hover .view {
  text-decoration: underline;
}

.unread-messages .conversation-icon::after {
  content: attr(data-message-count);
  font-style: normal !important;
  line-height: 1 !important;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  background-color: #02C27E;
  position: absolute;
  top: -5px;
  right: -10px;
}

.show-messages:hover {
  background-color: rgba(74, 74, 75, 0.2);
  border-color: rgba(74, 74, 75, 0.2);
}

#team-members-input {
  visibility: hidden;
  height: 1px;
  width: 1px;
  margin: 0;
}

#team-members-input + .team-members {
  display: block;
}

@media (min-width: 1024px) {
  #team-members-input + .team-members {
    max-width: 280px;
  }
}

@media (min-width: 1280px) {
  #team-members-input + .team-members {
    max-width: 300px;
  }
}

.account-team-members {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 767px) {
  .account-team-members {
    padding: 5px !important;
    margin-bottom: 50px;
    display: none;
    max-width: 300px;
  }
}

.account-team-members label {
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 5px;
}

@media (max-width: 767px) {
  .account-team-members label {
    display: block;
    position: relative;
    width: 65px;
    max-width: 18vw;
    height: 40px;
    text-align: center;
  }
  .account-team-members label .team-label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    font-size: 0.9rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .account-team-members label .team-icon {
    padding-left: 0;
  }
}

.account-team-members label .team-icon {
  position: relative;
  padding-left: 5px;
  font-size: 1.5rem;
  line-height: 0.85rem;
  vertical-align: middle;
}

.account-team-members .team-members {
  padding: 0;
  list-style: none;
  max-width: 100vw;
  border: 1px solid #eee;
  background-color: #fff;
}

@media (min-width: 768px) {
  .account-team-members .team-members {
    -webkit-box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
            box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
  }
}

@media (min-width: 768px) {
  .account-team-members .team-members {
    z-index: 2;
  }
}

.account-team-members .team-members button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a4a4b;
  text-align: left;
  background-color: transparent;
  padding: 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.account-team-members .team-members > li {
  padding: 0.5ch 0;
  margin: 0 auto;
}

.account-team-members .team-members > li.no-data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #02C27E;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.account-team-members .team-members > li.bar {
  background-color: #dbe3e8;
  height: 10px;
}

.account-team-members .team-members .team-member {
  overflow: hidden;
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background-color: #fff;
}

.account-team-members .team-members .team-member + .team-member {
  border-top: 1px solid #dbe3e8;
}

.account-team-members .team-members .team-member .team-member-img {
  border-radius: 50%;
  background: #fff;
  height: 50px;
  width: 50px;
  overflow: hidden;
  position: relative;
  margin-right: 1rem;
}

.account-team-members .team-members .team-member .team-member-btn {
  padding-left: 4rem;
  position: relative;
  height: 50px;
}

.account-team-members .team-members .team-member .team-member-btn:before {
  background-color: rgb(var(--color-brand-main));
  border-radius: 100%;
  content: "";
  display: block;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.account-team-members .team-members .team-member .team-member-btn:after {
  color: rgb(var(--color-brand-main-reverse));
  font-size: 1.125rem;
  line-height: 1;
  content: attr(data-team-member-initials);
  position: absolute;
  top: 50%;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.account-team-members .team-members .team-member .team-member-btn > span {
  display: block;
}

.account-team-members .team-members .team-member .team-member-btn .new-msg {
  color: #cfcfcf;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: block;
}

.account-team-members .team-members .team-member .team-member-btn .name {
  font-weight: normal;
  font-size: 1.25rem;
}

.account-team-members .team-members .team-member .team-member-btn .view {
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  color: rgb(var(--color-brand-accent));
}

.account-team-members .team-members .team-member .team-member-btn:hover .view {
  text-decoration: underline;
}

.account-team-members .team-members .team-member .info {
  line-height: 1.25;
  -ms-flex-preferred-size: calc(100% - 66px);
      flex-basis: calc(100% - 66px);
}

.account-team-members .team-members .team-member .info > div {
  margin: 2px 0;
}

.account-team-members .team-members .team-member .info .name {
  font-size: 0.875rem;
  font-weight: normal;
}

.account-team-members .team-members .team-member .info .contact {
  font-size: 0.75rem;
  font-weight: 400;
}

.account-team-members .team-members .team-member .info .contact .email > a, .account-team-members .team-members .team-member .info .contact .phone > a {
  color: rgb(var(--color-brand-main));
  text-decoration: none;
}

.account-team-members .team-members .team-member .info .stats {
  font-size: 0.725rem;
  font-style: italic;
  font-weight: 400;
  color: #E53140;
}

.account-team-members .team-members .team-member .info .stats span {
  display: block;
}

.account-team-members .team-members .team-member .team-member-img {
  border-radius: 50%;
  background: #fff;
  height: 50px;
  width: 50px;
  overflow: hidden;
  position: relative;
  margin-right: 1rem;
}

.engages__hero {
  margin-top: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.engages__hero__wrapper {
  height: 260px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 1400px;
  background-image: var(--hero-bg-src);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

@supports (background-blend-mode: multiply) {
  .engages__hero__wrapper {
    background-color: rgb(var(--color-brand-main));
    background-blend-mode: multiply;
  }
}

@media (min-width: 768px) {
  .engages__hero__wrapper {
    height: 120px;
  }
}

@media (min-width: 1400px) {
  .engages__hero__wrapper {
    width: 1400px;
  }
}

@media screen and (-ms-high-contrast: none) {
  .engages__hero__wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-brand-main), .8);
  }
}

@supports (-ms-ime-align: auto) {
  .engages__hero__wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-brand-main), .8);
  }
}

.engages__hero__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.engages__hero__content__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 200px;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .engages__hero__content__container {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 120px;
    margin-top: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 768px) {
  .engages__hero__content__link {
    margin: auto 25px;
  }
}

.engages__hero__content__link a.link {
  display: inline-block;
  padding: 0.4rem 1.5rem;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  background-color: rgb(var(--color-brand-main-dark));
  color: #fff;
}

.engages__hero__content__link a.link:hover, .engages__hero__content__link a.link:focus {
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-main-dark));
          box-shadow: 0 0 5px rgb(var(--color-brand-main-dark));
}

@media (min-width: 768px) {
  .engages__hero__content__link a.link {
    margin: 1rem 0;
    border-radius: 2rem;
    font-size: 0.8125rem;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    background-color: #fff;
    color: rgb(var(--color-brand-main));
  }
  .engages__hero__content__link a.link:hover, .engages__hero__content__link a.link:focus {
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px #fff;
            box-shadow: 0 0 5px #fff;
  }
}

.engages__hero__content__link a.link span::after {
  content: "ïˆ´";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  margin-left: 5px;
  margin-bottom: 5px;
  color: #fff;
}

@media (min-width: 768px) {
  .engages__hero__content__link a.link span::after {
    color: rgb(var(--color-brand-main));
  }
}

.engages__hero__content__link a.link.disabled {
  cursor: default;
  pointer-events: none;
  background-color: #f3f3f3;
}

.engages__hero__content__link a.link.disabled span {
  color: #dbe3e8;
}

.engages__hero__content__link a.link.disabled span::after {
  color: #dbe3e8;
}

.engages__hero__content__greeting {
  color: rgb(var(--color-brand-main-reverse));
  margin: 20px;
}

@media (min-width: 768px) {
  .engages__hero__content__greeting {
    margin: auto 25px;
  }
}

.engages__hero__content__greeting__heading {
  margin: 0;
  font-size: 2.5rem;
}

.engages__hero__content__greeting__message {
  font-weight: 700;
  font-size: 1.25rem;
}

.engages__hero__content__account__name, .engages__hero__content__account__logo {
  color: rgb(var(--color-brand-accent));
  font-weight: 600;
  text-align: center;
  width: 100%;
  background-color: #fff;
}

.engages__hero__content__account__logo {
  height: 120px;
  width: 100%;
  -webkit-box-shadow: 0 0 20px #dbe3e8;
          box-shadow: 0 0 20px #dbe3e8;
  display: none;
}

@media (min-width: 768px) {
  .engages__hero__content__account__logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    display: block;
  }
}

@media (min-width: 1280px) {
  .engages__hero__content__account__logo {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 330px;
            flex: 0 0 330px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.engages__hero__content__account__logo__img {
  padding: 20px;
}

.engages__hero__content__account__logo__img:not(.large-img) {
  max-height: 70px;
}

.engages__hero__content__account__name {
  padding: 2.5rem;
  font-size: 0.875rem;
  font-weight: 900;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  height: 260px;
  -webkit-box-shadow: 0 0 20px #dbe3e8;
          box-shadow: 0 0 20px #dbe3e8;
  margin-top: 60px;
  display: none;
}

@media (min-width: 768px) {
  .engages__hero__content__account__name {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    display: block;
    height: 120px;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .engages__hero__content__account__name {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 330px;
            flex: 0 0 330px;
  }
}

@media (min-width: 1024px) {
  .engages-main-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
  }
}

.engages__aside {
  display: none;
}

@media (min-width: 1024px) {
  .engages__aside {
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
    margin-right: 30px;
    margin-top: 25px;
  }
}

@media (min-width: 1024px) {
  .engages__aside__wrapper {
    max-width: 300px;
  }
}

.engages__aside-fixed .engages__aside__wrapper {
  position: fixed;
  top: 85px;
  margin-top: 0;
  padding-top: 0;
}

.engages__aside__rep, .engages__aside__primary {
  text-align: center;
  margin: 0;
  padding: 1.25rem;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 20px #dbe3e8;
          box-shadow: 0 0 20px #dbe3e8;
}

.engages__aside__rep__photo, .engages__aside__primary__photo {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}

.engages__aside__rep__nophoto, .engages__aside__primary__nophoto {
  position: relative;
}

.engages__aside__rep__nophoto:before, .engages__aside__primary__nophoto:before {
  content: "";
  background-color: rgb(var(--color-brand-main));
  border-radius: 100%;
  display: block;
  height: 80px;
  width: 80px;
  margin: auto;
}

.engages__aside__rep__nophoto:after, .engages__aside__primary__nophoto:after {
  color: #fff;
  font-size: 2.3rem;
  line-height: 1;
  content: attr(data-team-member-initials);
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 2.3rem;
}

.engages__aside__rep__details, .engages__aside__primary__details {
  color: #4a4a4b;
  margin-top: 1rem;
}

.engages__aside__rep__details__name, .engages__aside__primary__details__name {
  font-size: 1.4375rem;
  font-weight: 400;
}

.engages__aside__rep__details__title, .engages__aside__primary__details__title {
  font-weight: 900;
  font-size: 0.8125rem;
  letter-spacing: 1.5px;
  color: #b6b6b6;
  text-transform: uppercase;
  margin-top: .5rem;
}

.engages__aside__rep__contact, .engages__aside__primary__contact {
  text-align: center;
  font-size: 0.9375rem;
  color: #b6b6b6;
  margin-top: .5rem;
}

.engages__aside__rep__contact__field, .engages__aside__primary__contact__field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.engages__aside__rep__contact__field a, .engages__aside__primary__contact__field a {
  color: rgb(var(--color-brand-main));
  text-decoration: none;
}

.engages__aside__rep__contact__field + .engages__aside__rep__contact__field, .engages__aside__primary__contact__field + .engages__aside__rep__contact__field, .engages__aside__rep__contact__field + .engages__aside__primary__contact__field, .engages__aside__primary__contact__field + .engages__aside__primary__contact__field {
  margin-top: 5px;
}

.engages__aside__rep {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
}

.engages__aside__rep__photo {
  overflow: initial;
  margin-right: 0.5rem;
  height: 75px;
  width: 75px;
}

.engages__aside__rep__nophoto {
  position: relative;
}

.engages__aside__rep__nophoto:before {
  height: 70px;
  width: 70px;
  margin-right: 0.8rem;
}

.engages__aside__rep__nophoto:after {
  font-size: 1.8rem;
  top: 2.1rem;
  left: 2.2rem;
}

.engages__aside__rep__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  -ms-flex-preferred-size: calc(100% - 80px);
      flex-basis: calc(100% - 80px);
}

.engages__aside__rep__details {
  margin-top: 0;
}

.engages__aside__rep__details__name {
  font-size: 1.2rem;
}

@media (min-width: 1024px) {
  .engages__aside__info {
    margin: 12px 0;
  }
}

.engages__aside__info__wrapper {
  color: #fff;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-brand-main), .97)), to(rgba(var(--color-brand-main), .97))), var(--aside-info-bg-src, rgb(var(--color-brand-main)));
  background: linear-gradient(0deg, rgba(var(--color-brand-main), .97), rgba(var(--color-brand-main), .97)), var(--aside-info-bg-src, rgb(var(--color-brand-main)));
  background-size: contain;
  background-repeat: no-repeat;
  padding: 2rem 2rem 2.5rem;
}

.engages__aside__info__logo {
  margin-bottom: 1rem;
}

.engages__aside__info__header {
  text-transform: uppercase;
  font-size: 1.0625rem;
  font-weight: 900;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.engages__aside__info__body {
  font-size: 0.9375rem;
  letter-spacing: 1px;
}

.engages__aside__info__footer {
  margin-top: 2rem;
}

.engages__aside__info__learn-more {
  text-decoration: none;
  padding: .75rem 1.5rem;
  border-radius: 20px;
  background-color: #fff;
  font-size: 0.8125rem;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.engages__aside__primary__nophoto {
  height: 80px;
  width: 80px;
  overflow: hidden;
  margin: 0 auto;
}

.engages__aside__primary__nophoto:after {
  display: block;
  height: 2.3rem;
  width: 80px;
  top: 1.15rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  vertical-align: baseline;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

#footer-copyright {
  margin: 0 40px;
}

@media (min-width: 1280px) {
  #footer-copyright {
    max-width: 1280px;
    margin: 0 auto;
  }
}

#access-lost {
  margin: 0 40px;
}

@media (min-width: 1280px) {
  #access-lost {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.module {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.module .module-content-area.CA5 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA5 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 5%;
            flex: 1 0 5%;
  }
}

.module .module-content-area.CA10 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA10 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 10%;
            flex: 1 0 10%;
  }
}

.module .module-content-area.CA15 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA15 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 15%;
            flex: 1 0 15%;
  }
}

.module .module-content-area.CA20 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA20 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 20%;
            flex: 1 0 20%;
  }
}

.module .module-content-area.CA25 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA25 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
  }
}

.module .module-content-area.CA30 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA30 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 30%;
            flex: 1 0 30%;
  }
}

.module .module-content-area.CA35 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA35 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 35%;
            flex: 1 0 35%;
  }
}

.module .module-content-area.CA40 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA40 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 40%;
            flex: 1 0 40%;
  }
}

.module .module-content-area.CA45 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA45 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 45%;
            flex: 1 0 45%;
  }
}

.module .module-content-area.CA50 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA50 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
  }
}

.module .module-content-area.CA55 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA55 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 55%;
            flex: 1 0 55%;
  }
}

.module .module-content-area.CA60 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA60 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 60%;
            flex: 1 0 60%;
  }
}

.module .module-content-area.CA65 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA65 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 65%;
            flex: 1 0 65%;
  }
}

.module .module-content-area.CA70 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA70 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 70%;
            flex: 1 0 70%;
  }
}

.module .module-content-area.CA75 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA75 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 75%;
            flex: 1 0 75%;
  }
}

.module .module-content-area.CA80 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA80 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 80%;
            flex: 1 0 80%;
  }
}

.module .module-content-area.CA85 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA85 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 85%;
            flex: 1 0 85%;
  }
}

.module .module-content-area.CA90 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA90 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 90%;
            flex: 1 0 90%;
  }
}

.module .module-content-area.CA95 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA95 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 95%;
            flex: 1 0 95%;
  }
}

.module .module-content-area.CA100 {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.CA100 {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.module .module-content-area > .component-wrapper {
  padding: 0 20px 20px;
}

.module .module-content-area > .component-wrapper:first-child {
  padding-top: 20px;
}

@media (min-width: 1280px) {
  .module .module-content-area > .component-wrapper {
    padding: 0 30px 30px;
  }
  .module .module-content-area > .component-wrapper:first-child {
    padding-top: 30px;
  }
}

.module .module-content-area.H {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.module .module-content-area.H5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H5 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H5 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 5%;
            flex: 1 0 5%;
  }
}

.module .module-content-area.H10 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H10 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H10 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 10%;
            flex: 1 0 10%;
  }
}

.module .module-content-area.H15 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H15 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H15 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 15%;
            flex: 1 0 15%;
  }
}

.module .module-content-area.H20 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H20 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H20 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 20%;
            flex: 1 0 20%;
  }
}

.module .module-content-area.H25 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H25 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H25 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
  }
}

.module .module-content-area.H30 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H30 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H30 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 30%;
            flex: 1 0 30%;
  }
}

.module .module-content-area.H35 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H35 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H35 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 35%;
            flex: 1 0 35%;
  }
}

.module .module-content-area.H40 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H40 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H40 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 40%;
            flex: 1 0 40%;
  }
}

.module .module-content-area.H45 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H45 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H45 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 45%;
            flex: 1 0 45%;
  }
}

.module .module-content-area.H50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H50 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H50 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
  }
}

.module .module-content-area.H55 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H55 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H55 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 55%;
            flex: 1 0 55%;
  }
}

.module .module-content-area.H60 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H60 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H60 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 60%;
            flex: 1 0 60%;
  }
}

.module .module-content-area.H65 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H65 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H65 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 65%;
            flex: 1 0 65%;
  }
}

.module .module-content-area.H70 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H70 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H70 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 70%;
            flex: 1 0 70%;
  }
}

.module .module-content-area.H75 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H75 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H75 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 75%;
            flex: 1 0 75%;
  }
}

.module .module-content-area.H80 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H80 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H80 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 80%;
            flex: 1 0 80%;
  }
}

.module .module-content-area.H85 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H85 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H85 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 85%;
            flex: 1 0 85%;
  }
}

.module .module-content-area.H90 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H90 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H90 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 90%;
            flex: 1 0 90%;
  }
}

.module .module-content-area.H95 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H95 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H95 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 95%;
            flex: 1 0 95%;
  }
}

.module .module-content-area.H100 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module .module-content-area.H100 > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

@media (min-width: 768px) {
  .module .module-content-area.H100 > .component-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
  }
}

.module .module-content-area.V {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.module .module-content-area.V > .component-wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.module .module-content-area.V5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 5vh;
}

.module .module-content-area.V10 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 10vh;
}

.module .module-content-area.V15 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 15vh;
}

.module .module-content-area.V20 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 20vh;
}

.module .module-content-area.V25 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 25vh;
}

.module .module-content-area.V30 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 30vh;
}

.module .module-content-area.V35 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 35vh;
}

.module .module-content-area.V40 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 40vh;
}

.module .module-content-area.V45 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 45vh;
}

.module .module-content-area.V50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 50vh;
}

.module .module-content-area.V55 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 55vh;
}

.module .module-content-area.V60 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 60vh;
}

.module .module-content-area.V65 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 65vh;
}

.module .module-content-area.V70 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 70vh;
}

.module .module-content-area.V75 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 75vh;
}

.module .module-content-area.V80 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 80vh;
}

.module .module-content-area.V85 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 85vh;
}

.module .module-content-area.V90 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 90vh;
}

.module .module-content-area.V95 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 95vh;
}

.module .module-content-area.V100 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  max-height: 100vh;
}

.module p {
  margin: .67em 0;
}

.module p:first-child {
  margin-top: 0;
}

.module p:last-child {
  margin-bottom: 0;
}

.module-wrapper {
  margin-top: 30px;
  border: 1px solid #dbe3e8;
  -webkit-box-shadow: 0px 0px 20px #dbe3e8;
          box-shadow: 0px 0px 20px #dbe3e8;
}

.module-wrapper div.module-name {
  margin-left: 20px;
}

@media (min-width: 1280px) {
  .module-wrapper div.module-name {
    margin-left: 30px;
  }
}

.module-wrapper .module-name-editor input.module-name,
.module-wrapper h1 .module-name {
  display: inline-block;
  margin-right: 20px;
  color: rgb(var(--color-brand-main-dark));
  font-size: 1.5rem;
  font-weight: lighter;
}

@media (min-width: 1280px) {
  .module-wrapper .module-name-editor input.module-name,
  .module-wrapper h1 .module-name {
    font-size: 2.25rem;
    margin-right: 30px;
  }
}

.module-wrapper h1 .module-shown-date {
  color: #4a4a4b;
  font-size: 0.75rem;
  font-style: italic;
}

@media (min-width: 1280px) {
  .module-wrapper h1 .module-shown-date {
    font-size: 0.9375rem;
  }
}

.module-wrapper .module-name-editor h1 {
  margin: 0;
}

.module-wrapper .module-name-editor h1 .module-name,
.module-wrapper .module-name-editor h1 .module-shown-date {
  margin: 0;
}

@media (min-width: 1280px) {
  .module-wrapper .module-name-editor h1 .module-name,
  .module-wrapper .module-name-editor h1 .module-shown-date {
    margin: 0;
  }
}

.module-wrapper .module-name-editor h1 .module-name {
  margin-right: 1ch;
}

.module-wrapper .module-name-editor .prop.module-name > .module-name-actions {
  min-width: 85px;
}

.module-wrapper:last-child {
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .module-wrapper:last-child {
    margin-bottom: 60px;
  }
}

.module-wrapper:not(.managing) .module-name-actions,
.module-wrapper:not(.managing) .component-editor-actions {
  display: none;
}

.module-wrapper.managing .component-editor .inlinewindowcontent .persistence-actions {
  display: inline-block;
}

.module-actions {
  -ms-flex-item-align: center;
      align-self: center;
  padding-right: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
}

.module-actions button:disabled:before {
  color: rgba(74, 74, 75, 0.2);
}

.module-actions .btn + .btn {
  margin-left: 12px;
}

.module-actions .btn:last-child {
  margin-right: 2px;
}

.module-actions .manage {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .manage:before {
  content: "ï€“";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 700;
}

.module-actions .manage:disabled {
  cursor: not-allowed;
}

.module-actions .manage:not([disabled]) {
  cursor: pointer;
}

.module-actions .manage:not([disabled]):hover {
  background-color: rgba(74, 74, 75, 0.2);
  border-color: rgba(74, 74, 75, 0.2);
}

.module-actions .done {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .done:before {
  content: "ï€“";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 700;
}

.module-actions .done:disabled {
  cursor: not-allowed;
}

.module-actions .done:not([disabled]) {
  cursor: pointer;
}

.module-actions .done:not([disabled]):hover {
  background-color: rgba(74, 74, 75, 0.2);
  border-color: rgba(74, 74, 75, 0.2);
}

.module-actions .done:after {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #E53140;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.module-actions .delete {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .delete:before {
  content: "ï‹­";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #E53140;
  font-weight: 700;
}

.module-actions .delete:disabled {
  cursor: not-allowed;
}

.module-actions .delete:not([disabled]) {
  cursor: pointer;
}

.module-actions .delete:not([disabled]):hover {
  background-color: rgba(229, 49, 64, 0.2) !important;
  border-color: rgba(229, 49, 64, 0.2) !important;
}

.module-actions .show {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .show:before {
  content: "ï°";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #F27E30;
  font-weight: 700;
}

.module-actions .show:disabled {
  cursor: not-allowed;
}

.module-actions .show:not([disabled]) {
  cursor: pointer;
}

.module-actions .show:not([disabled]):hover {
  background-color: rgba(242, 126, 48, 0.2);
  border-color: rgba(242, 126, 48, 0.2);
}

.module-actions .hide {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .hide:before {
  content: "ï®";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-actions .hide:disabled {
  cursor: not-allowed;
}

.module-actions .hide:not([disabled]) {
  cursor: pointer;
}

.module-actions .hide:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .publish {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .publish:before {
  content: "ï„µ";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-actions .publish:disabled {
  cursor: not-allowed;
}

.module-actions .publish:not([disabled]) {
  cursor: pointer;
}

.module-actions .publish:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .send {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .send:before {
  content: "ï‡˜";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-actions .send:disabled {
  cursor: not-allowed;
}

.module-actions .send:not([disabled]) {
  cursor: pointer;
}

.module-actions .send:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .up {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .up:before {
  content: "ï¢";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-actions .up:disabled {
  cursor: not-allowed;
}

.module-actions .up:not([disabled]) {
  cursor: pointer;
}

.module-actions .up:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .down {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .down:before {
  content: "ï£";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-actions .down:disabled {
  cursor: not-allowed;
}

.module-actions .down:not([disabled]) {
  cursor: pointer;
}

.module-actions .down:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .messages {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .messages:before {
  content: "ïµ";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 400;
}

.module-actions .messages:disabled {
  cursor: not-allowed;
}

.module-actions .messages:not([disabled]) {
  cursor: pointer;
}

.module-actions .messages:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-actions .messages.show-messages {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-actions .messages.show-messages:before {
  content: "ïµ";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #dbe3e8;
  font-weight: 400;
}

.module-actions .messages.show-messages:disabled {
  cursor: not-allowed;
}

.module-actions .messages.show-messages:not([disabled]) {
  cursor: pointer;
}

.module-actions .messages:not(.unread-messages):not(.show-messages):after {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #E53140;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.module-actions .unread-messages::after {
  content: attr(data-message-count);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  background-color: #02C27E;
  color: white;
  padding: 3px;
  border-radius: 100%;
  font-weight: 700;
  position: absolute;
  top: -2px;
  right: -5px;
  height: 1.25rem;
  width: 1.25rem;
  z-index: 1;
}

.module-name-editor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 15px 0 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.module-name-editor .message-container {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}

.module-name-editor .message-container div.info.message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-name-editor .message-container .cancel.publish {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 30px);
          flex: 0 0 calc(100% - 30px);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.module-name-editor .message-container .cancel.publish:before {
  content: "ï‡˜";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: block;
  position: absolute;
  height: 2rem;
  width: 1.5rem;
  color: #4a4a4b;
  background-color: #d9edf7;
  line-height: 1.75;
  top: 0;
  left: -1.55rem;
}

.module-name-editor .message-container .cancel.publish:after {
  content: "ï€—";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.75rem;
  font-weight: bold;
  font-style: normal;
  font-size: .75rem;
  display: block;
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 100%;
  line-height: 1.15;
  color: #02C27E;
  background-color: #d9edf7;
  top: .9rem;
  left: -1.95rem;
}

.module-name-editor .message-container .cancel.publish a.cancel,
.module-name-editor .message-container .cancel.publish a.cancel-publish-button {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  height: 1.5rem;
  width: 1.5rem;
}

.module-name-editor .message-container .cancel.publish a.cancel:before,
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:before {
  content: "ï‡˜";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 800;
}

.module-name-editor .message-container .cancel.publish a.cancel:disabled,
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:disabled {
  cursor: not-allowed;
}

.module-name-editor .message-container .cancel.publish a.cancel:not([disabled]),
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:not([disabled]) {
  cursor: pointer;
}

.module-name-editor .message-container .cancel.publish a.cancel:after,
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:after {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.75rem;
  font-weight: bold;
  font-style: normal;
  font-size: .75rem;
  display: block;
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 100%;
  line-height: 1.15;
  color: #E53140;
  background-color: #d9edf7;
  top: .95rem;
  right: .95rem;
}

.module-name-editor .message-container .cancel.publish a.cancel:disabled,
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:disabled {
  cursor: not-allowed;
}

.module-name-editor .message-container .cancel.publish a.cancel:not([disabled]),
.module-name-editor .message-container .cancel.publish a.cancel-publish-button:not([disabled]) {
  cursor: pointer;
}

.module-name-editor .module-name {
  margin: 0;
}

.module-name-editor .prop.module-name {
  margin-bottom: 15px;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

.module-name-editor input.module-name,
.module-name-editor span.module-name {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
}

.module-name-editor input.module-name {
  max-width: calc(100% - 120px);
  padding: 5px;
}

.module-name-editor span.module-name {
  padding: 5px 0;
}

.module-name-editor .module-name-actions {
  -ms-flex-item-align: center;
      align-self: center;
}

.module-name-editor .module-name-actions .btn.edit {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  margin-top: -10px;
}

.module-name-editor .module-name-actions .btn.edit:before {
  content: "ïŒ„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 700;
}

.module-name-editor .module-name-actions .btn.edit:disabled {
  cursor: not-allowed;
}

.module-name-editor .module-name-actions .btn.edit:not([disabled]) {
  cursor: pointer;
}

.module-name-editor .module-name-actions .btn.edit:hover {
  background-color: rgba(74, 74, 75, 0.2);
  border-color: rgba(74, 74, 75, 0.2);
}

.module-name-editor .module-name-actions .save,
.module-name-editor .module-name-actions .btn.save {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-name-editor .module-name-actions .save:before,
.module-name-editor .module-name-actions .btn.save:before {
  content: "ï€Œ";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #02C27E;
  font-weight: 700;
}

.module-name-editor .module-name-actions .save:disabled,
.module-name-editor .module-name-actions .btn.save:disabled {
  cursor: not-allowed;
}

.module-name-editor .module-name-actions .save:not([disabled]),
.module-name-editor .module-name-actions .btn.save:not([disabled]) {
  cursor: pointer;
}

.module-name-editor .module-name-actions .save:hover,
.module-name-editor .module-name-actions .btn.save:hover {
  background-color: rgba(2, 194, 126, 0.2);
  border-color: rgba(2, 194, 126, 0.2);
}

.module-name-editor .module-name-actions .cancel,
.module-name-editor .module-name-actions .btn.cancel {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-name-editor .module-name-actions .cancel:before,
.module-name-editor .module-name-actions .btn.cancel:before {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #E53140;
  font-weight: 700;
}

.module-name-editor .module-name-actions .cancel:disabled,
.module-name-editor .module-name-actions .btn.cancel:disabled {
  cursor: not-allowed;
}

.module-name-editor .module-name-actions .cancel:not([disabled]),
.module-name-editor .module-name-actions .btn.cancel:not([disabled]) {
  cursor: pointer;
}

.module-name-editor .module-name-actions .cancel:hover,
.module-name-editor .module-name-actions .btn.cancel:hover {
  background-color: rgba(229, 49, 64, 0.2);
  border-color: rgba(229, 49, 64, 0.2);
}

.component-wrapper {
  position: relative;
}

.component-wrapper .component-editor-actions {
  position: absolute;
  top: 1px;
  right: 15px;
  z-index: 1;
  text-align: center;
}

.component-wrapper .component-editor-actions .btn.edit {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.component-wrapper .component-editor-actions .btn.edit:before {
  content: "ïŒ„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.125rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #4a4a4b;
  font-weight: 700;
}

.component-wrapper .component-editor-actions .btn.edit:disabled {
  cursor: not-allowed;
}

.component-wrapper .component-editor-actions .btn.edit:not([disabled]) {
  cursor: pointer;
}

.component-wrapper.cw_brand-main .component-editor-actions .btn.edit, .component-wrapper.cw_brand-main-gradient .component-editor-actions .btn.edit {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.component-wrapper.cw_brand-main .component-editor-actions .btn.edit:before, .component-wrapper.cw_brand-main-gradient .component-editor-actions .btn.edit:before {
  content: "ïŒ„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.125rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: rgb(var(--color-brand-main-reverse));
  font-weight: 700;
}

.component-wrapper.cw_brand-main .component-editor-actions .btn.edit:disabled, .component-wrapper.cw_brand-main-gradient .component-editor-actions .btn.edit:disabled {
  cursor: not-allowed;
}

.component-wrapper.cw_brand-main .component-editor-actions .btn.edit:not([disabled]), .component-wrapper.cw_brand-main-gradient .component-editor-actions .btn.edit:not([disabled]) {
  cursor: pointer;
}

.component-wrapper.cw_brand-accent .component-editor-actions .btn.edit, .component-wrapper.cw_brand-accent-gradient .component-editor-actions .btn.edit {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.component-wrapper.cw_brand-accent .component-editor-actions .btn.edit:before, .component-wrapper.cw_brand-accent-gradient .component-editor-actions .btn.edit:before {
  content: "ïŒ„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.125rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: rgb(var(--color-brand-accent-reverse));
  font-weight: 700;
}

.component-wrapper.cw_brand-accent .component-editor-actions .btn.edit:disabled, .component-wrapper.cw_brand-accent-gradient .component-editor-actions .btn.edit:disabled {
  cursor: not-allowed;
}

.component-wrapper.cw_brand-accent .component-editor-actions .btn.edit:not([disabled]), .component-wrapper.cw_brand-accent-gradient .component-editor-actions .btn.edit:not([disabled]) {
  cursor: pointer;
}

.manage-mode .module-empty {
  visibility: hidden;
  padding: .5rem 1rem;
  margin: 10px 2px 0 2px;
}

.managing .module-empty {
  visibility: visible;
  background-color: #dbe3e8;
  color: #4a4a4b;
  font-weight: 700;
}

.managing .component-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.managing .component-wrapper:hover {
  outline: 1px solid #56E3B1;
}

.managing .component-wrapper:hover .component-editor-actions .btn.edit:before {
  color: #02C27E;
}

.managing .component-wrapper.cw_brand-main:hover .component-editor-actions .btn.edit:before, .managing .component-wrapper.cw_brand-main-gradient:hover .component-editor-actions .btn.edit:before {
  color: #e0faf1;
}

.managing .component-wrapper.cw_brand-accent:hover .component-editor-actions .btn.edit:before, .managing .component-wrapper.cw_brand-accent-gradient:hover .component-editor-actions .btn.edit:before {
  color: #e0faf1;
}

.component-editor textarea.cke_source {
  font-family: 'Courier New',Monospace !important;
  font-size: small !important;
  background-color: #fff !important;
  white-space: pre-wrap !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: block !important;
}

.component-editor .dialog.prompt-close .actions {
  padding-top: 1rem;
}

.component-editor .editor > .message-container:not(.empty) {
  padding: 1rem 1rem 0.5rem;
}

.component-editor .editor > .message-container:not(.empty) .message {
  padding: .5rem;
  border: 1px solid #1C74BA;
  border-radius: 5px;
  color: #1C74BA;
  background-color: #BDE5F8;
}

.component-editor .editor > .message-container:not(.empty) .message + .message {
  margin-top: 0.5rem;
}

.module-shown-date-con {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-shown-date-con .module-shown-date {
  padding-right: 1ch;
  -ms-flex-item-align: center;
      align-self: center;
}

.module-shown-date-con .module-shown-date.scheduled {
  color: #636365;
}

.module-shown-date-con .module-shown-date.visible {
  color: #4a4a4b;
}

.module-name-editor h1 .module-visibility {
  color: #F9BB29;
  font-size: 1rem;
  -ms-flex-item-align: center;
      align-self: center;
}

.module-name-editor h1 .module-visibility.visible {
  color: #02C27E;
}

div.window.dialog.file-dialog {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 0.3rem;
  outline: 0;
}

div.window.dialog.file-dialog .titlebar {
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  z-index: 501;
  display: block;
}

div.window.dialog.file-dialog .titlebar button.btn:hover {
  color: #fff;
}

div.window.dialog.file-dialog .titlebar tbody {
  display: block;
}

div.window.dialog.file-dialog .titlebar tr {
  display: block;
}

div.window.dialog.file-dialog .titlebar td {
  display: block;
}

div.window.dialog.file-dialog td.activewindowtitle {
  padding: 25px 30px 1px;
  text-align: left;
  margin: 0;
  line-height: 1;
  font-size: 1.875rem;
  font-weight: 700;
  color: rgb(var(--color-brand-main));
  height: auto;
}

div.window.dialog.file-dialog td.windowbuttons {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 100%;
  color: inherit;
  padding: 0 10px;
  z-index: 502;
}

div.window.dialog.file-dialog td.windowbuttons .btn {
  background-color: transparent;
  padding: 0 10px 0;
  border: none;
  color: transparent;
  cursor: pointer;
  width: 50px;
  overflow: hidden;
  text-indent: -2.25ch;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

div.window.dialog.file-dialog td.windowbuttons .btn::after {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.95rem;
  font-weight: bold;
  font-style: normal;
  color: #cfcfcf;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}

div.window.dialog.file-dialog td.windowbuttons .btn:hover::after {
  color: #4a4a4b;
}

div.window.dialog.file-dialog .windowcontent {
  padding: 1px 25px 25px;
  border: none;
  background-color: #fff;
  color: #000;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  z-index: 502;
}

div.window.dialog.file-dialog .windowcontent .prop-editor {
  padding: 0;
}

div.window.dialog.file-dialog .actions.bottom,
div.window.dialog.file-dialog .actions.top {
  width: 100%;
  text-align: center;
}

div.window.dialog.file-dialog .actions.bottom::after,
div.window.dialog.file-dialog .actions.top::after {
  display: block;
  clear: both;
  content: "";
}

div.window.dialog.file-dialog .actions.bottom .btn + .btn,
div.window.dialog.file-dialog .actions.top .btn + .btn {
  margin-bottom: 0;
}

div.window.dialog.file-dialog .actions.bottom .btn-group .btn + .btn,
div.window.dialog.file-dialog .actions.top .btn-group .btn + .btn {
  margin-left: -1px;
}

div.window.dialog.file-dialog .actions.bottom .btn-block + .btn-block,
div.window.dialog.file-dialog .actions.top .btn-block + .btn-block {
  margin-left: 0;
}

div.window.dialog.file-dialog button.btn {
  border-color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main));
  color: #fff;
}

div.window.dialog.file-dialog button.btn:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main));
}

div.window.dialog.file-dialog .actions.top {
  border-top: none;
  border-bottom: 1px solid #fff;
  padding: 0 0 15px;
  margin: 0 0 1rem;
}

div.window.dialog.file-dialog .actions.bottom {
  border-top: 1px solid #dbe3e8;
  margin: 1rem 0 0;
  padding: 15px 0 0;
}

div.window.dialog.file-dialog h1 {
  font-size: 1.5rem;
}

div.window.dialog.file-dialog h2 {
  font-size: 1rem;
  padding-bottom: .15rem !important;
}

div.window.dialog.file-dialog .email-notifications {
  margin-bottom: 1rem;
}

div.window.dialog.file-dialog .miwt-table {
  font-size: 85%;
}

div.window.dialog.file-dialog .miwt-table > tbody > tr + tr {
  border-top: 1px solid white;
}

div.window.dialog.file-dialog .actions {
  clear: both;
}

div.window.dialog.file-dialog .actions .btn.close {
  padding: 10px 0 0;
  margin: 0 0 15px;
  font-size: 1.125rem;
  text-transform: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #4a4a4b;
  color: #4a4a4b;
  line-height: 1.2;
  background: transparent;
  border-radius: 0;
}

div.window.dialog.file-dialog input[type="text"],
div.window.dialog.file-dialog input[type="tel"],
div.window.dialog.file-dialog input[type="email"],
div.window.dialog.file-dialog textarea {
  background: #F1F2F7;
  border: 1px solid #dbe3e8;
  display: block;
  width: 100%;
  padding: 10px 15px;
  color: #4a4a4b;
  outline: none;
  -webkit-transition: border .15s ease;
  transition: border .15s ease;
}

div.window.dialog.file-dialog textarea {
  padding: 5px;
  font-style: italic;
  min-height: 100px;
  resize: none;
}

div.window.dialog.file-dialog input[type="file"] {
  padding: .5rem;
}

div.window.dialog.file-dialog input[type="file"] + .actions {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid #dbe3e8;
}

div.window.dialog.file-dialog label {
  color: #cfcfcf;
  font-weight: 700;
  text-transform: uppercase;
  font-size: .875rem;
  margin: 0 0 5px;
  line-height: 1;
}

div.window.dialog.file-dialog .note {
  margin-top: 1rem;
}

div.window.dialog.file-dialog .note label,
div.window.dialog.file-dialog .note .tal {
  font-weight: 500;
  margin-bottom: 0;
}

div.window.dialog.file-dialog .prop-body {
  max-width: none;
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
}

div.window.dialog.file-dialog .prop-footer {
  margin: 0;
  max-width: none;
}

div.window.dialog.file-dialog .prop-footer .actions {
  max-width: none;
}

div.window.dialog.file-dialog .prop-body div.prop {
  display: block;
}

div.window.dialog.file-dialog .prop-group.time label.prop-group-title {
  margin-bottom: -2.5rem;
  font-size: 1rem;
}

div.window.dialog.file-dialog .prop-group.time .zoned-time-editor span.time,
div.window.dialog.file-dialog .prop-group.time .zoned-time-editor span.timezone {
  display: inline-block;
}

div.window.dialog.file-dialog .prop-group.time .zoned-time-editor span.timezone {
  margin-left: 1.5rem;
}

div.window.dialog.file-dialog .prop.subject input[type="text"] {
  width: 100%;
}

div.window.dialog.file-dialog .search .entity-actions .menu-component,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component {
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

div.window.dialog.file-dialog .search .entity-actions .menu-component:hover,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:hover {
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

div.window.dialog.file-dialog .search .entity-actions .menu-component:active, div.window.dialog.file-dialog .search .entity-actions .menu-component.active,
.open > div.window.dialog.file-dialog .search .entity-actions .menu-component.dropdown-toggle,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:active,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.active,
.open >
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.dropdown-toggle {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

div.window.dialog.file-dialog .search .entity-actions .menu-component:active:hover, div.window.dialog.file-dialog .search .entity-actions .menu-component:active:focus, div.window.dialog.file-dialog .search .entity-actions .menu-component:active.focus, div.window.dialog.file-dialog .search .entity-actions .menu-component.active:hover, div.window.dialog.file-dialog .search .entity-actions .menu-component.active:focus, div.window.dialog.file-dialog .search .entity-actions .menu-component.active.focus,
.open > div.window.dialog.file-dialog .search .entity-actions .menu-component.dropdown-toggle:hover,
.open > div.window.dialog.file-dialog .search .entity-actions .menu-component.dropdown-toggle:focus,
.open > div.window.dialog.file-dialog .search .entity-actions .menu-component.dropdown-toggle.focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:active:hover,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:active:focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:active.focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.active:hover,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.active:focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.active.focus,
.open >
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.dropdown-toggle:hover,
.open >
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.dropdown-toggle:focus,
.open >
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.dropdown-toggle.focus {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

div.window.dialog.file-dialog .search .entity-actions .menu-component.disabled:focus, div.window.dialog.file-dialog .search .entity-actions .menu-component.disabled.focus, div.window.dialog.file-dialog .search .entity-actions .menu-component:disabled:focus, div.window.dialog.file-dialog .search .entity-actions .menu-component:disabled.focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.disabled:focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.disabled.focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:disabled:focus,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:disabled.focus {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

div.window.dialog.file-dialog .search .entity-actions .menu-component.disabled:hover, div.window.dialog.file-dialog .search .entity-actions .menu-component:disabled:hover,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component.disabled:hover,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component:disabled:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

div.window.dialog.file-dialog .search .entity-actions .menu-component > a.menu-item,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component > a.menu-item {
  font-size: 12px;
}

div.window.dialog.file-dialog .search .entity-actions .menu-component > a.menu-item:before,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component > a.menu-item:before {
  font-size: 10px;
  margin-top: -2px;
  padding-right: 4px;
}

div.window.dialog.file-dialog .search .entity-actions .menu-component li.menu ul.menu-items,
div.window.dialog.file-dialog .search-wrapper .entity-actions .menu-component li.menu ul.menu-items {
  top: auto;
  bottom: -10px;
}

.module-feed {
  /*
    FIXME : update all workspace dialogs to have a common class name
    https://agile.proteus.co/browse/ENGAGE-2926
*/
  /* I don't particularly like this style, but tracking down the specificity thing is making me crazy, so this is a
hot-fix. TODO: actual remove the display: none; wherever it is hiding currently */
  /* loading dots */
  /*
* Stylesheet for MassCare ROI Calculator
 */
}

.module-feed .window.dialog {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
  border: none;
  overflow: auto;
  background: rgba(241, 242, 247, 0.9);
  padding: 32px 0 65px;
}

@media (min-width: 1024px) {
  .module-feed .window.dialog {
    padding: 0 calc((100vw - 900px) / 2) !important;
  }
}

.module-feed .window.dialog > .titlebar {
  min-height: 30px;
  margin-top: 1em;
}

@media (max-width: 767px) {
  .module-feed .window.dialog > .titlebar {
    margin-top: 2.5em;
  }
}

.module-feed .window.dialog > .titlebar .titlebar.activewindowtitle {
  padding: 15px 15px 1px;
  font-size: 1.2rem;
  background: rgb(var(--color-brand-main));
  height: 50px;
}

@media (min-width: 1024px) {
  .module-feed .window.dialog > .titlebar .titlebar.activewindowtitle {
    font-size: 1.6rem;
  }
}

.module-feed .window.dialog > .titlebar .titlebar.inactivewindowtitle {
  padding: 15px 15px 1px;
  font-size: 1.2rem;
  background: rgb(var(--color-brand-main));
  height: 50px;
  color: transparent;
}

@media (min-width: 1024px) {
  .module-feed .window.dialog > .titlebar .titlebar.inactivewindowtitle {
    font-size: 1.6rem;
  }
}

.module-feed .window.dialog > .titlebar td.windowbuttons {
  height: 50px;
  display: block;
}

@media (min-width: 1024px) {
  .module-feed .window.dialog > .titlebar {
    max-width: 900px;
    min-width: 900px;
  }
}

.module-feed .window.dialog > .windowcontent {
  max-width: 100%;
  min-width: 100%;
  min-height: unset;
  max-height: unset;
  overflow: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: static;
}

@media (min-width: 1024px) {
  .module-feed .window.dialog > .windowcontent {
    max-width: 900px;
    min-width: 900px;
  }
}

.module-feed .window.dialog > .windowcontent .editor-title {
  color: rgb(var(--color-brand-main-dark));
  position: relative;
  margin-bottom: 10px;
}

.module-feed .window.dialog > .windowcontent .editor-title:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #dbe3e8;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
}

.module-feed .window.dialog > .windowcontent label,
.module-feed .window.dialog > .windowcontent .label {
  display: block;
  width: 100%;
  color: rgb(var(--color-brand-main));
  line-height: 1.2;
  text-transform: none;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 20px 10px 0 0;
}

.module-feed .window.dialog .bottom.persistence-actions.actions,
.module-feed .window.dialog .editor-actions {
  text-align: left !important;
  padding: 15px;
}

@media (max-width: 767px) {
  .module-feed .window.dialog .bottom.persistence-actions.actions,
  .module-feed .window.dialog .editor-actions {
    margin-bottom: 38px;
  }
}

.module-feed .window.dialog .publish input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}

.module-feed .window.dialog .publish input[type="radio"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 50%;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

.module-feed .window.dialog .publish div.publish-now {
  margin-top: 10px;
}

.module-feed .window.dialog .publish div.publish-now span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .window.dialog .publish div.publish-now label {
  margin: 0 10px 10px;
}

.module-feed .window.dialog .publish .publish-later span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .window.dialog .publish .publish-later label {
  margin: 0 10px 10px;
}

.module-feed .window.dialog .publish .publish-later .select2-selection__rendered {
  padding: .5rem 2.75rem .5rem .5rem;
}

.module-feed .window.dialog .publish .publish-later .select2-selection__arrow {
  width: 0.5rem;
}

.module-feed .window.dialog .publish .publish-later .select2-selection__arrow:after {
  padding-left: 0.25rem;
}

.module-feed .window.dialog .publish .publish-later .hour-selector .timezone {
  padding-left: 25px;
}

.module-feed .window.dialog .notify .label-after {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .window.dialog .notify .label-after label {
  margin: 0 10px 0;
}

.module-feed .window.dialog .notify input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.module-feed .window.dialog .notify input[type="checkbox"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 2px;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

.module-feed .window.dialog .note label {
  margin-left: 0 !important;
  text-transform: uppercase;
}

.module-feed .window.dialog div.replacement-message span.message-text,
.module-feed .window.dialog div.replacement-message span.message-flavor,
.module-feed .window.dialog div.replacement-message span.asset-name {
  margin-left: 0;
}

.module-feed .window.dialog div.message.success:before {
  content: none;
}

.module-feed div.window.send-module-dlg .windowcontent {
  border-radius: 0 0 3px 3px;
  margin-top: 0;
}

.module-feed div.window.send-module-dlg .windowcontent > .title {
  margin: 20px 0 10px;
}

.module-feed div.window.send-module-dlg .windowcontent .module-status {
  margin-top: 10px;
}

.module-feed div.window.send-module-dlg .windowcontent .notification-info .prop label.tal {
  margin: 0 0 5px;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
}

.module-feed div.window.send-module-dlg .windowcontent .notification-info .prop input[type=text] {
  background-color: #fff;
}

.module-feed div.window.send-module-dlg > .titlebar {
  border-radius: 3px 3px 0 0;
  -webkit-box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
}

.module-feed div.window.send-module-dlg .group-heading {
  padding: 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  color: #4a4a4b;
}

.module-feed div.window.send-module-dlg label.tbl {
  color: #4a4a4b;
  display: inline-block;
}

.module-feed div.window.send-module-dlg .prop label {
  color: #4a4a4b;
  margin: 0 10px 5px;
}

.module-feed div.window.send-module-dlg .prop-group .ctb.label-before label.tbl {
  font-weight: normal;
  text-transform: none;
  color: #000000;
}

.module-feed div.window.send-module-dlg .prop-group .ctb input[type="checkbox"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1.1rem;
  height: 1rem;
  border-radius: 3px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.module-feed div.window.send-module-dlg .prop-group .ctb input[type="checkbox"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 2px;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

.module-feed div.window.send-module-dlg .prop-group .ctb input[type="checkbox"] + label.tbl:after {
  content: "";
}

@media (min-width: 480px) {
  .module-feed div.window.send-module-dlg .prop-group .ctb {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: calc(50% - 15px);
    margin-right: 15px;
    vertical-align: top;
  }
}

.module-feed div.window.send-module-dlg .miwt-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  max-width: 300px;
  padding: 0 15px;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.open {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.open:before {
  content: "ï‰±";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #02C27E;
  font-weight: 700;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.open:disabled {
  cursor: not-allowed;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.open:not([disabled]) {
  cursor: pointer;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.open:hover {
  background-color: rgba(2, 194, 126, 0.2);
  border-color: rgba(2, 194, 126, 0.2);
}

.module-feed div.window.send-module-dlg .miwt-calendar button.clear {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.clear:before {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #F27E30;
  font-weight: 700;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.clear:disabled {
  cursor: not-allowed;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.clear:not([disabled]) {
  cursor: pointer;
}

.module-feed div.window.send-module-dlg .miwt-calendar button.clear:hover {
  background-color: rgba(242, 126, 48, 0.2);
  border-color: rgba(242, 126, 48, 0.2);
}

.module-feed div.window.send-module-dlg .hour-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.module-feed div.window.send-module-dlg .hour-selector .select2 {
  width: 80px !important;
}

.module-feed div.window.send-module-dlg .hour-selector .timezone {
  padding-left: 10px;
}

.module-feed div.window.send-module-dlg .instructions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed div.window.send-module-dlg .instructions.publish-later {
  padding: 0 25px;
}

.module-feed div.window.send-module-dlg .options .notify-label,
.module-feed div.window.send-module-dlg .options .publish-label {
  font-size: 1rem;
  color: rgb(var(--color-brand-main));
  text-transform: uppercase;
  margin-left: 0;
}

.module-feed div.window.dialog.file-dialog .sibling_directories-con {
  vertical-align: top;
}

.module-feed div.window.dialog.file-dialog .sibling_directories {
  padding-right: 15px;
}

.module-feed div.window.dialog.file-dialog .sibling_directories input[type=radio] {
  display: none;
}

.module-feed div.window.dialog.file-dialog .sibling_directories .btn-image img {
  display: none;
}

.module-feed div.window.dialog.file-dialog .sibling_directories .btn-image:after {
  content: "ï»";
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  padding-right: 5px;
  vertical-align: baseline;
}

.module-feed div.window.dialog.file-dialog .file_listing {
  width: 100%;
  border: 1px solid #F4F6FC;
  border-collapse: collapse;
}

.module-feed div.window.dialog.file-dialog .file_listing th {
  background-color: #dbe3e8;
}

.module-feed div.window.dialog.file-dialog .file_listing th,
.module-feed div.window.dialog.file-dialog .file_listing td {
  padding: 5px;
}

.module-feed div.window.dialog.file-dialog .file_listing tbody tr.odd {
  background-color: #F4F6FC;
}

.module-feed div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_thumbnail {
  max-width: 20%;
  height: auto;
}

.module-feed div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .btn-image {
  display: none;
}

.module-feed div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_file {
  -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
  padding: 10px;
  word-break: break-all;
  padding-right: 5px;
}

.module-feed div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_file span {
  display: inline-block;
}

.module-feed div.window.dialog.file-dialog td.actions-con .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.module-feed div.window.dialog.file-dialog td.actions-con .actions .upload,
.module-feed div.window.dialog.file-dialog td.actions-con .actions .createFolder {
  text-align: left;
  width: 280px;
}

.module-feed div.window.dialog.file-dialog td.actions-con .actions .upload button.btn,
.module-feed div.window.dialog.file-dialog td.actions-con .actions .createFolder button.btn {
  margin-top: 1rem;
}

.module-feed div.window.dialog.file-dialog td.actions-con .actions .createFolder input {
  margin: 1rem 0 0;
}

.module-feed .value-list-editor-list-orderable .list-renderer:active {
  border: 1px solid rgb(var(--color-brand-main)) !important;
}

.module-feed .value-list-editor-list-orderable .list-renderer .actions .btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.module-feed .value-list-editor-list-orderable .list-renderer .actions .btn.remove:hover:before {
  color: #E53140 !important;
}

.module-feed .value-list-editor-list-orderable .list-renderer .actions .btn.edit:hover:before {
  color: rgb(var(--color-brand-main)) !important;
}

.module-feed .dnd-source {
  cursor: grab;
  cursor: -webkit-grab;
}

.module-feed .dnd-active {
  cursor: no-drop;
}

.module-feed .dnd-active .dnd-source {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.module-feed .dnd-active .dnd-target {
  cursor: copy;
}

@media (min-width: 768px) {
  .module-feed .custom-content-editor .factor-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 10px 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .module-feed .custom-content-editor .factor-row .prop {
    max-width: 32%;
  }
}

.module-feed #document-title .prop-title {
  padding: 0;
}

.module-feed #document-selection .select-label, .module-feed #company-why-selection .select-label, .module-feed #demo-selection .select-label, .module-feed #meeting-selection .select-label, .module-feed #proposal-selection .select-label, .module-feed #impact-stories-selection .select-label,
.module-feed #onboarding-next-selection .select-label, .module-feed #hired-selection .select-label, .module-feed #about-job-selection .select-label, .module-feed #field-selection .select-label, .module-feed #product-service-selection .select-label {
  margin: 10px 0 0;
}

.module-feed #document-selection .select2, .module-feed #company-why-selection .select2, .module-feed #demo-selection .select2, .module-feed #meeting-selection .select2, .module-feed #proposal-selection .select2, .module-feed #impact-stories-selection .select2,
.module-feed #onboarding-next-selection .select2, .module-feed #hired-selection .select2, .module-feed #about-job-selection .select2, .module-feed #field-selection .select2, .module-feed #product-service-selection .select2 {
  margin: 5px 0 10px;
  width: 350px !important;
}

.module-feed #document-selection .select2-selection, .module-feed #company-why-selection .select2-selection, .module-feed #demo-selection .select2-selection, .module-feed #meeting-selection .select2-selection, .module-feed #proposal-selection .select2-selection, .module-feed #impact-stories-selection .select2-selection,
.module-feed #onboarding-next-selection .select2-selection, .module-feed #hired-selection .select2-selection, .module-feed #about-job-selection .select2-selection, .module-feed #field-selection .select2-selection, .module-feed #product-service-selection .select2-selection {
  border-radius: 0;
  border: 1px solid #a8acbc;
  height: 36px;
  line-height: 1;
}

.module-feed #document-selection .select2-selection .select2-selection__rendered, .module-feed #company-why-selection .select2-selection .select2-selection__rendered, .module-feed #demo-selection .select2-selection .select2-selection__rendered, .module-feed #meeting-selection .select2-selection .select2-selection__rendered, .module-feed #proposal-selection .select2-selection .select2-selection__rendered, .module-feed #impact-stories-selection .select2-selection .select2-selection__rendered,
.module-feed #onboarding-next-selection .select2-selection .select2-selection__rendered, .module-feed #hired-selection .select2-selection .select2-selection__rendered, .module-feed #about-job-selection .select2-selection .select2-selection__rendered, .module-feed #field-selection .select2-selection .select2-selection__rendered, .module-feed #product-service-selection .select2-selection .select2-selection__rendered {
  line-height: 34px;
  font-size: 1rem;
}

.module-feed #document-selection .select2-selection .select2-selection__arrow, .module-feed #company-why-selection .select2-selection .select2-selection__arrow, .module-feed #demo-selection .select2-selection .select2-selection__arrow, .module-feed #meeting-selection .select2-selection .select2-selection__arrow, .module-feed #proposal-selection .select2-selection .select2-selection__arrow, .module-feed #impact-stories-selection .select2-selection .select2-selection__arrow,
.module-feed #onboarding-next-selection .select2-selection .select2-selection__arrow, .module-feed #hired-selection .select2-selection .select2-selection__arrow, .module-feed #about-job-selection .select2-selection .select2-selection__arrow, .module-feed #field-selection .select2-selection .select2-selection__arrow, .module-feed #product-service-selection .select2-selection .select2-selection__arrow {
  right: 5px;
  top: 6px;
}

.module-feed #document-selection .select2-selection .select2-selection__arrow b, .module-feed #company-why-selection .select2-selection .select2-selection__arrow b, .module-feed #demo-selection .select2-selection .select2-selection__arrow b, .module-feed #meeting-selection .select2-selection .select2-selection__arrow b, .module-feed #proposal-selection .select2-selection .select2-selection__arrow b, .module-feed #impact-stories-selection .select2-selection .select2-selection__arrow b,
.module-feed #onboarding-next-selection .select2-selection .select2-selection__arrow b, .module-feed #hired-selection .select2-selection .select2-selection__arrow b, .module-feed #about-job-selection .select2-selection .select2-selection__arrow b, .module-feed #field-selection .select2-selection .select2-selection__arrow b, .module-feed #product-service-selection .select2-selection .select2-selection__arrow b {
  display: none;
}

.module-feed #document-selection .select2-selection .select2-selection__arrow:after, .module-feed #company-why-selection .select2-selection .select2-selection__arrow:after, .module-feed #demo-selection .select2-selection .select2-selection__arrow:after, .module-feed #meeting-selection .select2-selection .select2-selection__arrow:after, .module-feed #proposal-selection .select2-selection .select2-selection__arrow:after, .module-feed #impact-stories-selection .select2-selection .select2-selection__arrow:after,
.module-feed #onboarding-next-selection .select2-selection .select2-selection__arrow:after, .module-feed #hired-selection .select2-selection .select2-selection__arrow:after, .module-feed #about-job-selection .select2-selection .select2-selection__arrow:after, .module-feed #field-selection .select2-selection .select2-selection__arrow:after, .module-feed #product-service-selection .select2-selection .select2-selection__arrow:after {
  content: "ï„‡";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  font-size: 1.25rem;
  color: #cfcfcf;
}

.module-feed #company-why-selection label, .module-feed #demo-selection label, .module-feed #meeting-selection label, .module-feed #proposal-selection label, .module-feed #onboarding-next-selection label,
.module-feed #hired-selection label, .module-feed #about-job-selection label, .module-feed #product-service-selection label, .module-feed #impact-stories-selection label {
  margin: 20px 0 0;
}

.module-feed #company-why-selection .dc-file-upload .label, .module-feed #demo-selection .dc-file-upload .label, .module-feed #meeting-selection .dc-file-upload .label, .module-feed #proposal-selection .dc-file-upload .label, .module-feed #onboarding-next-selection .dc-file-upload .label,
.module-feed #hired-selection .dc-file-upload .label, .module-feed #about-job-selection .dc-file-upload .label, .module-feed #product-service-selection .dc-file-upload .label, .module-feed #impact-stories-selection .dc-file-upload .label {
  margin: 0;
}

.module-feed #company-why-selection .select2, .module-feed #demo-selection .select2, .module-feed #meeting-selection .select2, .module-feed #proposal-selection .select2, .module-feed #onboarding-next-selection .select2,
.module-feed #hired-selection .select2, .module-feed #about-job-selection .select2, .module-feed #product-service-selection .select2, .module-feed #impact-stories-selection .select2 {
  width: 350px !important;
}

@media (max-width: 767px) {
  .module-feed #company-why-selection .select2, .module-feed #demo-selection .select2, .module-feed #meeting-selection .select2, .module-feed #proposal-selection .select2, .module-feed #onboarding-next-selection .select2,
  .module-feed #hired-selection .select2, .module-feed #about-job-selection .select2, .module-feed #product-service-selection .select2, .module-feed #impact-stories-selection .select2 {
    max-width: 280px;
  }
}

.module-feed #company-why-selection .poster_image span, .module-feed #demo-selection .poster_image span, .module-feed #meeting-selection .poster_image span, .module-feed #proposal-selection .poster_image span, .module-feed #onboarding-next-selection .poster_image span,
.module-feed #hired-selection .poster_image span, .module-feed #about-job-selection .poster_image span, .module-feed #product-service-selection .poster_image span, .module-feed #impact-stories-selection .poster_image span {
  margin: 20px 0 0;
  font-size: 1.1rem;
}

.module-feed #company-why-selection .poster_image .dimensions-instructions, .module-feed #demo-selection .poster_image .dimensions-instructions, .module-feed #meeting-selection .poster_image .dimensions-instructions, .module-feed #proposal-selection .poster_image .dimensions-instructions, .module-feed #onboarding-next-selection .poster_image .dimensions-instructions,
.module-feed #hired-selection .poster_image .dimensions-instructions, .module-feed #about-job-selection .poster_image .dimensions-instructions, .module-feed #product-service-selection .poster_image .dimensions-instructions, .module-feed #impact-stories-selection .poster_image .dimensions-instructions {
  font-style: italic;
  margin: 5px 0;
  font-size: .875rem;
}

.module-feed #field-selection label {
  margin: 20px 0 0;
}

.module-feed #field-selection .dc-file-upload .label {
  margin: 0;
}

.module-feed #field-selection .poster_image span {
  margin: 20px 0 0;
}

.module-feed #field-selection .poster_image .dimensions-instructions {
  margin: 0;
}

.module-feed .module {
  border-top: 1px solid #dbe3e8;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.module-feed .module-wrapper {
  margin-top: 30px;
  border: 1px solid #dbe3e8;
  -webkit-box-shadow: 0px 0px 20px #dbe3e8;
          box-shadow: 0px 0px 20px #dbe3e8;
}

.module-feed .module-wrapper .module-header .module-header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 0;
  padding-left: 20px;
}

@media (min-width: 1280px) {
  .module-feed .module-wrapper .module-header .module-header-inner {
    padding: 15px 0;
    padding-left: 30px;
  }
}

.module-feed .module-wrapper .module-header .module-header-inner .module-info {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.module-feed .module-content-title {
  margin-bottom: 1rem;
  width: 100%;
  color: rgb(var(--color-brand-main-dark));
  font-size: 1.5rem;
  font-weight: 400;
}

@media (min-width: 1280px) {
  .module-feed .module-content-title {
    font-size: 2.25rem;
  }
}

.module-feed .component-wrapper {
  color: #4a4a4b;
}

.module-feed .component-wrapper .content-title {
  display: inline-block;
  color: rgb(var(--color-brand-main-dark));
  font-size: 1.5rem;
  font-weight: 400;
  margin: .67rem 0;
}

@media (min-width: 1280px) {
  .module-feed .component-wrapper .content-title {
    font-size: 2.25rem;
  }
}

.module-feed .component-wrapper .content-subtitle {
  display: inline-block;
  color: rgb(var(--color-brand-main));
  font-size: 1.125rem;
  font-weight: 400;
  margin: .83rem 0;
}

@media (min-width: 1280px) {
  .module-feed .component-wrapper .content-subtitle {
    font-size: 1.375rem;
  }
}

.module-feed .component-wrapper div, .module-feed .component-wrapper p, .module-feed .component-wrapper span {
  line-height: 1.5;
}

.module-feed .component-wrapper a.link {
  display: inline-block;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8125rem;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  background-color: rgb(var(--color-brand-accent));
  color: rgb(var(--color-brand-accent-reverse));
}

.module-feed .component-wrapper a.link:hover, .module-feed .component-wrapper a.link:focus {
  background-color: rgb(var(--color-brand-accent));
  border: 1px solid rgb(var(--color-brand-accent));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-accent));
          box-shadow: 0 0 5px rgb(var(--color-brand-accent));
}

.module-feed .component-wrapper ol {
  margin: 1em 0;
  padding: 0 0 0 50px;
  list-style: none;
  counter-reset: engagesCompanyInfo;
}

.module-feed .component-wrapper ol:first-child {
  margin-top: 0;
}

.module-feed .component-wrapper ol:last-child {
  margin-bottom: 0;
}

.module-feed .component-wrapper ol li {
  position: relative;
  margin: 30px 0;
}

.module-feed .component-wrapper ol li:first-child {
  margin-top: 0;
}

.module-feed .component-wrapper ol li:last-child {
  margin-bottom: 0;
}

.module-feed .component-wrapper ol li:before {
  display: block;
  position: absolute;
  counter-increment: engagesCompanyInfo;
  content: counter(engagesCompanyInfo);
  width: 30px;
  height: 30px;
  line-height: 30px;
  left: -50px;
  font-size: 1.33333rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  background-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main-reverse));
  text-align: center;
}

.module-feed .brand-main,
.module-feed .cw_brand-main-gradient,
.module-feed .cw_brand-main {
  background-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main-reverse));
}

.module-feed .brand-main .module-content-title,
.module-feed .brand-main .content-title,
.module-feed .brand-main .content-subtitle,
.module-feed .cw_brand-main-gradient .module-content-title,
.module-feed .cw_brand-main-gradient .content-title,
.module-feed .cw_brand-main-gradient .content-subtitle,
.module-feed .cw_brand-main .module-content-title,
.module-feed .cw_brand-main .content-title,
.module-feed .cw_brand-main .content-subtitle {
  color: rgb(var(--color-brand-main-reverse));
}

.module-feed .brand-main ol li:before,
.module-feed .cw_brand-main-gradient ol li:before,
.module-feed .cw_brand-main ol li:before {
  background-color: rgb(var(--color-brand-main-reverse));
  color: rgb(var(--color-brand-main));
}

.module-feed .brand-main a,
.module-feed .cw_brand-main-gradient a,
.module-feed .cw_brand-main a {
  color: rgb(var(--color-brand-main-reverse));
}

.module-feed .brand-main a.link,
.module-feed .cw_brand-main-gradient a.link,
.module-feed .cw_brand-main a.link {
  background-color: rgb(var(--color-brand-main-reverse));
  color: rgb(var(--color-brand-main));
}

.module-feed .brand-main a.link:hover, .module-feed .brand-main a.link:focus,
.module-feed .cw_brand-main-gradient a.link:hover,
.module-feed .cw_brand-main-gradient a.link:focus,
.module-feed .cw_brand-main a.link:hover,
.module-feed .cw_brand-main a.link:focus {
  background-color: rgb(var(--color-brand-main-reverse));
  border: 1px solid rgb(var(--color-brand-main-reverse));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-main-reverse));
          box-shadow: 0 0 5px rgb(var(--color-brand-main-reverse));
}

.module-feed .cw_neutral,
.module-feed .cw_neutral-gradient {
  background-color: #dbe3e8;
}

.module-feed .brand-accent,
.module-feed .cw_brand-accent-gradient,
.module-feed .cw_brand-accent {
  background-color: rgb(var(--color-brand-accent));
  color: rgb(var(--color-brand-accent-reverse));
}

.module-feed .brand-accent .module-content-title,
.module-feed .brand-accent .content-title,
.module-feed .brand-accent .content-subtitle,
.module-feed .cw_brand-accent-gradient .module-content-title,
.module-feed .cw_brand-accent-gradient .content-title,
.module-feed .cw_brand-accent-gradient .content-subtitle,
.module-feed .cw_brand-accent .module-content-title,
.module-feed .cw_brand-accent .content-title,
.module-feed .cw_brand-accent .content-subtitle {
  color: rgb(var(--color-brand-accent-reverse));
}

.module-feed .brand-accent ol li:before,
.module-feed .cw_brand-accent-gradient ol li:before,
.module-feed .cw_brand-accent ol li:before {
  background-color: rgb(var(--color-brand-accent-reverse));
  color: rgb(var(--color-brand-accent));
}

.module-feed .brand-accent a,
.module-feed .cw_brand-accent-gradient a,
.module-feed .cw_brand-accent a {
  color: rgb(var(--color-brand-accent-reverse));
}

.module-feed .brand-accent a.link,
.module-feed .cw_brand-accent-gradient a.link,
.module-feed .cw_brand-accent a.link {
  background-color: rgb(var(--color-brand-accent-reverse));
  color: rgb(var(--color-brand-accent));
}

.module-feed .brand-accent a.link:hover, .module-feed .brand-accent a.link:focus,
.module-feed .cw_brand-accent-gradient a.link:hover,
.module-feed .cw_brand-accent-gradient a.link:focus,
.module-feed .cw_brand-accent a.link:hover,
.module-feed .cw_brand-accent a.link:focus {
  background-color: rgb(var(--color-brand-accent-reverse));
  border: 1px solid rgb(var(--color-brand-accent-reverse));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-accent-reverse));
          box-shadow: 0 0 5px rgb(var(--color-brand-accent-reverse));
}

.module-feed .cw_brand-main-gradient {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.2)), color-stop(50%, rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, from(rgb(var(--color-brand-main))), to(rgb(var(--color-brand-main))));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgb(var(--color-brand-main)) 0%, rgb(var(--color-brand-main)) 100%);
}

.module-feed .cw_neutral-gradient {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.2)), color-stop(50%, rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, from(#dbe3e8), to(#dbe3e8));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, #dbe3e8 0%, #dbe3e8 100%);
}

.module-feed .cw_brand-accent-gradient {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.2)), color-stop(50%, rgba(0, 0, 0, 0))), -webkit-gradient(linear, left bottom, left top, from(rgb(var(--color-brand-accent))), to(rgb(var(--color-brand-accent))));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgb(var(--color-brand-accent)) 0%, rgb(var(--color-brand-accent)) 100%);
}

.module-feed .responsive-video-standard {
  border: 5px solid #dbe3e8;
  background-color: #dbe3e8;
}

@media (min-width: 768px) {
  .module-feed .responsive-video-standard {
    min-height: 230px;
  }
}

.module-feed .responsive-video-standard video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed .responsive-video-standard .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed .responsive-video-standard-image {
  border: none;
  background-color: none;
}

@media (min-width: 768px) {
  .module-feed .responsive-video-standard-image {
    min-height: 230px;
  }
}

.module-feed .responsive-video-standard-image .image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed > .no-data {
  display: block;
  text-align: center;
  margin-top: 30px;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(var(--color-brand-accent));
}

.module-feed .alignment-component .alignment-inner > div + div {
  margin-top: 20px;
}

@media (min-width: 480px) {
  .module-feed .alignment-component .alignment-inner > div + div {
    margin-top: 0;
  }
  .module-feed .alignment-component .alignment-case-statement {
    margin-bottom: 30px;
  }
  .module-feed .alignment-component .alignment-case-statement:after {
    content: "";
    display: block;
    margin-top: 30px;
    border-bottom: 1px solid rgb(var(--color-brand-main));
  }
}

.module-feed .award-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed .award-component .award {
  min-width: 280px;
  width: 100%;
  max-width: 420px;
  margin: 20px 0;
}

@media (min-width: 480px) {
  .module-feed .award-component .award {
    width: 49%;
  }
}

@media (min-width: 1024px) {
  .module-feed .award-component .award {
    width: 32%;
  }
}

.module-feed .award-component .award-inner {
  position: relative;
}

.module-feed .award-component .award-image {
  display: block;
  width: 100%;
  line-height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #4a4a4b;
}

.module-feed .award-component .award-image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 69.45946%;
  opacity: 0.25;
  background-color: #4a4a4b;
}

.module-feed .award-component .award-image a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-decoration: none;
}

.module-feed .award-component .award-image a .reader-only {
  display: block;
  height: 1px;
  width: 1px;
  color: transparent;
  overflow: hidden;
}

.module-feed .award-component .award-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: height 1s;
  transition: height 1s;
}

.module-feed .award-component .award-content .award-name {
  font-size: 1.375rem;
  font-weight: 700;
  color: rgb(var(--color-brand-main-reverse));
}

@media (min-width: 1280px) {
  .module-feed .award-component .award-content .award-name {
    font-size: 1.875rem;
  }
}

.module-feed .award-component .award-content .rte {
  height: 1px;
  text-align: center;
  overflow: hidden;
  color: white;
  width: 100%;
  -webkit-transition: height ease 0.5s;
  transition: height ease 0.5s;
  padding: 0 20px;
}

.module-feed .award-component .award:hover {
  cursor: pointer;
}

.module-feed .award-component .award:hover .award-name content-subtitle {
  margin: 0 0 0.83rem 0;
}

.module-feed .award-component .award:hover .award-image:before {
  opacity: 0.75;
  background-color: rgb(var(--color-brand-main));
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}

.module-feed .award-component .award:hover .award-content {
  -webkit-transition: height 1s;
  transition: height 1s;
}

.module-feed .award-component .award:hover .award-content .rte {
  height: 14ch;
  -webkit-transition: height ease 0.5s;
  transition: height ease 0.5s;
}

.module-feed .bio-inner:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 1280px) {
  .module-feed .bio-content:after {
    content: "";
    display: table;
    clear: both;
  }
}

.module-feed .bio-aside {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding-top: 0.5ch;
  padding-right: 30px;
  padding-bottom: 1.5ch;
}

@media (min-width: 1280px) {
  .module-feed .bio-aside {
    float: left;
  }
}

.module-feed .bio-photo img {
  width: 100%;
  height: auto;
}

.module-feed .bio-contact {
  padding: 0;
  margin: -1rem 0 1rem;
  list-style: none;
  font-size: 0.9375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.module-feed .bio-contact a {
  color: rgb(var(--color-brand-main));
  text-decoration: none;
}

.module-feed .bio-contact a:hover {
  text-decoration: underline;
}

.module-feed .bio-contact li {
  display: inline-block;
  word-break: break-all;
  margin: .67rem 0 0;
  padding: 0;
  padding-right: .67rem;
}

.module-feed .bio-contact li.job-title {
  width: 100%;
}

@media (min-width: 768px) {
  .module-feed .bio-contact li.job-title {
    width: auto;
  }
}

.module-feed .bio-contact li:not(.job-title) {
  color: rgb(var(--color-brand-main));
}

.module-feed .cw_celebration-component .celebration .celebration-inner {
  position: relative;
}

.module-feed .cw_celebration-component .celebration .celebration-video,
.module-feed .cw_celebration-component .celebration .celebration-tcontent {
  display: block;
  text-align: center;
}

.module-feed .cw_celebration-component .celebration .celebration-video {
  position: relative;
  margin: -20px -20px 20px;
}

@media (min-width: 1280px) {
  .module-feed .cw_celebration-component .celebration .celebration-video {
    margin: -30px -30px 30px;
  }
}

.module-feed .cw_celebration-component .celebration .celebration-video img {
  width: 100%;
}

.module-feed .cw_celebration-component .celebration .celebration-video:after {
  content: "ïŠµ";
  font-family: "Font Awesome 5 Pro";
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  height: 60px;
  width: 60px;
  line-height: unset;
  position: absolute;
  bottom: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: rgb(var(--color-brand-main-reverse));
  background-color: rgb(var(--color-brand-main-dark));
  border-radius: 100%;
}

.module-feed .cw_celebration-component .celebration .celebration-image img {
  max-height: 600px;
}

.module-feed .cw_celebration-component .celebration .celebration-tcontent {
  margin: 30px;
}

.module-feed .cw_celebration-component .celebration .content-title,
.module-feed .cw_celebration-component .celebration .content-subtitle {
  display: block;
  margin: 0 auto;
}

.module-feed .cw_celebration-component .celebration .content-subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.module-feed .cw_celebration-component .celebration .rte {
  margin-top: 30px;
}

.module-feed .cw_celebration-component #confetti_canvas,
.module-feed .cw_celebration-component .confetti_canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

@-webkit-keyframes milestone-main-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes milestone-main-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes milestone-accent-fade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes milestone-accent-fade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.module-feed .cw_milestone-component .milestone {
  text-align: center;
}

.module-feed .cw_milestone-component .milestone-title-wrapper {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 6rem 3rem 0;
  overflow: hidden;
}

.module-feed .cw_milestone-component .milestone-graphic {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 2rem;
}

.module-feed .cw_milestone-component .milestone-graphic svg {
  height: 100%;
  width: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .module-feed .cw_milestone-component .milestone-graphic.mg-main {
    -webkit-animation: milestone-main-fade 1s ease-in;
            animation: milestone-main-fade 1s ease-in;
  }
  .module-feed .cw_milestone-component .milestone-graphic.mg-accent {
    -webkit-animation: milestone-accent-fade 1s ease-in 1s;
            animation: milestone-accent-fade 1s ease-in 1s;
  }
}

.module-feed .cw_milestone-component .milestone-title .content-title {
  font-size: 8rem;
}

.module-feed .cw_milestone-component .milestone-title .content-subtitle {
  text-transform: uppercase;
  min-width: 50%;
  font-weight: bold;
  font-size: 1.75rem;
  text-align: center;
  letter-spacing: 0.5rem;
  margin-top: -0.5rem;
  color: rgb(var(--color-brand-main-dark));
  border-top: 2px solid rgb(var(--color-brand-accent));
  border-bottom: 2px solid rgb(var(--color-brand-accent));
}

.module-feed .cw_milestone-component .milestone-tcontent {
  margin: 2rem 0;
}

.module-feed .quote-component {
  max-width: 350px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .module-feed .quote-component {
    height: 100%;
  }
}

.module-feed .quote-component > .quote {
  height: 100%;
  position: relative;
}

.module-feed .quote-component .quote-inner {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  padding: 0 30px 50px 0;
}

.module-feed .quote-component .quote-bg {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  z-index: -1;
}

.module-feed .quote-component .quote-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
}

.module-feed .quote-component .quote-no-image {
  background-color: #dbe3e8;
}

.module-feed .quote-component .quote-con {
  border-right: 15px solid #fff;
  border-bottom: 15px solid #fff;
  padding: 30px;
  min-height: calc(100% - 50px);
  width: calc(100% - 30px);
}

.module-feed .quote-component .quote-con .quote {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.25;
}

.module-feed .quote-component .author-con {
  margin-top: 1.5rem;
  font-style: italic;
  line-height: 1.5;
}

.module-feed .quote-component .author-con .author {
  font-size: 1rem;
  font-weight: 500;
}

.module-feed .quote-component .author-con .author-title {
  font-size: 0.7rem;
  text-transform: uppercase;
}

.module-feed .hobby-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.module-feed .hobby-component .hobby {
  border-top: 2px solid rgb(var(--color-brand-main-dark));
  padding-top: 15px;
}

.module-feed .hobby-component .hobby + .hobby {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .module-feed .hobby-component .hobby {
    width: calc(50% - 30px);
    border-top: 0;
    padding-top: unset;
    border-left: 5px solid rgb(var(--color-brand-main-dark));
    padding-left: 20px;
    padding-bottom: 30px;
  }
  .module-feed .hobby-component .hobby + .hobby {
    margin-top: unset;
  }
  .module-feed .hobby-component .hobby:nth-child(n + 3) {
    margin-top: 20px;
  }
}

.module-feed .hobby-component .hobby h2 {
  color: rgb(var(--color-brand-main-dark));
  font-weight: 400;
}

.module-feed .hobby-component .hobby:nth-child(3n + 2) {
  border-top: 2px solid rgb(var(--color-brand-main));
}

@media (min-width: 768px) {
  .module-feed .hobby-component .hobby:nth-child(3n + 2) {
    border-top: 0;
    border-left: 5px solid rgb(var(--color-brand-main));
  }
}

.module-feed .hobby-component .hobby:nth-child(3n + 2) h2 {
  color: rgb(var(--color-brand-main));
}

.module-feed .hobby-component .hobby:nth-child(3n + 3) {
  border-top: 2px solid rgb(var(--color-brand-accent));
}

@media (min-width: 768px) {
  .module-feed .hobby-component .hobby:nth-child(3n + 3) {
    border-top: 0;
    border-left: 5px solid rgb(var(--color-brand-accent));
  }
}

.module-feed .hobby-component .hobby:nth-child(3n + 3) h2 {
  color: rgb(var(--color-brand-accent));
}

.module-feed .location-component .location-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .location-component .location-wrapper .map-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  height: 250px;
}

.module-feed .location-component .location-wrapper .notify-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  background-color: rgb(var(--color-brand-accent));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.module-feed .location-component .location-wrapper .notify-con .notify-content {
  text-align: center;
  color: #fff;
  margin: 0 10px;
}

.module-feed .location-component .location-wrapper .notify-con a {
  background-color: #fff;
  color: rgb(var(--color-brand-accent));
}

.module-feed .location-component .location-wrapper .notify-con a:hover {
  -webkit-box-shadow: 0 0 5px #fff;
          box-shadow: 0 0 5px #fff;
  border: none;
  cursor: pointer;
}

.module-feed .statistic-fact-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  /* Microsoft Edge Browser 15+ */
}

@supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
  .module-feed .statistic-fact-component {
    /* space-between is a fallback for browsers that have only partial support for space-evenly */
    /* Browser specific because otherwise, Safari displays incorrectly */
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.module-feed .statistic-fact-component .statistic-fact {
  text-align: center;
}

@media (min-width: 480px) {
  .module-feed .statistic-fact-component .statistic-fact {
    max-width: calc(50% - 30px);
  }
}

.module-feed .statistic-fact-component .statistic-fact-value {
  font-family: "Lato Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 3.75rem;
  font-weight: 300;
  color: rgb(var(--color-brand-accent));
}

.module-feed .statistic-fact-component .statistic-fact-title {
  font-size: 0.9375rem;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.module-feed .results-component .results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed .results-component .results .results-fact {
  text-align: center;
}

@media (min-width: 480px) {
  .module-feed .results-component .results .results-fact {
    max-width: calc(50% - 30px);
  }
}

.module-feed .results-component .results .results-fact-inner {
  text-align: center;
}

.module-feed .results-component .results .results-fact-value {
  font-family: "Lato Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 3.75rem;
  font-weight: 300;
  color: rgb(var(--color-brand-accent));
}

.module-feed .results-component .results .results-fact-title {
  font-size: 0.9375rem;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.module-feed .cw_case-study-component .case-study + .case-study {
  margin-top: 20px;
}

@media (min-width: 1280px) {
  .module-feed .cw_case-study-component .case-study {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
  .module-feed .cw_case-study-component .case-study + .engages_case_study {
    margin-top: 30px;
  }
}

.module-feed .cw_case-study-component .case-study header .content-subtitle {
  margin-top: 0;
}

.module-feed .cw_case-study-component .case-study-thumb-con {
  font-size: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .module-feed .cw_case-study-component .case-study-thumb-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 20px);
            flex: 0 0 calc(50% - 20px);
  }
}

@media (min-width: 1280px) {
  .module-feed .cw_case-study-component .case-study-thumb-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 30px);
            flex: 0 0 calc(50% - 30px);
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.module-feed .cw_case-study-component .case-study-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
}

@media (min-width: 768px) {
  .module-feed .cw_case-study-component .case-study-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}

.module-feed .cw_case-study-component .case-study-summary {
  margin: 5px 0;
}

.module-feed .cw_case-study-component .case-study-link-con:before {
  content: "ï£";
  font-family: "Font Awesome 5 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 1ch;
}

.module-feed .cw_company-info-component .company-info .company-info-logo {
  width: 100%;
}

.module-feed .cw_company-info-component .company-info .company-info-info {
  margin-top: 10px;
}

.module-feed .cw_company-info-component .company-info-logo-con {
  width: 100%;
}

.module-feed .company-info-component-full .company-info .company-info-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  padding: 0 20px;
}

.module-feed .company-info-component-full .company-info .company-info-logo {
  width: 100%;
  padding: 10px;
}

.module-feed .company-info-component-full .company-info .company-info-info {
  margin-top: 10px;
}

.module-feed .company-info-component-full .company-info-logo-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}

.module-feed .company-why .company-why-why {
  margin-top: 10px;
}

.module-feed .company-message .image {
  margin-top: 10px;
  width: 100%;
}

.module-feed .dc-container-editor .dc-listing-editor .prop-footer-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: .5rem 0;
}

.module-feed .dc-container-editor .actions.persistence-actions.top {
  display: none;
}

.module-feed .dc-container-editor .content-listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -0.5rem;
}

.module-feed .dc-container-editor .content-listing .content-renderer, .module-feed .dc-container-editor .content-listing .actions-card {
  -webkit-box-shadow: 0 2px 5px rgba(219, 227, 232, 0.25);
          box-shadow: 0 2px 5px rgba(219, 227, 232, 0.25);
  margin-top: .5rem;
  margin-bottom: .5rem;
  border-radius: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.4rem;
  color: rgb(var(--color-brand-main));
  background: #fff;
}

@media (min-width: 480px) {
  .module-feed .dc-container-editor .content-listing .content-renderer, .module-feed .dc-container-editor .content-listing .actions-card {
    max-width: 49%;
    margin-left: 1%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(2n-1), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(2n-1) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .module-feed .dc-container-editor .content-listing .content-renderer, .module-feed .dc-container-editor .content-listing .actions-card {
    max-width: 32%;
    margin-left: 1.33333%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(2n-1), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(2n-1) {
    margin-left: 1.33333%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(3n-2), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(3n-2) {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .module-feed .dc-container-editor .content-listing .content-renderer, .module-feed .dc-container-editor .content-listing .actions-card {
    max-width: 24%;
    margin-left: 1%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(2n-1), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(2n-1) {
    margin-left: 1%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(3n-2), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(3n-2) {
    margin-left: 1%;
  }
  .module-feed .dc-container-editor .content-listing .content-renderer:nth-of-type(4n-3), .module-feed .dc-container-editor .content-listing .actions-card:nth-of-type(4n-3) {
    margin-left: 0;
  }
}

.module-feed .dc-container-editor .content-listing .content-renderer {
  border: 1px solid rgb(var(--color-brand-main));
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(25% - .5rem);
          flex: 0 0 calc(25% - .5rem);
  margin: 0 0 .5rem .5rem !important;
}

@media (max-width: 767px) {
  .module-feed .dc-container-editor .content-listing .content-renderer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-actions {
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-top: -7px;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-actions .btn.delete {
  visibility: hidden;
  margin-right: -59px;
  margin-left: 0;
  padding-left: 0;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-actions .btn.delete:before {
  content: "ï€";
  color: #E2757E;
  font-size: 0.75rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  visibility: visible;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-actions .btn.edit {
  visibility: hidden;
  margin-right: 0;
  padding-right: 0;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-actions .btn.edit:after {
  content: "ï„";
  color: #cfcfcf;
  font-size: 0.75rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  visibility: visible;
  cursor: pointer;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  text-decoration: none;
  padding: 2rem;
  padding-top: 0;
}

.module-feed .dc-container-editor .content-listing .content-renderer .content-content .content-name {
  margin: 7% 0;
  text-align: center;
}

.module-feed .dc-container-editor .content-listing .add-new-content-button {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  margin: 0 0 .5rem .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.4;
  min-height: 180px;
  text-transform: uppercase;
  border: 1px solid #dbe3e8;
  border-radius: unset;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #36c190;
  color: #fff;
}

@media (max-width: 767px) {
  .module-feed .dc-container-editor .content-listing .add-new-content-button {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin: .5rem 0;
  }
}

.module-feed .dc-container-editor .content-listing .add-new-content-button:before {
  content: "ï§";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 2rem;
  display: block;
}

.module-feed .dc-container-editor .content-listing .add-content-selector {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(25% - .5rem);
          flex: 0 0 calc(25% - .5rem);
  margin: 0 0 .5rem .5rem;
}

@media (max-width: 767px) {
  .module-feed .dc-container-editor .content-listing .add-content-selector {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin: .5rem 0;
  }
}

.module-feed .dc-container-editor .content-listing .add-content-selector.active {
  position: -ms-page;
}

.module-feed .dc-container-editor .content-listing .add-content-selector.active ul.menu-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.module-feed .dc-container-editor .content-listing .add-content-selector.active:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: .75;
  z-index: 900;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > a.menu-item {
  padding: 6px 12px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  min-height: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgb(var(--color-brand-main));
  font-size: 1.1rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > a.menu-item:before {
  content: "ï‰";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 2rem;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 8px 15px 7px;
  line-height: 1;
  text-align: center;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
  font-family: "Roboto", Arial, Verdana, sans-serif;
  padding: 12px 24px;
  text-decoration: none;
  white-space: unset;
  margin: 0;
  width: 100%;
  opacity: .65;
}

@media (prefers-reduced-motion: reduce) {
  .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item {
    -webkit-transition: none;
    transition: none;
  }
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:hover, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:focus {
  text-decoration: none;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.disabled, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:not(:disabled):not(.disabled):active, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:not(:disabled):not(.disabled):active:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:hover {
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: #fff;
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:active, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.active,
.open > .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.dropdown-toggle {
  color: #fff;
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:active:hover, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:active:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:active.focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.active:hover, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.active:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.active.focus,
.open > .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.dropdown-toggle:hover,
.open > .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.dropdown-toggle:focus,
.open > .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.dropdown-toggle.focus {
  color: #fff;
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.disabled:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.disabled.focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:disabled:focus, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:disabled.focus {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item.disabled:hover, .module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:disabled:hover {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.module-feed .dc-container-editor .content-listing .add-content-selector > span.menu-item:before {
  content: "ï‰";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  margin-right: .5rem;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items {
  position: fixed;
  max-width: 500px;
  min-width: 500px;
  left: calc((100vw - 500px) / 2);
  top: 20vh;
  max-height: 70vh;
  overflow: auto;
  padding: 2rem 5rem;
  background: #f2f2f2;
  border: 1px solid #dbe3e8;
  border-radius: 10px;
  z-index: 1000;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items:before {
  content: 'Select Library Content';
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
  width: 100%;
  color: #dbe3e8;
  background-color: #4a4a4b;
  padding: .5rem;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items:after {
  content: "ï";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 10px;
  right: 20px;
  color: #cfcfcf;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items::-webkit-scrollbar {
  width: 10px;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items li.library-custom-content {
  margin: .5rem 0;
  width: 100%;
  text-align: center;
}

.module-feed .dc-container-editor .content-listing .add-content-selector ul.menu-items li.library-custom-content a {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #cfcfcf;
}

.module-feed .dc-listing-editor-dlg .titlebar td.windowbuttons {
  display: block !important;
}

.module-feed .document-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.module-feed .document-component .document {
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 480px) {
  .module-feed .document-component .document {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .module-feed .document-component .document {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}

.module-feed .document-component .document .document-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed .document-component .document .document-inner .tile {
  background: #fff;
  position: relative;
  border-radius: 4px;
  border: 1px solid #dbe3e8;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 49%;
          flex: 1 0 49%;
  text-decoration: none;
  padding-bottom: 25px;
  height: 125px;
  -webkit-box-shadow: 0 2px 5px rgba(74, 74, 75, 0.25);
          box-shadow: 0 2px 5px rgba(74, 74, 75, 0.25);
  margin-bottom: 15px;
  max-width: 100%;
}

.module-feed .document-component .document .document-inner .name {
  display: block;
  color: rgb(var(--color-brand-main));
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.25;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative;
  padding: 15px;
}

@media (min-width: 1280px) {
  .module-feed .document-component .document .document-inner .name {
    font-size: 1.375rem;
  }
}

.module-feed .document-component .document .document-inner .tags {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #cfcfcf;
  color: #fff;
  padding-left: 30px;
  font-size: 0.75rem;
  height: 30px;
  line-height: 30px;
}

.module-feed .document-component .document .document-inner .tags:before {
  content: "ï€¬";
  position: absolute;
  display: block;
  top: 50%;
  left: 8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  font-size: 0.75rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.module-feed .document-component .document .document-inner .tags:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 200;
  background: rgb(var(--color-brand-accent));
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  right: 5%;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}

.module-feed .document-component .document .document-inner .file-pdf .tags:after {
  content: '\f1c1';
}

.module-feed .document-component .document .document-inner .file-word .tags:after {
  content: '\f1c2';
}

.module-feed .document-component .document .document-inner .file-excel .tags:after {
  content: '\f1c3';
}

.module-feed .document-component .document .document-inner .file-powerpoint .tags:after {
  content: '\f1c4';
}

.module-feed .document-component .document .document-inner .file-archive .tags:after {
  content: '\f1c6';
}

.module-feed .document-component .document .document-inner .file-image .tags:after {
  content: '\f1c5';
}

.module-feed .document-component .document .document-inner .file-video .tags:after {
  content: '\f1c8';
}

.module-feed .document-component .document .document-inner .file-audio .tags:after {
  content: '\f1c7';
}

.module-feed .custom-content-container .custom-content-activity-logs {
  display: none;
}

.module-feed .customcontentcontainer2 .prop-body[data-membership-type-category='BuyerSide'] .ce-editor.document .prop.name {
  display: none;
}

.module-feed .customcontentcontainer2 .dc-listing-editor[data-membership-type-category='BuyerSide'] .content-renderer-document.trashed {
  display: none;
}

.module-feed .customcontentcontainer2 .document .content-listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-right: 0;
  margin-left: 0;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card {
  min-height: 170px;
  position: relative;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card .btn.add-new-content-button {
  padding: 0.75rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase !important;
  letter-spacing: 2px;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card .btn.add-new-content-button:hover, .module-feed .customcontentcontainer2 .document .content-listing .actions-card .btn.add-new-content-button:focus,
.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector > .menu-item:hover,
.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector > .menu-item:focus {
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
  color: #fff;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector ul.menu-items {
  top: 35vh;
  max-height: 40vh;
  border-radius: 0;
  min-width: 280px;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector > a.menu-item,
.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector > span.menu-item {
  padding: 0.75rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase !important;
  letter-spacing: 2px;
}

.module-feed .customcontentcontainer2 .document .content-listing .actions-card .add-content-selector + .add-new-content-button:not(:disabled):after {
  content: 'or';
  color: #cfcfcf;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document {
  position: relative;
  max-width: 100%;
  margin: 0.5rem 0 !important;
}

@media (min-width: 768px) {
  .module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(25% - 0.5rem);
            flex: 0 0 calc(25% - 0.5rem);
    max-width: 25%;
    margin: 0 0 .5rem .5rem !important;
  }
  .module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document:nth-child(4n + 1) {
    margin-left: 0 !important;
  }
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .content-actions {
  height: 33px;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .content-content {
  padding: 0;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .document-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .document-inner .tile {
  background: #fff;
  position: relative;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 49%;
          flex: 1 0 49%;
  text-decoration: none;
  padding-bottom: 30px;
  height: 140px;
  max-width: 100%;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .document-inner .name {
  display: block;
  color: rgb(var(--color-brand-main));
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.25;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative;
  padding: 15px 15px 30px;
}

@media (min-width: 1024px) {
  .module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .document-inner .name {
    font-size: 1.375rem;
  }
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .document-inner .tags {
  display: none;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .custom-content-activity-logs {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff;
  background: #cfcfcf;
  padding-left: 15px;
  padding-right: 30px;
  font-size: 0.8rem;
}

@media (min-width: 480px) {
  .module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .custom-content-activity-logs {
    font-size: 0.75rem;
  }
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .custom-content-activity-logs:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 200;
  background: rgb(var(--color-brand-accent));
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  right: 5%;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-pdf + .custom-content-activity-logs:after {
  content: '\f1c1';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-word + .custom-content-activity-logs:after {
  content: '\f1c2';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-excel + .custom-content-activity-logs:after {
  content: '\f1c3';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-powerpoint + .custom-content-activity-logs:after {
  content: '\f1c4';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-archive + .custom-content-activity-logs:after {
  content: '\f1c6';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-image + .custom-content-activity-logs:after {
  content: '\f1c5';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-video + .custom-content-activity-logs:after {
  content: '\f1c8';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document .file-audio + .custom-content-activity-logs:after {
  content: '\f1c7';
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed {
  border: 1px solid #cfcfcf;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner {
  text-decoration: line-through;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner .tile {
  color: rgb(var(--color-brand-main));
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner .tile .name {
  opacity: .5;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner .tags,
.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner .custom-content-activity-logs {
  background: #e9e9e9;
}

.module-feed .customcontentcontainer2 .document .content-listing .content-renderer-document.trashed .document-inner .tags:after {
  background: #cfcfcf;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs-input {
  visibility: hidden;
  height: 1px;
  width: 1px;
  margin: 0;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs-input + .analytics-event-list {
  display: none;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs-input:checked + .analytics-event-list:not(:empty) {
  display: block;
  overflow: auto;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 7px 15px;
}

@media (max-width: 767px) {
  .module-feed .customcontentcontainer2 .custom-content-activity-logs {
    padding: 5px 15px !important;
    max-height: 30px;
  }
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs label {
  cursor: pointer;
  font-size: 0.725rem;
  font-weight: 900;
  line-height: 1rem;
  letter-spacing: 2px;
  vertical-align: middle;
  text-transform: uppercase;
  max-height: 40px;
}

@media (max-width: 767px) {
  .module-feed .customcontentcontainer2 .custom-content-activity-logs label {
    display: block;
    position: relative;
    width: 140px;
    max-height: 30px;
    text-align: center;
  }
  .module-feed .customcontentcontainer2 .custom-content-activity-logs label .custom-content-activity-logs-label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    font-size: 0.625rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .module-feed .customcontentcontainer2 .custom-content-activity-logs label .activity-logs-icon {
    padding-left: 0;
  }
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs label .activity-logs-icon {
  position: relative;
  font-weight: 900;
  padding-left: 5px;
  line-height: 1rem;
  vertical-align: middle;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs label .activity-logs-icon:after {
  content: "ï„‡";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.25rem;
  color: #fff;
  font-style: normal;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list {
  padding: 0;
  list-style: none;
  width: calc(100% - 4px);
  max-width: 100vw;
  border: 1px solid #eee;
  margin: 0 auto;
  background-color: #fff;
  overflow: auto;
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 2px;
  left: 2px;
  max-height: 300px;
  -webkit-box-shadow: 0px 0px 10px rgba(219, 227, 232, 0.98);
          box-shadow: 0px 0px 10px rgba(219, 227, 232, 0.98);
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list > li {
  padding: 0.5ch 0;
  margin: 0 auto;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list > li.no-data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #02C27E;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list > li.bar {
  background-color: #dbe3e8;
  height: 10px;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event {
  overflow: hidden;
  padding: 0.5rem 0.6rem;
  color: #4a4a4b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background-color: #fff;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event + .analytics-event {
  border-top: 1px solid #dbe3e8;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event .action, .module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event .date, .module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event .by {
  font-size: 0.75rem;
  font-weight: normal;
  margin-left: 2px;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event .by {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.module-feed .customcontentcontainer2 .custom-content-activity-logs .analytics-event-list .analytics-event .by:before {
  content: 'By ';
}

@media (min-width: 768px) {
  .module-feed .dc-container-editor .document.dc-listing-editor .content-listing .add-content-selector:nth-child(4n + 1),
  .module-feed .dc-container-editor .document.dc-listing-editor .content-listing .add-new-content-button:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.module-feed .dc-container-editor .document.dc-listing-editor .content-listing .add-content-selector ul.menu-items {
  left: 15px;
  max-width: calc(100vw - 30px);
  min-width: calc(100vw - 30px);
  padding: 40px 15px 15px;
  border-radius: 0;
  border: 0 none;
  background-color: #fff;
}

@media (min-width: 768px) {
  .module-feed .dc-container-editor .document.dc-listing-editor .content-listing .add-content-selector ul.menu-items {
    max-width: 500px;
    min-width: 500px;
    left: calc((100vw - 500px)/ 2);
    padding: 40px 15px 15px;
    border-radius: 0;
    border: 0 none;
    background-color: #fff;
  }
}

.module-feed .docusign-inner .document {
  text-align: center;
}

.module-feed .docusign-inner .document .status {
  font-size: 0.75rem;
}

.module-feed .docusign-inner .document .envelope-lock-msg {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 7px;
  position: relative;
  background-color: #F6C9AB;
  border: 1px solid #F27E30;
  color: #F27E30;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .envelope-lock-msg:before {
  content: "ï±";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

.module-feed .docusign-inner .document .envelope-lock-msg a {
  color: #F27E30;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .envelope-lock-msg a:hover {
  text-decoration: none;
}

.module-feed .docusign-inner .document .docusign button {
  background-color: rgb(var(--color-brand-main));
  color: #fff;
  display: inline-block;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8125rem;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-family: Lato, "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .docusign button:hover {
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-main));
          box-shadow: 0 0 5px rgb(var(--color-brand-main));
}

.module-feed .docusign-inner .document .status-warning {
  position: relative;
  background-color: #F6C9AB;
  border: 1px solid #F27E30;
  color: #F27E30;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  padding-left: 7px;
}

.module-feed .docusign-inner .document .status-warning:before {
  display: none;
}

.module-feed .docusign-inner .document .status-warning a {
  color: #F27E30;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .status-warning a:hover {
  text-decoration: none;
}

.module-feed .docusign-inner .document .status-info {
  position: relative;
  background-color: #BDE5F8;
  border: 1px solid #1C74BA;
  color: #1C74BA;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .status-info:before {
  content: "ïš";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

.module-feed .docusign-inner .document .status-info a {
  color: #1C74BA;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .status-info a:hover {
  text-decoration: none;
}

.module-feed .docusign-inner .document .status-error {
  position: relative;
  background-color: #FFBABA;
  border: 1px solid #E53140;
  color: #E53140;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .status-error:before {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

.module-feed .docusign-inner .document .status-error a {
  color: #E53140;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .docusign-inner .document .status-error a:hover {
  text-decoration: none;
}

.module-feed .insight-component .insight-link {
  word-break: break-all;
}

.module-feed .insight-component .insight .image {
  margin-top: 10px;
  width: 100%;
}

.module-feed .cw_loi-component {
  background-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main-reverse));
}

@media (min-width: 768px) {
  .module-feed .loi-component .loi .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.module-feed .loi-component .loi-link {
  text-align: center;
}

.module-feed .cw_nda-component {
  background-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main-reverse));
}

@media (min-width: 768px) {
  .module-feed .nda-component .nda .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.module-feed .nda-component .nda-link {
  text-align: center;
}

.module-feed .cw_prepare_service-component {
  background-color: rgb(var(--color-brand-main));
  color: rgb(var(--color-brand-main-reverse));
}

@media (min-width: 768px) {
  .module-feed .prepare_service-component .prepare_service .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.module-feed .prepare_service-component .prepare_service-link {
  text-align: center;
}

.module-feed .video-demo-video-intro + .video-demo-video,
.module-feed .video-demo-video-intro + .video-demo-video-followup {
  margin-top: 1rem;
}

.module-feed .video-demo-video + .video-demo-video-followup {
  margin-top: 1rem;
}

.module-feed .next-steps-component .next-steps-summary {
  margin: 10px 0;
}

.module-feed .next-steps-component .next-steps-steps-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border: 1px solid #cfcfcf;
  -webkit-box-shadow: 0 2px 5px rgba(74, 74, 75, 0.25);
          box-shadow: 0 2px 5px rgba(74, 74, 75, 0.25);
  padding: 15px;
}

.module-feed .next-steps-component .next-steps-steps-list li {
  margin: 10px 35px;
}

.module-feed .next-steps-component .next-steps-steps-list li:first-child {
  margin-top: auto;
}

.module-feed .next-steps-component .next-steps-steps-list li:last-child {
  margin-bottom: auto;
}

@media (min-width: 768px) {
  .module-feed .next-steps-component .next-steps-steps-list li {
    -ms-flex-preferred-size: calc(50% - 50px);
        flex-basis: calc(50% - 50px);
    margin: auto 0 auto 50px;
  }
  .module-feed .next-steps-component .next-steps-steps-list li p {
    margin: 8px 0;
  }
  .module-feed .next-steps-component .next-steps-steps-list li:before {
    left: -40px;
  }
}

.module-feed .ce-editor.next-steps .prop-next_steps_html .richlist > .element > .list-renderer {
  border: 1px solid #cfcfcf;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: .25rem;
}

.module-feed .ce-editor.next-steps .prop-next_steps_html .richlist > .element > .list-renderer > .list-item-renderer {
  display: inline-block;
}

.module-feed .ce-editor.next-steps .prop-next_steps_html .richlist > .element > .list-renderer > .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .window.dialog.next_steps_html-dlg .titlebar {
  display: block;
}

.module-feed .window.dialog.next_steps_html-dlg .next_steps_html {
  margin-top: 1em;
}

.module-feed .our-process .our-process-image {
  max-width: 100%;
  height: auto;
}

.module-feed .our-process .our-process-image-con {
  margin-top: 10px;
  text-align: center;
}

.module-feed .our-process .our-process-summary {
  padding: 20px;
}

.module-feed .product-service-summary-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  /* Microsoft Edge Browser 15+ */
}

.module-feed .product-service-summary-component .product-service-summary {
  min-width: 280px;
  width: 100%;
  max-width: 420px;
  margin: 5px 0;
}

@media (min-width: 480px) {
  .module-feed .product-service-summary-component .product-service-summary {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1024px) {
  .module-feed .product-service-summary-component .product-service-summary {
    width: calc(33% - 20px);
  }
}

@supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
  .module-feed .product-service-summary-component {
    /* space-between is a fallback for browsers that have only partial support for space-evenly */
    /* Browser specific because otherwise, Safari displays incorrectly */
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  @media only screen and (min-width: 480px) and (max-width: 1023px) {
    .module-feed .product-service-summary-component .product-service-summary {
      min-width: auto;
      width: calc(50% - 10px);
      margin-left: 20px;
    }
    .module-feed .product-service-summary-component .product-service-summary:nth-child(2n+1) {
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 1024px) {
    .module-feed .product-service-summary-component .product-service-summary {
      width: calc(33% - 20px);
      margin-left: 30px;
    }
    .module-feed .product-service-summary-component .product-service-summary:nth-child(3n+1) {
      margin-left: 0;
    }
  }
}

.module-feed .product-service-summary-component .product-service-summary-inner {
  position: relative;
}

.module-feed .product-service-summary-component .product-service-image {
  display: block;
  width: 100%;
  line-height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #4a4a4b;
}

.module-feed .product-service-summary-component .product-service-image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 69.45946%;
  opacity: 0.25;
  background-color: #4a4a4b;
}

.module-feed .product-service-summary-component .product-service-image a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-decoration: none;
}

.module-feed .product-service-summary-component .product-service-image a .reader-only {
  display: block;
  height: 1px;
  width: 1px;
  color: transparent;
  overflow: hidden;
}

.module-feed .product-service-summary-component .product-service-summary-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: height 1s;
  transition: height 1s;
}

.module-feed .product-service-summary-component .product-service-summary-content .product-service-name {
  font-size: 1.375rem;
  font-weight: 700;
  color: rgb(var(--color-brand-main-reverse));
}

@media (min-width: 1280px) {
  .module-feed .product-service-summary-component .product-service-summary-content .product-service-name {
    font-size: 1.875rem;
  }
}

.module-feed .product-service-summary-component .product-service-summary-content .rte {
  height: 1px;
  text-align: center;
  overflow: hidden;
  color: white;
  width: 100%;
  -webkit-transition: height ease 0.5s;
  transition: height ease 0.5s;
  padding: 0 20px;
}

.module-feed .product-service-summary-component .product-service-summary:hover {
  cursor: pointer;
}

.module-feed .product-service-summary-component .product-service-summary:hover .product-service-name content-subtitle {
  margin: 0 0 0.83rem 0;
}

.module-feed .product-service-summary-component .product-service-summary:hover .product-service-image:before {
  opacity: 0.75;
  background-color: rgb(var(--color-brand-main));
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}

.module-feed .product-service-summary-component .product-service-summary:hover .product-service-summary-content {
  -webkit-transition: height 1s;
  transition: height 1s;
}

.module-feed .product-service-summary-component .product-service-summary:hover .product-service-summary-content .rte {
  height: 14ch;
  -webkit-transition: height ease 0.5s;
  transition: height ease 0.5s;
}

.module-feed .reference-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

@media (min-width: 480px) {
  .module-feed .reference-component {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}

.module-feed .reference-component .reference {
  padding: 15px;
  width: 100%;
  color: #343940;
  background-color: #fff;
  margin: 1rem 0;
  -webkit-box-shadow: 0 0 5px rgba(219, 227, 232, 0.44);
          box-shadow: 0 0 5px rgba(219, 227, 232, 0.44);
}

@media (min-width: 480px) {
  .module-feed .reference-component .reference {
    max-width: calc(50% - 1rem);
    margin: 0 0 1rem 1rem;
  }
  .module-feed .reference-component .reference:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .module-feed .reference-component .reference {
    max-width: calc(25% - 1rem);
  }
  .module-feed .reference-component .reference:nth-child(2n + 1) {
    margin-left: 1rem;
  }
  .module-feed .reference-component .reference:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.module-feed .reference-component .reference a {
  color: rgb(var(--color-brand-main));
  text-decoration: none;
}

.module-feed .reference-component .reference .name {
  font-size: 1.4375rem;
  font-weight: 400;
}

.module-feed .reference-component .reference .reference-inner > div:not(.name):not(.title) {
  font-size: 0.8125rem;
}

.module-feed .reference-component .reference .label,
.module-feed .reference-component .reference .title {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1.5px;
  color: #b6b6b6;
}

.module-feed .reference-component .reference .label {
  margin-top: 10px;
}

.module-feed .reference-component .reference .contact-info,
.module-feed .reference-component .reference .speakto,
.module-feed .reference-component .reference .notes {
  margin: 10px 0;
}

.module-feed .reference-component .reference .contact-actions {
  margin-top: 8px;
}

.module-feed .reference-component .reference .new-line {
  display: block;
}

.module-feed .reference-component .reference .email a,
.module-feed .reference-component .reference .website a,
.module-feed .reference-component .reference .phone a {
  max-width: 80ch;
  display: block;
  height: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial {
  padding: 0 20px;
  line-height: 1.5;
  font-size: 1rem;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial + .testimonial {
  margin-top: 20px;
}

@media (min-width: 1280px) {
  .module-feed .cw_testimonial-component .testimonial-component .testimonial {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    padding: 0 30px;
    font-size: 1.125rem;
  }
  .module-feed .cw_testimonial-component .testimonial-component .testimonial + .testimonial {
    margin-top: 30px;
  }
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-inner {
  position: relative;
  margin: 0;
  font-style: normal;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-inner svg {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: auto;
  opacity: .3;
  -webkit-transform: translate(-20px, -3px);
          transform: translate(-20px, -3px);
  z-index: 1;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-inner svg ~ svg {
  -webkit-transform: translate(-51px, -1px) rotateY(180deg) rotateX(180deg);
          transform: translate(-51px, -1px) rotateY(180deg) rotateX(180deg);
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-testimonial {
  font-style: italic;
  padding: 10px 0 0 10px;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-credit {
  line-height: 1.2;
  margin-top: 10px;
  padding: 0 0 0 10px;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-credit .name {
  display: block;
  font-weight: bold;
}

.module-feed .cw_testimonial-component .testimonial-component .testimonial .testimonial-credit .company {
  display: block;
}

.module-feed .cw_testimonial-component.cw_brand-main-gradient .testimonial-component .testimonial .testimonial-inner svg, .module-feed .cw_testimonial-component.cw_brand-main .testimonial-component .testimonial .testimonial-inner svg, .module-feed .cw_testimonial-component.cw_brand-accent .testimonial-component .testimonial .testimonial-inner svg, .module-feed .cw_testimonial-component.cw_brand-accent-gradient .testimonial-component .testimonial .testimonial-inner svg {
  -webkit-filter: invert(0%);
          filter: invert(0%);
}

.module-feed .timeline-component {
  overflow: hidden;
}

.module-feed .timeline-component .timeline-inner .timeline-con {
  padding: 0 0 25px;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul {
  background: #fff;
  padding: 0;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul:after {
  content: "";
  display: block;
  width: 6px;
  border-radius: 3px;
  background: rgb(var(--color-brand-main));
  position: absolute;
  top: 50px;
  bottom: 105px;
  left: 40px;
}

@media screen and (min-width: 601px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul:after {
    left: calc(50% - 3px);
  }
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media screen and (max-width: 600px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li {
    margin-left: 20px;
  }
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  -webkit-transition: background .25s ease-in-out;
  transition: background .25s ease-in-out;
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li:last-child {
  padding-bottom: 0;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li:last-child::before {
  bottom: 25px;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: rgb(var(--color-brand-main));
  color: #fff;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
  background-image: -webkit-gradient(linear, left bottom, left top, from(transparent), color-stop(15%, rgb(var(--color-brand-main))));
  background-image: linear-gradient(to top, transparent, rgb(var(--color-brand-main)) 15%);
}

@media screen and (max-width: 900px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li div {
    width: 250px;
  }
}

@media screen and (max-width: 600px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li div {
    width: calc(100vw - 180px);
  }
}

@media screen and (max-width: 480px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li div {
    width: calc(100vw - 150px);
  }
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .event {
  /* reset li styles for the rte list items*/
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .event ol {
  list-style-type: decimal;
  margin: 1rem 0;
  padding: 0 0 0 40px;
  counter-reset: unset;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .event ol li {
  margin: 0;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .event ol li:before {
  content: none;
  margin: 0;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(odd) div {
  left: 45px;
  /* turn off the animation for now
            transform: translate3d(200px, 0, 0);
            */
}

@media screen and (max-width: 600px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(even) div {
    left: 45px;
  }
}

@media screen and (min-width: 601px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(even) div {
    left: -288px;
  }
}

@media screen and (min-width: 901px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(even) div {
    left: -439px;
    /* turn off the animation for now
              transform: translate3d(-200px, 0, 0);
              */
  }
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li::after {
  content: '';
  position: absolute;
  bottom: 25px;
  width: 0;
  height: 0;
  border-style: solid;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li:last-child {
  padding-bottom: 0;
}

@media screen and (min-width: 601px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(even):after {
    border-color: transparent transparent transparent rgb(var(--color-brand-main));
    border-width: 15px 0 15px 18px;
    left: -42px;
  }
}

@media screen and (max-width: 600px) {
  .module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(even):after {
    border-color: transparent rgb(var(--color-brand-main)) transparent transparent;
    border-width: 15px 18px 15px 0;
    right: -42px;
  }
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li:nth-child(odd):after {
  border-color: transparent rgb(var(--color-brand-main)) transparent transparent;
  border-width: 15px 18px 15px 0;
  right: -42px;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li::before {
  background: rgb(var(--color-brand-main));
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .event {
  -webkit-transform: none;
          transform: none;
  visibility: visible;
  opacity: 1;
  max-height: 225px;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more {
  cursor: pointer;
  padding-bottom: 40px;
  bottom: -25px;
  -webkit-box-shadow: 0 3px 3px #dbe3e8;
          box-shadow: 0 3px 3px #dbe3e8;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more::after {
  content: "ï¸";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more:not(.show) {
  overflow: hidden;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more:not(.show):before {
  content: '';
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  height: 5rem;
  width: 100%;
  border: 0 none;
  border-color: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(80%, rgb(var(--color-brand-main))), to(rgb(var(--color-brand-main))));
  background: linear-gradient(180deg, transparent 0%, rgb(var(--color-brand-main)) 80%, rgb(var(--color-brand-main)) 100%);
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more.show {
  /* changed from max-height:unset; because IE doesn't honor it */
  max-height: none;
  cursor: pointer;
}

.module-feed .timeline-component .timeline-inner .timeline-con > ul > li .more.show::after {
  content: "ï·";
  font-family: "Font Awesome 5 Pro";
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(65%, #fff));
  background: linear-gradient(#fff 0%, #fff 65%);
}

.module-feed .ce-editor.timeline .richlist > .element > .list-renderer {
  border: 1px solid #cfcfcf;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: .25rem;
}

.module-feed .ce-editor.timeline .richlist > .element > .list-renderer > .list-item-renderer {
  display: inline-block;
}

.module-feed .ce-editor.timeline .richlist > .element > .list-renderer > .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .truck-component .truck .truck-con {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 30px 10px;
}

@media (min-width: 1024px) {
  .module-feed .truck-component .truck .truck-con {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

@media (min-width: 1024px) {
  .module-feed .truck-component .truck .truck-con .image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}

.module-feed .truck-component .truck .truck-con .truck-content {
  padding: 20px 0;
}

@media (min-width: 1024px) {
  .module-feed .truck-component .truck .truck-con .truck-content {
    padding: 0 10px 0 30px;
  }
}

.module-feed .product-service-full-component .product-service-full .product-service-full-link {
  word-break: break-all;
}

.module-feed .product-service-full-component .product-service-full .image {
  margin-top: 10px;
  width: 100%;
}

.module-feed .product-service-full-alt-component .product_service_full:nth-child(even) .row {
  margin: 30px -15px 0;
  padding: 30px 0 0;
  border-top: 1px solid #dbe3e8;
}

@media only screen and (min-width: 576px) {
  .module-feed .product-service-full-alt-component .product_service_full:nth-child(even) .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-flow: row-reverse wrap;
            flex-flow: row-reverse wrap;
  }
}

.module-feed .product-service-full-alt-component .product_service_full:nth-child(even):not(:last-child) .row {
  border-bottom: 1px solid #dbe3e8;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.module-feed .onboarding-next-steps-component .onboarding-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .onboarding-next-steps-component .onboarding-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.module-feed .onboarding-next-steps-component .onboarding-con .responsive-video-standard {
  min-width: 300px;
}

.module-feed .onboarding-next-steps-component .onboarding-con .responsive-video-standard video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed .onboarding-next-steps-component .onboarding-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

.module-feed .onboarding-next-steps-component .onboarding-content .title {
  font-size: 1.5rem;
  color: rgb(var(--color-brand-main));
  margin: 0;
}

@media (min-width: 768px) {
  .module-feed .onboarding-next-steps-component .half {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.module-feed .success-statement-component .success-summary {
  padding: 20px;
}

.module-feed .share-external {
  min-height: 325px;
}

.module-feed .share-external .note {
  display: none;
}

.module-feed .share-external .note p {
  padding: 10px 10px 30px;
  font-style: italic;
}

.module-feed .share-external .shared-external-con {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.module-feed .share-external .share-external-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 600px) {
  .module-feed .share-external .share-external-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

.module-feed .share-external .share-external-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  margin: 0 10px;
}

@media screen and (max-width: 600px) {
  .module-feed .share-external .share-external-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

.module-feed .share-external .share-external-content .title {
  margin-top: 0;
  line-height: 1.1;
}

.module-feed .loading {
  color: #000;
  text-align: center;
  font-size: 2rem;
}

.module-feed .loading:after {
  content: ' .';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  40% {
    color: #000;
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  60% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  80%, 100% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  40% {
    color: #000;
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  60% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
  80%, 100% {
    text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
  }
}

.module-feed .battle-cards-component .battle-cards {
  width: 100%;
  margin: 30px auto;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.module-feed .battle-cards-component .table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #dedede;
}

.module-feed .battle-cards-component .table-header .table-title {
  -webkit-box-flex: 10;
      -ms-flex: 10;
          flex: 10;
  border-right: 1px solid #dedede;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
  text-align: center;
}

.module-feed .battle-cards-component .table-header .table-title img {
  width: 75%;
}

.module-feed .battle-cards-component .table-header .table-title h2 {
  font-weight: 500;
  margin: 0;
}

.module-feed .battle-cards-component .table-header .table-title:last-child {
  border-right: none;
}

.module-feed .battle-cards-component .table-header .table-title.first {
  -webkit-box-flex: 8;
      -ms-flex: 8;
          flex: 8;
}

.module-feed .battle-cards-component .table-row {
  border-bottom: 1px solid #dedede;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-feed .battle-cards-component .table-row:nth-child(even) {
  background: #F4F6FC;
}

.module-feed .battle-cards-component .table-row:last-child {
  border-bottom: none;
}

.module-feed .battle-cards-component .table-row .column {
  -webkit-box-flex: 10;
      -ms-flex: 10;
          flex: 10;
  padding: 20px 0;
  border-right: 1px solid #dedede;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.module-feed .battle-cards-component .table-row .column .factor {
  font-weight: 700;
}

.module-feed .battle-cards-component .table-row .column p {
  margin: 0;
  font-weight: 500;
  padding: 0 10px;
}

.module-feed .battle-cards-component .table-row .column:last-child {
  border-right: none;
}

.module-feed .battle-cards-component .table-row .column.first {
  -webkit-box-flex: 8;
      -ms-flex: 8;
          flex: 8;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .module-feed .battle-cards-component .battle-cards {
    width: 90%;
  }
  .module-feed .battle-cards-component .battle-cards .table-title {
    font-size: 14px;
  }
  .module-feed .battle-cards-component .battle-cards .column {
    padding: 15px 0;
  }
}

@media only screen and (max-width: 600px) {
  .module-feed .battle-cards-component .battle-cards {
    width: 100%;
  }
  .module-feed .battle-cards-component .table-row .column p {
    padding: 0 3px;
    font-size: 9px;
  }
}

.module-feed .masscare-roi .col-xs-12 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 48em) {
  .module-feed .masscare-roi .container {
    width: 46rem;
  }
  .module-feed .masscare-roi .col-sm-12 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 62em) {
  .module-feed .masscare-roi .container {
    width: 61rem;
  }
}

@media only screen and (min-width: 75em) {
  .module-feed .masscare-roi .container {
    width: 71rem;
  }
  .module-feed .masscare-roi .col-lg-10 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .module-feed .masscare-roi .col-lg-offset-1 {
    margin-left: 8.333%;
  }
}

.module-feed .masscare-roi ol, .module-feed .masscare-roi ul {
  list-style: none;
}

.module-feed .masscare-roi .calculator {
  background: #fff;
}

.module-feed .masscare-roi table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.module-feed .masscare-roi table td {
  border: 1px solid #333;
  padding: 5px;
}

.module-feed .masscare-roi table td:nth-child(1) {
  text-align: left;
}

.module-feed .masscare-roi table th {
  font-weight: bold;
}

.module-feed .masscare-roi select {
  -moz-text-align-last: center;
       text-align-last: center;
}

.module-feed .masscare-roi input {
  display: block;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  font-size: 35px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  padding: 10px;
  width: 200px;
  color: #111;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
}

.module-feed .masscare-roi input:hover {
  background: #f9f9f9;
}

.module-feed .masscare-roi input:focus {
  background: #f9f9f9;
  border: 2px solid rgb(var(--color-brand-main));
}

.module-feed .masscare-roi label {
  display: block;
  padding: 2px;
}

.module-feed .masscare-roi h1 {
  text-align: center;
  padding: 2px;
}

.module-feed .masscare-roi .class {
  padding: 10px;
}

.module-feed .masscare-roi .step {
  padding: 20px;
  -webkit-box-shadow: 0 0 3px 1px #ccc;
          box-shadow: 0 0 3px 1px #ccc;
  padding-left: 10%;
  padding-right: 10%;
}

.module-feed .masscare-roi .step h1 {
  display: none;
}

.module-feed .masscare-roi .step label {
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 35px;
}

.module-feed .masscare-roi button {
  margin: auto;
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: block;
  border-radius: 2rem;
  background: rgb(var(--color-brand-accent));
  color: #fff;
  min-width: 220px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.module-feed .masscare-roi button:hover {
  background: rgb(var(--color-brand-accent));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-accent));
          box-shadow: 0 0 5px rgb(var(--color-brand-accent));
}

.module-feed .masscare-roi .result {
  font-size: 37px !important;
  font-weight: bold;
  color: rgb(var(--color-brand-main));
  background: #ddd;
  width: 250px;
  margin: auto;
  padding: 10px;
  border-radius: 4px;
}

.module-feed .masscare-roi #view1 table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.module-feed .masscare-roi #view1 table td {
  border: 1px solid #aaa;
}

.module-feed .masscare-roi #view1 table th {
  border: 1px solid #aaa;
  padding: 5px;
  background: #f0f0f0;
}

.module-feed .masscare-roi #view1 table td:nth-child(1) {
  font-size: 20px;
  padding-left: 5%;
}

.module-feed .masscare-roi #view1 table label {
  color: rgb(var(--color-brand-main));
  margin: 0;
}

.module-feed .masscare-roi #view1 table th:nth-child(1) {
  font-weight: bold;
  font-size: 25px;
  padding-left: 5%;
  text-align: left;
  background: #f0f0f0;
}

.module-feed .masscare-roi #view1 table th label {
  color: rgb(var(--color-brand-main));
  margin: 0;
  background: #f0f0f0;
}

.module-feed .masscare-roi #view3 table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.module-feed .masscare-roi #view3 table td {
  border: 1px solid #aaa;
}

.module-feed .masscare-roi #view3 table th {
  border: 1px solid #aaa;
  padding: 5px;
  background: #f0f0f0;
}

.module-feed .masscare-roi #view3 table td:nth-child(1) {
  font-size: 20px;
  padding-left: 5%;
}

.module-feed .masscare-roi #view3 table label {
  color: rgb(var(--color-brand-main));
  margin: 0;
}

.module-feed .masscare-roi #view3 table th:nth-child(1) {
  font-weight: bold;
  font-size: 25px;
  padding-left: 5%;
  text-align: left;
  background: #f0f0f0;
}

.module-feed .masscare-roi #view3 table th label {
  color: rgb(var(--color-brand-main));
  margin: 0;
  background: #f0f0f0;
}

.module-feed .module-image-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  /* Microsoft Edge Browser 15+ */
}

.module-feed .module-image-gallery .lightbox-gallery img {
  max-height: 200px;
}

@supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
  .module-feed .module-image-gallery {
    /* space-between is a fallback for browsers that have only partial support for space-evenly */
    /* Browser specific because otherwise, Safari displays incorrectly */
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.module-feed .file-upload-data-capture .value-wrapper {
  background-color: #dbe3e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem;
}

.module-feed .file-upload-data-capture .value-wrapper .file-name {
  margin-bottom: 15px;
}

.module-feed .file-upload-data-capture .value-wrapper .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper {
  position: relative;
  margin-right: 5px;
  overflow: hidden;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;
  padding: 8px 15px 7px;
  line-height: 1;
  text-align: center;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: rgb(var(--color-brand-main));
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
  font-family: "Roboto", Arial, Verdana, sans-serif;
}

@media (prefers-reduced-motion: reduce) {
  .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper {
    -webkit-transition: none;
    transition: none;
  }
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:hover, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:focus {
  text-decoration: none;
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.disabled, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:not(:disabled):not(.disabled):active, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:not(:disabled):not(.disabled):active:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:hover {
  color: #fff;
  background-color: rgb(var(--color-brand-main));
  border-color: rgb(var(--color-brand-main));
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:active, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.active,
.open > .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.dropdown-toggle {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:active:hover, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:active:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:active.focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.active:hover, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.active:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.active.focus,
.open > .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.dropdown-toggle:hover,
.open > .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.dropdown-toggle:focus,
.open > .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.dropdown-toggle.focus {
  color: rgb(var(--color-brand-main));
  background-color: rgb(var(--color-brand-main-dark));
  border-color: rgb(var(--color-brand-main-dark));
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.disabled:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.disabled.focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:disabled:focus, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:disabled.focus {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper.disabled:hover, .module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper:disabled:hover {
  background-color: #fff;
  border-color: rgb(var(--color-brand-main));
}

.module-feed .file-upload-data-capture .value-wrapper .actions .upload-field-wrapper input[type="file"] {
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}

.module-feed .cognito-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.module-feed .cognito-form--seller-side--no-results {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.module-feed .cognito-form--seller-side--no-results .cognito-form__instructions {
  width: 100%;
}

.module-feed .cognito-form--seller-side--has-results .cognito-form__instructions {
  width: 50%;
}

.module-feed .cognito-form--seller-side--has-results .cognito-form__mode {
  width: 50%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.module-feed .cognito-form--buyer-side.cognito-form--submit-once {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.module-feed .cognito-form--buyer-side.cognito-form--inline.cognito-form--results {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media (max-width: 1023px) {
  .module-feed .cognito-form--buyer-side.cognito-form--inline.cognito-form--results {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.module-feed .cognito-form--buyer-side.cognito-form--inline.cognito-form--results .cognito-form--inline__edit {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (min-width: 768px) {
  .module-feed .cognito-form--buyer-side.cognito-form--inline.cognito-form--results .cognito-form--inline__edit {
    width: 25% !important;
  }
}

.module-feed .cognito-form--buyer-side.cognito-form--inline.cognito-form--results .cognito-form--inline__instructions {
  width: 75% !important;
}

.module-feed .cognito-form__instructions {
  width: 75%;
}

.module-feed .cognito-form__mode {
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.module-feed .cognito-form__edit {
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.module-feed .cognito-form--inline, .module-feed .cognito-form--inline-expand {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.module-feed .cognito-form--inline__instructions, .module-feed .cognito-form--inline-expand__instructions {
  width: 100% !important;
}

.module-feed .cognito-form--inline__mode, .module-feed .cognito-form--inline-expand__mode {
  width: 100% !important;
  display: block !important;
}

.module-feed .cognito-form--inline__edit, .module-feed .cognito-form--inline-expand__edit {
  width: 100% !important;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.module-feed .cognito-form--inline .cognito-mode--inline-expand, .module-feed .cognito-form--inline-expand .cognito-mode--inline-expand {
  margin-top: 1rem;
}

.module-feed .cognito-form--inline .cognito-mode--inline-expand__call-to-action, .module-feed .cognito-form--inline-expand .cognito-mode--inline-expand__call-to-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.module-feed .cognito-form--inline .cognito-mode--inline-expand__call-to-action:after, .module-feed .cognito-form--inline-expand .cognito-mode--inline-expand__call-to-action:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.module-feed .cognito-form--inline .cognito-mode--inline-expand__call-to-action--collapsed:after, .module-feed .cognito-form--inline-expand .cognito-mode--inline-expand__call-to-action--collapsed:after {
  content: "ï„…";
}

.module-feed .cognito-form--inline .cognito-mode--inline-expand__call-to-action--expanded:after, .module-feed .cognito-form--inline-expand .cognito-mode--inline-expand__call-to-action--expanded:after {
  content: "ï„‡";
}

.module-feed .cognito-form .cognito-mode__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module-feed .cognito-form .cognito-mode__actions .cognito-mode__actions__action + .cognito-mode__actions__action {
  margin-left: 5px;
}

.module-feed .cognito-form .cognito-mode--full-page__call-to-action {
  text-decoration: none;
}

.module-feed .cognito-form .cognito-mode--modal__modal {
  position: fixed;
  z-index: 1000;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  padding: 0 1rem;
  top: 10vh;
  left: 2vw;
  min-width: 96vw;
  max-width: 96vw;
  max-height: 80vh;
  min-height: 60vh;
}

@media (min-width: 1280px) {
  .module-feed .cognito-form .cognito-mode--modal__modal {
    min-width: 1080px;
    max-width: 1080px;
    left: calc((100vw - 1080px) / 2);
  }
}

.module-feed .cognito-form .cognito-mode--modal__modal .pf-modal-header {
  padding: 1rem 0;
}

.module-feed .cognito-form .cognito-mode--modal__modal .pf-modal-header .pf-modal-title {
  font-weight: 700;
}

.module-feed .cognito-form .cognito-mode--modal__modal .pf-modal-content {
  padding: 1rem;
  max-height: 75vh;
  min-height: 55vh;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .module-feed .cognito-form .cognito-mode--modal__modal .pf-modal-content {
    padding: 1rem 5rem;
  }
}

.module-feed .cognito-form .cognito-mode--modal__modal__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-bottom: 0 !important;
}

.module-feed .cognito-form .cognito-mode--modal__modal__header--has-title {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-feed .cognito-form .cognito-mode--modal__modal__header--has-title__title {
  font-weight: 600;
  font-size: 1.5rem;
}

.module-feed .cognito-form .cognito-mode--modal__modal__close {
  border: none;
  padding: .25rem .5rem;
  background-color: transparent;
  color: #E2757E;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.module-feed .cognito-form .cognito-mode--modal__modal__close:hover {
  background-color: #E2757E;
  color: #fff;
}

.module-feed .cognito-form .cognito-mode--modal__modal-backdrop {
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: 900;
}

.module-feed .cognito-form .cognito-mode button.btn, .module-feed .cognito-form .cognito-mode a.btn {
  background-color: rgb(var(--color-brand-main));
  color: #fff;
  display: inline-block;
  white-space: unset;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8125rem;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-family: Lato, "Open Sans", Arial, Verdana, sans-serif;
}

.module-feed .cognito-form .cognito-mode button.btn:hover, .module-feed .cognito-form .cognito-mode a.btn:hover {
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-main));
          box-shadow: 0 0 5px rgb(var(--color-brand-main));
}

.module-feed .cw_about-job-component .row {
  margin: 20px 0;
}

.module-feed .cw_about-job-component .title {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  color: rgb(var(--color-brand-main));
  font-size: 1.75rem;
}

.module-feed .cw_about-job-component .title h1 {
  margin: 10px 0 0;
}

.module-feed .cw_about-job-component .logo-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .module-feed .cw_about-job-component .logo-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
  }
}

.module-feed .cw_about-job-component .logo-con img {
  padding: 10px;
}

.module-feed .cw_about-job-component .story {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .cw_about-job-component .split {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
  }
}

.module-feed .cw_about-job-component .values-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .cw_about-job-component .values-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.module-feed .cw_about-job-component .values-con .responsive-video-standard {
  min-width: 300px;
}

.module-feed .cw_about-job-component .values-con .responsive-video-standard video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed .cw_about-job-component .values {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .cw_about-job-component .half {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.module-feed .cw_about-job-component .about-role {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

.module-feed .cw_about-job-component .about-manager {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

.module-feed .cw_about-job-component .faq {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

.module-feed .cw_about-job-component .faq .faq-link .link {
  background-color: rgb(var(--color-brand-main));
}

.module-feed .cw_about-job-component .faq .faq-link .link:hover, .module-feed .cw_about-job-component .faq .faq-link .link:focus {
  background-color: rgb(var(--color-brand-main));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-main));
          box-shadow: 0 0 5px rgb(var(--color-brand-main));
  border: none;
}

@media (min-width: 768px) {
  .module-feed .cw_future-fit-component .future-fit {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

.module-feed .cw_future-fit-component img {
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .cw_future-fit-component img {
    max-width: 300px;
  }
}

.module-feed .cw_future-fit-component .future-fit-content {
  padding: 10px;
}

.module-feed .hired-component .hired-con {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .module-feed .hired-component .hired-con {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

.module-feed .hired-component .hired-con .responsive-video-standard {
  min-width: 300px;
}

.module-feed .hired-component .hired-con .responsive-video-standard video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-feed .hired-component .hired-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 10px;
}

.module-feed .hired-component .hired-content .title {
  font-size: 1.5rem;
  color: rgb(var(--color-brand-main));
  margin: 0;
}

@media (min-width: 768px) {
  .module-feed .hired-component .half {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}

#engages-aside {
  /*
    FIXME : update all workspace dialogs to have a common class name
    https://agile.proteus.co/browse/ENGAGE-2926
    */
  /*
    FIXME : update all workspace dialogs to have a common class name
    https://agile.proteus.co/browse/ENGAGE-2926
*/
}

#engages-aside .window.dialog {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
  border: none;
  overflow: auto;
  background: rgba(241, 242, 247, 0.9);
  padding: 32px 0 65px;
}

@media (min-width: 1024px) {
  #engages-aside .window.dialog {
    padding: 0 calc((100vw - 900px) / 2) !important;
  }
}

#engages-aside .window.dialog > .titlebar {
  min-height: 30px;
  margin-top: 1em;
}

@media (max-width: 767px) {
  #engages-aside .window.dialog > .titlebar {
    margin-top: 2.5em;
  }
}

#engages-aside .window.dialog > .titlebar .titlebar.activewindowtitle {
  padding: 15px 15px 1px;
  font-size: 1.2rem;
  background: rgb(var(--color-brand-main));
  height: 50px;
}

@media (min-width: 1024px) {
  #engages-aside .window.dialog > .titlebar .titlebar.activewindowtitle {
    font-size: 1.6rem;
  }
}

#engages-aside .window.dialog > .titlebar .titlebar.inactivewindowtitle {
  padding: 15px 15px 1px;
  font-size: 1.2rem;
  background: rgb(var(--color-brand-main));
  height: 50px;
  color: transparent;
}

@media (min-width: 1024px) {
  #engages-aside .window.dialog > .titlebar .titlebar.inactivewindowtitle {
    font-size: 1.6rem;
  }
}

#engages-aside .window.dialog > .titlebar td.windowbuttons {
  height: 50px;
  display: block;
}

@media (min-width: 1024px) {
  #engages-aside .window.dialog > .titlebar {
    max-width: 900px;
    min-width: 900px;
  }
}

#engages-aside .window.dialog > .windowcontent {
  max-width: 100%;
  min-width: 100%;
  min-height: unset;
  max-height: unset;
  overflow: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: static;
}

@media (min-width: 1024px) {
  #engages-aside .window.dialog > .windowcontent {
    max-width: 900px;
    min-width: 900px;
  }
}

#engages-aside .window.dialog > .windowcontent .editor-title {
  color: rgb(var(--color-brand-main-dark));
  position: relative;
  margin-bottom: 10px;
}

#engages-aside .window.dialog > .windowcontent .editor-title:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #dbe3e8;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
}

#engages-aside .window.dialog > .windowcontent label,
#engages-aside .window.dialog > .windowcontent .label {
  display: block;
  width: 100%;
  color: rgb(var(--color-brand-main));
  line-height: 1.2;
  text-transform: none;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 20px 10px 0 0;
}

#engages-aside .window.dialog .bottom.persistence-actions.actions,
#engages-aside .window.dialog .editor-actions {
  text-align: left !important;
  padding: 15px;
}

@media (max-width: 767px) {
  #engages-aside .window.dialog .bottom.persistence-actions.actions,
  #engages-aside .window.dialog .editor-actions {
    margin-bottom: 38px;
  }
}

#engages-aside .window.dialog .publish input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}

#engages-aside .window.dialog .publish input[type="radio"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 50%;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

#engages-aside .window.dialog .publish div.publish-now {
  margin-top: 10px;
}

#engages-aside .window.dialog .publish div.publish-now span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#engages-aside .window.dialog .publish div.publish-now label {
  margin: 0 10px 10px;
}

#engages-aside .window.dialog .publish .publish-later span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#engages-aside .window.dialog .publish .publish-later label {
  margin: 0 10px 10px;
}

#engages-aside .window.dialog .publish .publish-later .select2-selection__rendered {
  padding: .5rem 2.75rem .5rem .5rem;
}

#engages-aside .window.dialog .publish .publish-later .select2-selection__arrow {
  width: 0.5rem;
}

#engages-aside .window.dialog .publish .publish-later .select2-selection__arrow:after {
  padding-left: 0.25rem;
}

#engages-aside .window.dialog .publish .publish-later .hour-selector .timezone {
  padding-left: 25px;
}

#engages-aside .window.dialog .notify .label-after {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#engages-aside .window.dialog .notify .label-after label {
  margin: 0 10px 0;
}

#engages-aside .window.dialog .notify input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

#engages-aside .window.dialog .notify input[type="checkbox"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 2px;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

#engages-aside .window.dialog .note label {
  margin-left: 0 !important;
  text-transform: uppercase;
}

#engages-aside .window.dialog div.replacement-message span.message-text,
#engages-aside .window.dialog div.replacement-message span.message-flavor,
#engages-aside .window.dialog div.replacement-message span.asset-name {
  margin-left: 0;
}

#engages-aside .window.dialog div.message.success:before {
  content: none;
}

#engages-aside div.window.send-module-dlg .windowcontent {
  border-radius: 0 0 3px 3px;
  margin-top: 0;
}

#engages-aside div.window.send-module-dlg .windowcontent > .title {
  margin: 20px 0 10px;
}

#engages-aside div.window.send-module-dlg .windowcontent .module-status {
  margin-top: 10px;
}

#engages-aside div.window.send-module-dlg .windowcontent .notification-info .prop label.tal {
  margin: 0 0 5px;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgb(var(--color-brand-main));
}

#engages-aside div.window.send-module-dlg .windowcontent .notification-info .prop input[type=text] {
  background-color: #fff;
}

#engages-aside div.window.send-module-dlg > .titlebar {
  border-radius: 3px 3px 0 0;
  -webkit-box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.15);
}

#engages-aside div.window.send-module-dlg .group-heading {
  padding: 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  color: #4a4a4b;
}

#engages-aside div.window.send-module-dlg label.tbl {
  color: #4a4a4b;
  display: inline-block;
}

#engages-aside div.window.send-module-dlg .prop label {
  color: #4a4a4b;
  margin: 0 10px 5px;
}

#engages-aside div.window.send-module-dlg .prop-group .ctb.label-before label.tbl {
  font-weight: normal;
  text-transform: none;
  color: #000000;
}

#engages-aside div.window.send-module-dlg .prop-group .ctb input[type="checkbox"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgb(var(--color-brand-main));
  width: 1.1rem;
  height: 1rem;
  border-radius: 3px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

#engages-aside div.window.send-module-dlg .prop-group .ctb input[type="checkbox"]:checked:after {
  content: "";
  height: calc(1rem - 6px);
  width: calc(1rem - 7px);
  display: block;
  border-radius: 2px;
  background-color: rgb(var(--color-brand-main));
  margin-top: 2px;
  margin-left: 2px;
}

#engages-aside div.window.send-module-dlg .prop-group .ctb input[type="checkbox"] + label.tbl:after {
  content: "";
}

@media (min-width: 480px) {
  #engages-aside div.window.send-module-dlg .prop-group .ctb {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: calc(50% - 15px);
    margin-right: 15px;
    vertical-align: top;
  }
}

#engages-aside div.window.send-module-dlg .miwt-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  max-width: 300px;
  padding: 0 15px;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.open {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.open:before {
  content: "ï‰±";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #02C27E;
  font-weight: 700;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.open:disabled {
  cursor: not-allowed;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.open:not([disabled]) {
  cursor: pointer;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.open:hover {
  background-color: rgba(2, 194, 126, 0.2);
  border-color: rgba(2, 194, 126, 0.2);
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.clear {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
  position: unset;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.clear:before {
  content: "ï€";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #F27E30;
  font-weight: 700;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.clear:disabled {
  cursor: not-allowed;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.clear:not([disabled]) {
  cursor: pointer;
}

#engages-aside div.window.send-module-dlg .miwt-calendar button.clear:hover {
  background-color: rgba(242, 126, 48, 0.2);
  border-color: rgba(242, 126, 48, 0.2);
}

#engages-aside div.window.send-module-dlg .hour-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#engages-aside div.window.send-module-dlg .hour-selector .select2 {
  width: 80px !important;
}

#engages-aside div.window.send-module-dlg .hour-selector .timezone {
  padding-left: 10px;
}

#engages-aside div.window.send-module-dlg .instructions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#engages-aside div.window.send-module-dlg .instructions.publish-later {
  padding: 0 25px;
}

#engages-aside div.window.send-module-dlg .options .notify-label,
#engages-aside div.window.send-module-dlg .options .publish-label {
  font-size: 1rem;
  color: rgb(var(--color-brand-main));
  text-transform: uppercase;
  margin-left: 0;
}

#engages-aside div.window.dialog.file-dialog .sibling_directories-con {
  vertical-align: top;
}

#engages-aside div.window.dialog.file-dialog .sibling_directories {
  padding-right: 15px;
}

#engages-aside div.window.dialog.file-dialog .sibling_directories input[type=radio] {
  display: none;
}

#engages-aside div.window.dialog.file-dialog .sibling_directories .btn-image img {
  display: none;
}

#engages-aside div.window.dialog.file-dialog .sibling_directories .btn-image:after {
  content: "ï»";
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  padding-right: 5px;
  vertical-align: baseline;
}

#engages-aside div.window.dialog.file-dialog .file_listing {
  width: 100%;
  border: 1px solid #F4F6FC;
  border-collapse: collapse;
}

#engages-aside div.window.dialog.file-dialog .file_listing th {
  background-color: #dbe3e8;
}

#engages-aside div.window.dialog.file-dialog .file_listing th,
#engages-aside div.window.dialog.file-dialog .file_listing td {
  padding: 5px;
}

#engages-aside div.window.dialog.file-dialog .file_listing tbody tr.odd {
  background-color: #F4F6FC;
}

#engages-aside div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#engages-aside div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_thumbnail {
  max-width: 20%;
  height: auto;
}

#engages-aside div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .btn-image {
  display: none;
}

#engages-aside div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_file {
  -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
  padding: 10px;
  word-break: break-all;
  padding-right: 5px;
}

#engages-aside div.window.dialog.file-dialog .file_listing .type-file-system-entity .fse_icon_name .fse_file span {
  display: inline-block;
}

#engages-aside div.window.dialog.file-dialog td.actions-con .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#engages-aside div.window.dialog.file-dialog td.actions-con .actions .upload,
#engages-aside div.window.dialog.file-dialog td.actions-con .actions .createFolder {
  text-align: left;
  width: 280px;
}

#engages-aside div.window.dialog.file-dialog td.actions-con .actions .upload button.btn,
#engages-aside div.window.dialog.file-dialog td.actions-con .actions .createFolder button.btn {
  margin-top: 1rem;
}

#engages-aside div.window.dialog.file-dialog td.actions-con .actions .createFolder input {
  margin: 1rem 0 0;
}

#engages-aside .value-list-editor-list-orderable .list-renderer:active {
  border: 1px solid rgb(var(--color-brand-main)) !important;
}

#engages-aside .value-list-editor-list-orderable .list-renderer .actions .btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}

#engages-aside .value-list-editor-list-orderable .list-renderer .actions .btn.remove:hover:before {
  color: #E53140 !important;
}

#engages-aside .value-list-editor-list-orderable .list-renderer .actions .btn.edit:hover:before {
  color: rgb(var(--color-brand-main)) !important;
}

#engages-aside .dnd-source {
  cursor: grab;
  cursor: -webkit-grab;
}

#engages-aside .dnd-active {
  cursor: no-drop;
}

#engages-aside .dnd-active .dnd-source {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

#engages-aside .dnd-active .dnd-target {
  cursor: copy;
}

@media (min-width: 768px) {
  #engages-aside .custom-content-editor .factor-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 10px 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (min-width: 768px) {
  #engages-aside .custom-content-editor .factor-row .prop {
    max-width: 32%;
  }
}

#engages-aside #document-title .prop-title {
  padding: 0;
}

#engages-aside #document-selection .select-label, #engages-aside #company-why-selection .select-label, #engages-aside #demo-selection .select-label, #engages-aside #meeting-selection .select-label, #engages-aside #proposal-selection .select-label, #engages-aside #impact-stories-selection .select-label,
#engages-aside #onboarding-next-selection .select-label, #engages-aside #hired-selection .select-label, #engages-aside #about-job-selection .select-label, #engages-aside #field-selection .select-label, #engages-aside #product-service-selection .select-label {
  margin: 10px 0 0;
}

#engages-aside #document-selection .select2, #engages-aside #company-why-selection .select2, #engages-aside #demo-selection .select2, #engages-aside #meeting-selection .select2, #engages-aside #proposal-selection .select2, #engages-aside #impact-stories-selection .select2,
#engages-aside #onboarding-next-selection .select2, #engages-aside #hired-selection .select2, #engages-aside #about-job-selection .select2, #engages-aside #field-selection .select2, #engages-aside #product-service-selection .select2 {
  margin: 5px 0 10px;
  width: 350px !important;
}

#engages-aside #document-selection .select2-selection, #engages-aside #company-why-selection .select2-selection, #engages-aside #demo-selection .select2-selection, #engages-aside #meeting-selection .select2-selection, #engages-aside #proposal-selection .select2-selection, #engages-aside #impact-stories-selection .select2-selection,
#engages-aside #onboarding-next-selection .select2-selection, #engages-aside #hired-selection .select2-selection, #engages-aside #about-job-selection .select2-selection, #engages-aside #field-selection .select2-selection, #engages-aside #product-service-selection .select2-selection {
  border-radius: 0;
  border: 1px solid #a8acbc;
  height: 36px;
  line-height: 1;
}

#engages-aside #document-selection .select2-selection .select2-selection__rendered, #engages-aside #company-why-selection .select2-selection .select2-selection__rendered, #engages-aside #demo-selection .select2-selection .select2-selection__rendered, #engages-aside #meeting-selection .select2-selection .select2-selection__rendered, #engages-aside #proposal-selection .select2-selection .select2-selection__rendered, #engages-aside #impact-stories-selection .select2-selection .select2-selection__rendered,
#engages-aside #onboarding-next-selection .select2-selection .select2-selection__rendered, #engages-aside #hired-selection .select2-selection .select2-selection__rendered, #engages-aside #about-job-selection .select2-selection .select2-selection__rendered, #engages-aside #field-selection .select2-selection .select2-selection__rendered, #engages-aside #product-service-selection .select2-selection .select2-selection__rendered {
  line-height: 34px;
  font-size: 1rem;
}

#engages-aside #document-selection .select2-selection .select2-selection__arrow, #engages-aside #company-why-selection .select2-selection .select2-selection__arrow, #engages-aside #demo-selection .select2-selection .select2-selection__arrow, #engages-aside #meeting-selection .select2-selection .select2-selection__arrow, #engages-aside #proposal-selection .select2-selection .select2-selection__arrow, #engages-aside #impact-stories-selection .select2-selection .select2-selection__arrow,
#engages-aside #onboarding-next-selection .select2-selection .select2-selection__arrow, #engages-aside #hired-selection .select2-selection .select2-selection__arrow, #engages-aside #about-job-selection .select2-selection .select2-selection__arrow, #engages-aside #field-selection .select2-selection .select2-selection__arrow, #engages-aside #product-service-selection .select2-selection .select2-selection__arrow {
  right: 5px;
  top: 6px;
}

#engages-aside #document-selection .select2-selection .select2-selection__arrow b, #engages-aside #company-why-selection .select2-selection .select2-selection__arrow b, #engages-aside #demo-selection .select2-selection .select2-selection__arrow b, #engages-aside #meeting-selection .select2-selection .select2-selection__arrow b, #engages-aside #proposal-selection .select2-selection .select2-selection__arrow b, #engages-aside #impact-stories-selection .select2-selection .select2-selection__arrow b,
#engages-aside #onboarding-next-selection .select2-selection .select2-selection__arrow b, #engages-aside #hired-selection .select2-selection .select2-selection__arrow b, #engages-aside #about-job-selection .select2-selection .select2-selection__arrow b, #engages-aside #field-selection .select2-selection .select2-selection__arrow b, #engages-aside #product-service-selection .select2-selection .select2-selection__arrow b {
  display: none;
}

#engages-aside #document-selection .select2-selection .select2-selection__arrow:after, #engages-aside #company-why-selection .select2-selection .select2-selection__arrow:after, #engages-aside #demo-selection .select2-selection .select2-selection__arrow:after, #engages-aside #meeting-selection .select2-selection .select2-selection__arrow:after, #engages-aside #proposal-selection .select2-selection .select2-selection__arrow:after, #engages-aside #impact-stories-selection .select2-selection .select2-selection__arrow:after,
#engages-aside #onboarding-next-selection .select2-selection .select2-selection__arrow:after, #engages-aside #hired-selection .select2-selection .select2-selection__arrow:after, #engages-aside #about-job-selection .select2-selection .select2-selection__arrow:after, #engages-aside #field-selection .select2-selection .select2-selection__arrow:after, #engages-aside #product-service-selection .select2-selection .select2-selection__arrow:after {
  content: "ï„‡";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  font-size: 1.25rem;
  color: #cfcfcf;
}

#engages-aside #company-why-selection label, #engages-aside #demo-selection label, #engages-aside #meeting-selection label, #engages-aside #proposal-selection label, #engages-aside #onboarding-next-selection label,
#engages-aside #hired-selection label, #engages-aside #about-job-selection label, #engages-aside #product-service-selection label, #engages-aside #impact-stories-selection label {
  margin: 20px 0 0;
}

#engages-aside #company-why-selection .dc-file-upload .label, #engages-aside #demo-selection .dc-file-upload .label, #engages-aside #meeting-selection .dc-file-upload .label, #engages-aside #proposal-selection .dc-file-upload .label, #engages-aside #onboarding-next-selection .dc-file-upload .label,
#engages-aside #hired-selection .dc-file-upload .label, #engages-aside #about-job-selection .dc-file-upload .label, #engages-aside #product-service-selection .dc-file-upload .label, #engages-aside #impact-stories-selection .dc-file-upload .label {
  margin: 0;
}

#engages-aside #company-why-selection .select2, #engages-aside #demo-selection .select2, #engages-aside #meeting-selection .select2, #engages-aside #proposal-selection .select2, #engages-aside #onboarding-next-selection .select2,
#engages-aside #hired-selection .select2, #engages-aside #about-job-selection .select2, #engages-aside #product-service-selection .select2, #engages-aside #impact-stories-selection .select2 {
  width: 350px !important;
}

@media (max-width: 767px) {
  #engages-aside #company-why-selection .select2, #engages-aside #demo-selection .select2, #engages-aside #meeting-selection .select2, #engages-aside #proposal-selection .select2, #engages-aside #onboarding-next-selection .select2,
  #engages-aside #hired-selection .select2, #engages-aside #about-job-selection .select2, #engages-aside #product-service-selection .select2, #engages-aside #impact-stories-selection .select2 {
    max-width: 280px;
  }
}

#engages-aside #company-why-selection .poster_image span, #engages-aside #demo-selection .poster_image span, #engages-aside #meeting-selection .poster_image span, #engages-aside #proposal-selection .poster_image span, #engages-aside #onboarding-next-selection .poster_image span,
#engages-aside #hired-selection .poster_image span, #engages-aside #about-job-selection .poster_image span, #engages-aside #product-service-selection .poster_image span, #engages-aside #impact-stories-selection .poster_image span {
  margin: 20px 0 0;
  font-size: 1.1rem;
}

#engages-aside #company-why-selection .poster_image .dimensions-instructions, #engages-aside #demo-selection .poster_image .dimensions-instructions, #engages-aside #meeting-selection .poster_image .dimensions-instructions, #engages-aside #proposal-selection .poster_image .dimensions-instructions, #engages-aside #onboarding-next-selection .poster_image .dimensions-instructions,
#engages-aside #hired-selection .poster_image .dimensions-instructions, #engages-aside #about-job-selection .poster_image .dimensions-instructions, #engages-aside #product-service-selection .poster_image .dimensions-instructions, #engages-aside #impact-stories-selection .poster_image .dimensions-instructions {
  font-style: italic;
  margin: 5px 0;
  font-size: .875rem;
}

#engages-aside #field-selection label {
  margin: 20px 0 0;
}

#engages-aside #field-selection .dc-file-upload .label {
  margin: 0;
}

#engages-aside #field-selection .poster_image span {
  margin: 20px 0 0;
}

#engages-aside #field-selection .poster_image .dimensions-instructions {
  margin: 0;
}

/* Undo styles that should only be targeting the header. */
.new-subspace-dlg .titlebar .titlebar_row td.titlebar.activewindowtitle {
  background-color: rgb(var(--color-brand-main));
  color: #fff;
  padding: 15px 15px 1px;
  height: 50px;
  font-size: 1.6rem;
}

.new-subspace-dlg .account-team-members .team-members {
  width: 100%;
  position: initial;
  max-height: unset;
}

.new-subspace-dlg .account-team-members .team-members:before {
  display: none;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-img {
  height: 4rem;
  width: 4rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn {
  padding-left: 5rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn:before {
  height: 4rem;
  width: 4rem;
  left: 2rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn:after {
  font-size: 1.5rem;
  left: 2rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .last-login {
  display: block;
}

.new-subspace-dlg .account-team-members .team-members .team-member .duration {
  display: block;
}

.new-subspace-dlg .account-team-members {
  overflow-y: auto;
  height: 80vh;
}

.new-subspace-dlg .account-team-members .team-members {
  margin: 5px auto;
}

.subspace-conversations {
  margin: 20px 0 0;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 20px #dbe3e8;
          box-shadow: 0 0 20px #dbe3e8;
}

@media (min-width: 1024px) {
  .subspace-conversations {
    margin-top: 10px;
    max-width: 283px;
  }
}

@media (min-width: 1280px) {
  .subspace-conversations {
    max-width: 300px;
  }
}

.subspace-conversations .view {
  font-weight: 700;
  font-size: 1.1rem;
  padding: 1rem 1rem 0.5rem;
  display: block;
}

.subspace-conversations .view:before {
  content: "ï€•";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1.25rem;
  color: rgb(var(--color-brand-main));
  font-style: normal;
  margin-right: 5px;
}

.subspace-conversations .view:hover {
  color: rgb(var(--color-brand-main-dark));
}

.subspace-conversations .view:hover:before {
  color: rgb(var(--color-brand-main-dark));
}

.subspace-conversations a {
  color: rgb(var(--color-brand-main));
  text-decoration: none;
}

.subspace-conversations .unread-conversations {
  color: #4a4a4b;
  margin-left: .5rem;
  padding: 0 0.5rem 1rem;
  display: block;
}

.subspace-conversations .conversations-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.25rem 1rem;
}

.subspace-conversations .conversations-header .conversations-title {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 1.1rem;
  line-height: 1.5;
  color: rgb(var(--color-brand-main));
  font-weight: 700;
}

.subspace-conversations .conversations-header .btn {
  font-size: .75rem;
  border: 1px solid rgb(var(--color-brand-accent));
  background-color: rgb(var(--color-brand-accent));
  color: #fff;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 900;
  border-radius: 2rem;
}

.subspace-conversations .conversations-header .btn:hover {
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-accent));
          box-shadow: 0 0 5px rgb(var(--color-brand-accent));
}

.subspace-conversations .conversations-header .btn + .btn {
  margin-left: 5px;
}

.subspace-conversations .conversations-header .hide, .subspace-conversations .conversations-header .show, .subspace-conversations .conversations-header .new {
  padding: .25rem .375rem;
}

.subspace-conversations .conversations-header .hide:before, .subspace-conversations .conversations-header .show:before, .subspace-conversations .conversations-header .new:before {
  font-family: "Font Awesome 5 Pro";
  font-size: .75rem;
  font-weight: 700;
  padding-right: .25ch;
}

.subspace-conversations .conversations-header .hide:before {
  content: "ï·";
}

.subspace-conversations .conversations-header .show:before {
  content: "ï¸";
}

.subspace-conversations .conversations-header .new:before {
  content: "ï•";
}

.subspace-conversations ol.conversations {
  list-style: none;
  padding: 5px 0;
  margin: .25rem 0;
  border-top: 1px solid #dbe3e8;
}

.subspace-conversations ol.conversations .unread-messages::after {
  content: attr(data-message-count);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.4rem 0;
  background-color: rgb(var(--color-brand-accent));
  color: #fff;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
}

.subspace-conversations li.subspace {
  padding: 5px 2rem 5px 1rem;
  position: relative;
}

.subspace-conversations li.subspace:before {
  content: "ïƒ";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 1rem;
  color: rgb(var(--color-brand-main));
  font-style: normal;
  margin-right: 5px;
}

.subspace-conversations li.subspace a {
  color: rgb(var(--color-brand-main));
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  max-width: calc(100% - 42px);
  margin-right: 5px;
  line-height: 1.5;
}

.subspace-conversations li.subspace a:hover {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .subspace-conversations li.subspace {
    width: 280px;
  }
}

@media (min-width: 1280px) {
  .subspace-conversations li.subspace {
    width: 300px;
  }
}

.subspace-conversations li.subspace.active {
  background-color: rgb(var(--color-brand-main));
}

.subspace-conversations li.subspace.active:before {
  color: #fff;
}

.subspace-conversations li.subspace.active a {
  color: #fff;
  margin-right: 5px;
}

.subspace .delimiter {
  padding-right: .5ch;
}

@media (max-width: 767px) {
  .engages__aside__wrapper .miwt {
    display: none;
  }
}

.workspace-op-search .workspace {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: .2rem;
}

.workspace-op-search .workspace a.workspace-link {
  color: #343940;
  font-weight: 700;
  font-size: 1.05rem;
}

.workspace-op-search .workspace .actions .btn {
  font-size: 0.7rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: .25rem .35rem;
  vertical-align: middle;
}

.workspace-op-search .workspace .actions .btn:before {
  content: '\f067';
  font-size: .7rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  margin-right: 0.1rem;
}

.workspace-op-search .miwt-collapsable {
  position: relative;
}

.workspace-op-search .miwt-collapsable .btn.trigger {
  position: absolute;
  height: 2rem;
  width: 2rem;
  padding: 0;
  border-style: solid;
  border-width: 2px 2px 2px 0;
  border-radius: 0 50% 50% 0;
  border-color: #cfcfcf;
  background-color: #cfcfcf;
  -webkit-box-shadow: 5px 0 10px rgba(213, 213, 213, 0.45);
          box-shadow: 5px 0 10px rgba(213, 213, 213, 0.45);
}

@media (max-width: 1023px) {
  .workspace-op-search .miwt-collapsable .btn.trigger {
    top: -1rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

@media (min-width: 1024px) {
  .workspace-op-search .miwt-collapsable .btn.trigger {
    top: 0.35rem;
  }
}

.workspace-op-search .miwt-collapsable .btn.trigger:before {
  background: -webkit-gradient(linear, left top, right top, from(rgba(213, 213, 213, 0.45)), to(rgba(213, 213, 213, 0)));
  background: linear-gradient(90deg, rgba(213, 213, 213, 0.45) 0%, rgba(213, 213, 213, 0) 100%);
  content: '';
  display: block;
  height: 3rem;
  left: 0;
  position: absolute;
  top: -0.6rem;
  width: 2px;
}

.workspace-op-search .miwt-collapsable .btn.trigger:after {
  content: "ïŒ£";
  font-size: 1rem;
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  line-height: 1;
}

.workspace-op-search .miwt-collapsable .btn.trigger:hover {
  border-color: #b0b0b0;
  background-color: #b0b0b0;
}

.workspace-op-search .miwt-collapsable.hidden {
  width: 0;
}

.workspace-op-search .miwt-collapsable.hidden .btn.trigger:after {
  content: "ïŒ¤";
}

@media (max-width: 1023px) {
  .workspace-op-search .miwt-collapsable.hidden .btn.trigger {
    left: 1rem;
  }
}

.workspace-op-search .miwt-collapsable.shown {
  width: 100%;
  padding: 0.5rem 1rem 1rem;
}

@media (min-width: 1024px) {
  .workspace-op-search .miwt-collapsable.shown {
    width: 300px;
    -webkit-box-shadow: 0 0 30px rgba(213, 213, 213, 0.75);
            box-shadow: 0 0 30px rgba(213, 213, 213, 0.75);
  }
  .workspace-op-search .miwt-collapsable.shown .btn.trigger {
    right: -2rem;
  }
}

.workspace-op-search .miwt-table > tbody > tr > td.type-workspace-search-dto,
.workspace-op-search .miwt-table > thead > tr > th.type-workspace-search-dto {
  padding: 0;
}

.workspace-op-search .workspace-subspaces {
  padding: .75rem 1rem;
}

@media (min-width: 1024px) {
  .workspace-op-search .workspace-subspaces {
    width: 270px;
  }
}

.workspace-op-search .workspace-subspaces.active {
  background-color: #DBE3E8;
}

@media (min-width: 1024px) {
  .workspace-op-search .subspaces {
    width: 236px;
  }
}

.workspace-op-search .subspaces .subspace {
  width: 100%;
  padding-right: 1.9rem;
  line-height: 1.5;
}

.workspace-op-search .subspaces .subspace.unread-messages::after {
  content: attr(data-message-count);
  font-size: .8rem;
  font-weight: 700;
  line-height: .8;
  padding: 0.4rem 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #2dc590;
  color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  text-align: center;
}

.workspace-op-search .subspaces .subspace .active {
  font-weight: bold;
}

.workspace-op-search .subspaces .subspace > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  position: relative;
}

.workspace-op-search .subspaces .subspace .delimiter {
  padding-right: .5ch;
}

@media (max-width: 1023px) {
  .workspace-op-search div.search {
    padding-top: 1rem;
  }
}

.workspace-op-search div.search .search-bar .constraints {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.workspace-op-search div.search .search-bar input,
.workspace-op-search div.search .search-bar select {
  min-width: 100%;
  padding: 7px 8px 7px;
}

.workspace-op-search div.search .search-bar .select2-container {
  width: calc(100% - 2rem) !important;
}

.workspace-op-search div.search .search-bar .search-actions {
  padding-top: 18px;
  margin-bottom: 20px;
  width: 100%;
}

.workspace-op-search div.search .search-suppliers {
  line-height: 2;
}

.workspace-op-search div.search .search-suppliers .selected {
  font-weight: 700;
}

.workspace-op-search div.search .search-suppliers a:not(:last-child):after {
  content: "|";
  display: inline-block;
  padding-right: 3px;
  padding-left: 3px;
  cursor: auto;
}

.workspace-op-search .pager-search-results {
  -webkit-box-shadow: 0 0 30px rgba(213, 213, 213, 0.75);
          box-shadow: 0 0 30px rgba(213, 213, 213, 0.75);
}

@media (min-width: 1024px) {
  .workspace-op-search .pager-search-results {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}

.workspace-op-search .pager-search-results .pageable .search-results {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 0;
}

.workspace-op-search .pager-search-results .pageable .search-results th {
  display: none;
}

.workspace-op-search .pager-search-results .pager-bottom {
  margin-bottom: 0;
}

.add-module-component + .miwt.edit-conv {
  margin-top: 30px;
}

.miwt .conversation-header {
  background-color: rgb(var(--color-brand-accent));
  color: #fff;
  padding: 25px 30px;
}

@media (min-width: 768px) {
  .miwt .conversation-header .conversation-members-con {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.miwt .conversation-header .conversation-members-con .conversation-members span {
  display: inline-block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.miwt .conversation-header h2 {
  margin: 0;
  font-size: 0.925rem;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
}

@media (min-width: 768px) {
  .miwt .conversation-header h2 {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

.miwt .conversation-header h1 {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
  color: #fff;
}

@media (min-width: 768px) {
  .miwt .conversation-header h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .miwt .conversation-header h1 {
    font-size: 3rem;
  }
}

.miwt .conversation-header .btn.edit-conversation {
  width: auto;
  border: 0 none;
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-box-shadow: 0 0 0 transparent;
          box-shadow: 0 0 0 transparent;
  background-color: transparent;
  color: #fff;
  max-height: 40px;
  margin: auto 0;
  padding: 0 2px;
}

.miwt .conversation-header .btn.edit-conversation:before {
  content: "ï„";
  font-family: "Font Awesome 5 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
}

.miwt .conversation-header .btn.edit-conversation:hover {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 transparent;
          box-shadow: 0 0 0 transparent;
}

.module-footer-wrapper .module-footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 0.75rem 1rem;
  border-top: 1px solid #dbe3e8;
}

@media (min-width: 768px) {
  .module-footer-wrapper .module-footer-inner {
    padding: 16px 20px;
  }
}

@media (min-width: 1280px) {
  .module-footer-wrapper .module-footer-inner {
    padding: 16px 30px;
  }
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn {
  margin-right: 10px;
  font-size: 0.95rem;
  font-weight: 700px;
  line-height: 2.25;
  text-decoration: none;
}

@media (min-width: 768px) {
  .module-footer-wrapper .module-footer-inner .show-conversation-btn {
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 1.75;
  }
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-label {
  text-transform: uppercase;
  color: rgb(var(--color-brand-main-dark));
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-message-count {
  color: rgb(var(--color-brand-accent));
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon {
  color: rgb(var(--color-brand-accent));
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand:before {
  content: "ï¸";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.15rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand:disabled {
  cursor: not-allowed;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand:not([disabled]) {
  cursor: pointer;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand:before {
  line-height: 42px;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.expand:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.collapse {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.collapse:before {
  content: "ï·";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.15rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #1C74BA;
  font-weight: 700;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.collapse:disabled {
  cursor: not-allowed;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.collapse:not([disabled]) {
  cursor: pointer;
}

.module-footer-wrapper .module-footer-inner .show-conversation-btn-icon.collapse:not([disabled]):hover {
  background-color: rgba(28, 116, 186, 0.2);
  border-color: rgba(28, 116, 186, 0.2);
}

/* Undo styles that should only be targeting the header. */
.new-subspace-dlg .titlebar td.titlebar.activewindowtitle {
  color: #fff;
}

@media (max-width: 767px) {
  .new-subspace-dlg .prop-editor .prop-footer .persistence-actions .btn.save {
    margin-top: 0.5rem;
    margin-right: 12px;
  }
  .new-subspace-dlg .prop-editor .prop-footer .persistence-actions .btn.cancel {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}

.new-subspace-dlg .account-team-members .team-members {
  width: 100%;
  position: initial;
  max-height: unset;
}

.new-subspace-dlg .account-team-members .team-members:before {
  display: none;
}

.new-subspace-dlg .account-team-members .team-members .team-member {
  position: relative;
}

.new-subspace-dlg .account-team-members .team-members .team-member:after {
  content: '';
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-img {
  height: 4rem;
  width: 4rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn {
  padding-left: 5rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn:before {
  height: 4rem;
  width: 4rem;
  left: 2rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-btn:after {
  font-size: 1.5rem;
  left: 2rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-selection .ctb {
  padding: 5px 0;
  line-height: 0;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-selection .ctb input[type="checkbox"] {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
}

.new-subspace-dlg .account-team-members .team-members .team-member .team-member-selection .ctb input[type="checkbox"]:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #4a4a4b;
  background-color: white;
}

.new-subspace-dlg .account-team-members .team-members .team-member .last-login {
  display: block;
}

.new-subspace-dlg .account-team-members .team-members .team-member .duration {
  display: block;
}

.new-subspace-dlg .account-team-members .team-members .team-member.selected {
  -webkit-box-shadow: inset 0 0 10px #cfcfcf;
          box-shadow: inset 0 0 10px #cfcfcf;
}

.new-subspace-dlg .account-team-members .team-members .team-member.selected .team-member-selection .ctb {
  padding: 5px 0;
  line-height: 0;
}

.new-subspace-dlg .account-team-members .team-members .team-member.selected .team-member-selection .ctb input[type="checkbox"]:before {
  border: 2px solid rgb(var(--color-brand-main));
}

.new-subspace-dlg .account-team-members .team-members .team-member.selected .team-member-selection .ctb input[type="checkbox"]:after {
  content: "ï€Œ";
  font-family: "Font Awesome 5 Pro";
  font-size: 1rem;
  font-weight: bold;
  font-style: normal;
  color: rgb(var(--color-brand-main));
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  line-height: 1.5;
}

.new-subspace-dlg .account-team-members {
  overflow-y: auto;
  height: 50vh;
}

@media (min-width: 1400px) {
  .new-subspace-dlg .account-team-members {
    height: 60vh;
  }
}

@media (max-width: 767px) {
  .new-subspace-dlg .account-team-members {
    display: block;
  }
}

.new-subspace-dlg .account-team-members .team-members {
  margin: 5px auto;
}

.engages-main-content {
  position: relative;
}

@media (max-width: 1023px) {
  .engages-main-content {
    margin-bottom: 50px;
  }
}

.engages-main-content .new-subspace-dlg {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
  border: none;
  overflow: auto;
  background: rgba(241, 242, 247, 0.9) !important;
  padding: 0 calc((100vw - 900px)/ 2) !important;
}

@media (min-width: 1280px) {
  .engages-main-content .new-subspace-dlg > .titlebar {
    max-width: 900px;
    min-width: 900px;
  }
}

@media (min-width: 1280px) {
  .engages-main-content .new-subspace-dlg > .windowcontent {
    max-width: 900px;
    min-width: 900px;
  }
}

.module-conversations-opened {
  border: 3px solid rgb(var(--color-brand-main-dark));
}

.module-wrapper .module-conversation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.module-wrapper .module-conversation .empty-conversation-label {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-style: italic;
  font-size: 1.25rem;
}

.module-wrapper .module-conversation .send-message, .module-wrapper .module-conversation .conversation-con {
  padding: 15px;
}

.module-wrapper .module-conversation .conversation-con {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

@media (min-width: 768px) {
  .module-wrapper .module-conversation .conversation-con {
    -ms-flex-preferred-size: 60%;
        flex-basis: 60%;
  }
}

.module-wrapper .module-conversation .send-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(var(--color-brand-main-dark));
  color: rgb(var(--color-brand-main-reverse));
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.module-wrapper .module-conversation .send-message .btn.send {
  position: relative;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 0;
  letter-spacing: normal;
}

.module-wrapper .module-conversation .send-message .btn.send:before {
  content: "ï‡˜";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
  text-align: center;
  color: #fff;
  font-weight: 700;
}

.module-wrapper .module-conversation .send-message .btn.send:disabled {
  cursor: not-allowed;
}

.module-wrapper .module-conversation .send-message .btn.send:not([disabled]) {
  cursor: pointer;
}

.module-wrapper .module-conversation .send-message textarea.message {
  border: none;
  background-color: #fff;
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9375rem;
  resize: none;
  border-radius: 5px;
}

.module-wrapper .module-conversation .send-message textarea.message:focus, .module-wrapper .module-conversation .send-message textarea.message:active {
  border: none;
}

.module-wrapper .module-conversation .conversation-con {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background-color: #F4F6FC;
  max-height: 350px;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .module-wrapper .module-conversation .conversation-con {
    max-height: 600px;
  }
}

.module-wrapper .module-conversation .conversation-con .conversation-message {
  border-bottom: 1px solid #ddd;
  padding: .75rem 0;
}

.module-wrapper .module-conversation .conversation-con .conversation-message .name {
  font-size: 1rem;
  color: rgb(var(--color-brand-main));
  padding-right: 1ch;
}

.module-wrapper .module-conversation .conversation-con .conversation-message .sent-time-friendly {
  color: darkgray;
  display: inline;
  font-size: .85rem;
  font-style: italic;
}

.module-wrapper .module-conversation .conversation-con .conversation-message .message-body {
  margin: .25rem 0;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 38px;
  height: 38px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background-color: rgb(var(--color-brand-accent));
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
}

.slick-prev:hover,
.slick-next:hover {
  background: rgb(var(--color-brand-accent));
  -webkit-box-shadow: 0 0 5px rgb(var(--color-brand-accent));
          box-shadow: 0 0 5px rgb(var(--color-brand-accent));
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'Arial', sans-serif;
  font-size: 32px;
  line-height: 1;
  color: #fff;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before:hover,
.slick-next:before:hover {
  color: #fff;
}

.slick-prev {
  left: -10px;
  z-index: 1;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "â®";
}

[dir="rtl"] .slick-prev:before {
  content: "â¯";
}

.slick-next {
  right: -10px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "â¯";
}

[dir="rtl"] .slick-next:before {
  content: "â®";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: #dbe3e8;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "â€¢";
  width: 20px;
  height: 20px;
  font-family: Arial, sans-serif;
  font-size: 35px;
  line-height: 35px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 1;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: inline-block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.gallery {
  margin: 40px 0;
}

.gallery .success-logos .logo-item {
  text-align: center;
}

.gallery .success-logos .logo-item .logo-image {
  display: none;
}

#activity-viewer-input {
  visibility: hidden;
}

#activity-viewer-input + .notification-pending-counts {
  display: none;
}

#activity-viewer-input:checked + .notification-pending-counts:not(:empty) {
  display: block;
}

.activity-viewer {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 15px;
}

.activity-viewer label {
  cursor: pointer;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.activity-viewer label .activity-viewer-icon {
  position: relative;
  padding-left: 5px;
  font-size: 1.5rem;
  line-height: 0.85rem;
  vertical-align: middle;
}

.activity-viewer label .activity-viewer-icon:before {
  content: "ïµ";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  vertical-align: top;
}

.activity-viewer .notification-pending-counts {
  padding: 0;
  list-style: none;
  width: 35ch;
  max-width: 100vw;
  border: 1px solid #eee;
  background-color: white;
  -webkit-box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
          box-shadow: 0px -10px 30px rgba(219, 227, 232, 0.98);
  position: absolute;
  z-index: 2;
  top: calc(100% + 0.5rem);
  right: 0;
}

.activity-viewer .notification-pending-counts:before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  -webkit-box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
          box-shadow: -3px -3px 3px rgba(207, 207, 207, 0.35);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  bottom: calc(100% - 0.5rem);
  right: 5rem;
}

.activity-viewer .notification-pending-counts > li {
  padding: 0.5ch 0;
  margin: 0 auto;
}

.activity-viewer .notification-pending-counts > li.no-data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 500;
  color: #02C27E;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
}

.activity-viewer .notification-pending-counts .activity {
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.activity-viewer .notification-pending-counts .activity + .activity {
  border-top: 1px solid #dbe3e8;
}

.notifications-pending .activity-viewer-icon::after {
  content: attr(data-message-count);
  font-style: normal !important;
  line-height: 1 !important;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  background-color: #02C27E;
  position: absolute;
  top: -5px;
  right: -10px;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

::-moz-selection {
  background: rgb(var(--color-brand-main));
  color: #fff;
}

::selection {
  background: rgb(var(--color-brand-main));
  color: #fff;
}

body {
  font-size: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  font-family: Lato, "Lato", "Open Sans", Arial, Verdana, sans-serif;
}

a {
  color: rgb(var(--color-brand-accent));
}

.screen-reader-only {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  left: -99999px;
}

.page {
  display: table;
  height: 100%;
  width: 100%;
}

#page-top {
  display: none;
}

#e-content {
  display: table-row;
  height: 100%;
}

#e-content > .box-wc {
  height: 100%;
}

.engages-body-con {
  position: relative;
}

.engages-body-con > .box-wc {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 1024px) {
  .engages-body-con > .box-wc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 1400px) {
  .engages-body-con > .box-wc {
    padding: 0;
  }
}

#footer {
  display: table-row;
  height: 1px;
}

.select-wrap {
  position: relative;
  width: 100%;
  max-width: 260px;
  display: block;
}

.select-wrap:after {
  content: '';
  display: block;
  border-top: 5px solid #4a4a4b;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.select-wrap select {
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid #dbe3e8;
  border-bottom: 3px solid rgb(var(--color-brand-accent));
  color: #4a4a4b;
  background: #fff;
  padding: 8px 25px 8px 12px;
  font-size: 1.16667rem;
  width: 100%;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.lum-lightbox-inner img {
  max-height: 90vh !important;
}
